import {saveAsExcel} from 'common/tables/excelExport'
import {saveAsCSV} from 'common/tables/csvExport'

export function getExportFileBlob({columns, data, fileType, fileName}) {
  if (fileType === 'csv') {
    saveAsCSV(columns, data, fileName)
  } else if (fileType === 'xlsx') {
    saveAsExcel(columns, data, fileName)
    return false
  }
  return false
}

export const compareIgnoreCase = (a, b) => {
  var _a = a && typeof a === 'string' ? a?.toLowerCase() : a || ''
  var _b = b && typeof b === 'string' ? b?.toLowerCase() : b || ''
  if (_a < _b) return -1
  if (_a > _b) return 1
  return 0
}

export const changePageAndScroll = (
  type,
  scrollRef,
  nextPage,
  previousPage,
) => {
  const Y_OFFSET = 200
  const topOfElement =
    scrollRef.current.getBoundingClientRect().top + window.scrollY
  window.scrollTo({
    top: topOfElement - Y_OFFSET,
    behavior: 'smooth',
  })

  if (type === 'next') {
    nextPage()
  } else {
    previousPage()
  }
}
