import React, {useEffect} from 'react'
import Bootstrap from 'bootstrap' // eslint-disable-line
import {Router} from 'react-router-dom'
import {onAuthStateChanged} from 'firebase/auth'
import history from './history'
import Routes from './Routes'
import gql from 'graphql-tag'
import {print} from 'graphql'
import {auth} from './Firebase/Firebase'
import {QueryClient, QueryClientProvider} from 'react-query'
import {ReactQueryDevtools} from 'react-query/devtools'
import Axios from 'axios'
import ErrorBoundary from './ErrorBoundary'
import * as Sentry from '@sentry/react'

//font awesome icon library
import {library} from '@fortawesome/fontawesome-svg-core'
import {
  faFacebookF,
  faInstagram,
  faTwitter,
  faTiktok,
  faPinterestP,
  faLinkedinIn,
  faYoutube,
} from '@fortawesome/free-brands-svg-icons'
import {
  faPlus,
  faTimesCircle,
  faEdit,
  faCartPlus,
  faChartLine,
  faGolfBall,
  faFolderOpen,
  faHeartbeat,
  faInfoCircle,
  faPhone,
  faEnvelopeOpen,
  faEnvelope,
  faFilePdf,
  faEye,
  faSortAlphaDown,
  faSortNumericDown,
  faSpinner,
  faTable,
  faMoneyBill,
  faHandHoldingUsd,
  faFileExcel,
  faRedo,
  faCopy,
  faBars,
  faUser,
  faList,
  faSignOutAlt,
  faSignInAlt,
  faTrashAlt,
  faCaretSquareUp,
  faCaretSquareDown,
  faCaretDown,
  faCaretRight,
  faSave,
  faExclamationTriangle,
  faEllipsisH,
  faArrowRight,
} from '@fortawesome/free-solid-svg-icons'

library.add(
  faPlus,
  faTimesCircle,
  faEdit,
  faCartPlus,
  faChartLine,
  faGolfBall,
  faFolderOpen,
  faHeartbeat,
  faInfoCircle,
  faPhone,
  faEnvelopeOpen,
  faEnvelope,
  faFilePdf,
  faEye,
  faSortAlphaDown,
  faSortNumericDown,
  faSpinner,
  faTable,
  faMoneyBill,
  faHandHoldingUsd,
  faFileExcel,
  faRedo,
  faCopy,
  faBars,
  faUser,
  faList,
  faSignOutAlt,
  faSignInAlt,
  faTrashAlt,
  faCaretSquareUp,
  faCaretSquareDown,
  faCaretDown,
  faCaretRight,
  faSave,
  faExclamationTriangle,
  faFacebookF,
  faInstagram,
  faTiktok,
  faPinterestP,
  faLinkedinIn,
  faYoutube,
  faTwitter,
  faEllipsisH,
  faArrowRight,
)

const endpoint =
  process.env.REACT_APP_ENV === 'local'
    ? `${process.env.REACT_APP_API_URL}/graphql`
    : process.env.REACT_APP_ENV === 'development'
    ? '/apidev'
    : '/api'

const GET_SUPER_ADMIN = gql`
  query getSuperAdmin {
    SuperAdmin {
      id
      user
    }
  }
`

function createAxiosResponseInterceptor() {
  const interceptor = Axios.interceptors.response.use(
    response => response,
    error => {
      // Reject promise if usual error
      //const status = error.response ? error.response.status : null
      console.error(error?.response?.data?.errors)
      const errorData = error?.response?.data?.errors
      if (!errorData || !errorData[0]?.message.includes('Token has expired')) {
        console.error(error.response)
        return Promise.reject(error)
      }

      /*
       * Try to refresh the token.
       * Eject the interceptor so it doesn't loop in case
       * token refresh causes the 401 response
       */
      Axios.interceptors.response.eject(interceptor)
      console.info('getting new token')
      return auth.currentUser
        .getIdToken()
        .then(token => {
          error.config.headers['Authorization'] = 'Basic ' + token
          Axios.defaults.headers.common['Authorization'] = `Basic ${token}`
          return Axios.request(error.config)
        })
        .catch(error => {
          // destroyToken();
          // this.router.push('/login');
          console.error('error from interceptor', error.response)
          return Promise.reject(error)
        })
        .finally(createAxiosResponseInterceptor)
    },
  )
}

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    },
  },
})

const App = () => {
  // const dispatch = useUserDispatch()
  Axios.defaults.baseURL = endpoint
  Axios.defaults.headers.common['Access-Control-Allow-Origin'] = '*'
  //the ignore errors is for some recursive error that gets triggered: https://forum.sentry.io/t/unhandledrejection-non-error-promise-rejection-captured-with-value/14062/17
  Sentry.init({
    dsn: process.env.REACT_APP_SENTRY_KEY,
    ignoreErrors: ['Non-Error promise rejection captured'],
  })

  useEffect(() => {
    window.addEventListener('unhandledrejection', function(event) {
      // the event object has two special properties:
      console.error(event.promise) // [object Promise] - the promise that generated the error
      console.error(event.reason) // Error: Whoops! - the unhandled error object
    })

    //listen for refreshed tokens
    const idListener = onAuthStateChanged(auth, user => {
      const newUser = user
      const loggedIn = localStorage?.getItem('loggedIn')
      if (user && loggedIn) {
        //if (user) {
        user.getIdToken().then(token => {
          const user = {
            email: newUser.email,
            displayName: newUser.displayName,
            token: token,
          }
          Axios.defaults.headers.common['Authorization'] = `Basic ${token}`

          //check if super
          const currentUser = queryClient.getQueryData('currentUser')
          if (currentUser && currentUser.email === user.email) {
            user.super = currentUser.super
          } else {
            //user has just logged in
            Axios.post('', {
              query: print(GET_SUPER_ADMIN),
            })
              .then(response => {
                const su = response?.data?.data?.SuperAdmin
                user.super = su ? true : false
                queryClient.setQueryData('currentUser', user, {
                  cacheTime: Infinity,
                })
              })
              .catch(err => {
                const errorUser = {error: true}
                queryClient.setQueryData('currentUser', errorUser, {
                  cacheTime: Infinity,
                })
                console.error(err)
              })
          }
        })
      }
    })
    createAxiosResponseInterceptor()
    return () => {
      // listener()
      idListener()
    }
  }, [])

  return (
    <Sentry.ErrorBoundary fallback={ErrorBoundary}>
      <QueryClientProvider client={queryClient}>
        <Router history={history}>
          <Routes />
        </Router>
        <ReactQueryDevtools initialIsOpen={false} />
      </QueryClientProvider>
    </Sentry.ErrorBoundary>
  )
}

export default App
