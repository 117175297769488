//
import React, {useState, Fragment} from 'react'
// import styled from "styled-components/macro";
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  FormGroup,
  Label,
  Input,
  Card,
} from 'reactstrap'
import styled from 'styled-components/macro'
import Logo from '../assets/images/GolfInvite_cob4cc.jpg'

import CartPreview from './CartPreview'
import Paypal from '../assets/Paypal'
import Stripe from '../assets/Stripe'

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
  justify-content: center;
`

const ButtonRow = styled.div`
  align-self: flex-end;
  display: flex;
  width: 100%;
  justify-content: center;
  button {
    margin-left: 10px;
  }
`

const ButtonText = styled.aside`
  font-size: 0.9rem;
  color: #333;
`

const ModalCard = styled(Card)`
  min-height: 138px;
  margin: 0 10px;
  padding: 1rem;
  display: ${props => (props.showit === 'true' ? 'flex' : 'none')};
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  /* overflow: scroll;
  max-height: 350px;
  ::-webkit-scrollbar {
    -webkit-appearance: none;
    width: 7px;
  }
  ::-webkit-scrollbar-thumb {
    border-radius: 4px;
    background-color: rgba(0, 0, 0, 0.5);
    -webkit-box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
  } */
`

const Header = styled(ModalHeader)`
  display: flex;
  justify-content: center;
`

const ZeroCostButtons = styled.div`
  display: flex;
  button {
    margin: 5px;
  }
`

const CancelButton = props => (
  <Button
    color="danger"
    onClick={props.toggleCartModal}
    data-cy="cancelCartModal"
  >
    Cancel
  </Button>
)

const CartModal = props => {
  const initalPaymentOption = props.event.paymentStripeEnabled
    ? 'stripe'
    : props.event.paypalPayee
    ? 'paypal'
    : props.event.paymentCheckEnabled
    ? 'check'
    : 'other'
  const [paymentOptionSelected, setPaymentOptionSelected] = useState(
    initalPaymentOption,
  )
  return (
    <Modal isOpen={props.showingCart} size="lg">
      <Header>
        <img src={Logo} className="img-fluid" alt="Demo" />
      </Header>
      <ModalBody>
        <CartPreview
          event={props.event}
          guests={props.guests || []}
          players={props.players || []}
          cartTotal={props.cartTotal}
          sponsorshipItems={props.sponsorshipItems}
          removeItemClick={props.removeItemClick}
          removeGuestClick={props.removeGuestClick}
          removeGroupClick={props.removeGroupClick}
          removePlayerClick={props.removePlayerClick}
          removeDonationClick={props.removeDonationClick}
          playerDiscounts={props.playerDiscounts}
          donations={props.donations}
        />
      </ModalBody>
      <ModalFooter>
        <Container>
          {props.cartTotal === 0 ? (
            <ZeroCostButtons>
              <Button
                color="primary"
                onClick={props.submitRegistration}
                data-payment-method="none"
                disabled={props.cartItemCount < 1}
              >
                Submit Registration
              </Button>
              <Button color="danger" onClick={props.toggleCartModal}>
                Cancel
              </Button>
            </ZeroCostButtons>
          ) : (
            <Fragment>
              <FormGroup tag="fieldset">
                <legend>Payment Options</legend>
                {props.event.paypalPayee && props.event.paymentPaypalEnabled ? (
                  <FormGroup check>
                    <Label check>
                      <Input
                        type="radio"
                        value="paypal"
                        data-cy="paymentOptionsCC"
                        checked={paymentOptionSelected === 'paypal'}
                        name="paymentOptionSelected"
                        onChange={() => setPaymentOptionSelected('paypal')}
                      />{' '}
                      Paypal/Credit Card
                    </Label>
                  </FormGroup>
                ) : (
                  <Fragment />
                )}
                {props.event.stripeLinkedID &&
                props.event.paymentStripeEnabled ? (
                  <FormGroup check>
                    <Label check>
                      <Input
                        type="radio"
                        value="stripe"
                        data-cy="paymentOptionsStripe"
                        checked={paymentOptionSelected === 'stripe'}
                        name="paymentOptionSelected"
                        onChange={() => setPaymentOptionSelected('stripe')}
                      />{' '}
                      Stripe/Credit Card
                    </Label>
                  </FormGroup>
                ) : (
                  <Fragment />
                )}
                {props.event.paymentCheckEnabled ? (
                  <FormGroup check>
                    <Label check>
                      <Input
                        type="radio"
                        value="check"
                        data-cy="paymentOptionsCheck"
                        checked={paymentOptionSelected === 'check'}
                        name="paymentOptionSelected"
                        onChange={() => setPaymentOptionSelected('check')}
                      />{' '}
                      Check
                    </Label>
                  </FormGroup>
                ) : (
                  <Fragment />
                )}
                {props.event.paymentOtherEnabled ? (
                  <FormGroup check>
                    <Label check>
                      <Input
                        type="radio"
                        value="other"
                        data-cy="paymentOptionsOther"
                        checked={paymentOptionSelected === 'other'}
                        name="paymentOptionSelected"
                        onChange={() => setPaymentOptionSelected('other')}
                      />{' '}
                      {props.event.paymentOtherTitle}
                    </Label>
                  </FormGroup>
                ) : (
                  <Fragment />
                )}
              </FormGroup>
              {props.event.paypalPayee && props.event.paymentPaypalEnabled ? (
                <ModalCard
                  body
                  outline
                  color="secondary"
                  showit={(paymentOptionSelected === 'paypal').toString()}
                >
                  <Paypal
                    payCallback={props.payAndSubmitRegistration}
                    cartTotal={props.cartTotal}
                    submitObject={props.submitObject}
                    payee={props.event.paypalPayee || ''}
                    toggleModal={props.toggleCartModal}
                    description={`${props.event.name.substring(
                      0,
                      70,
                    )} Golf Invite Registration from ${
                      props.registrant.firstName
                    } ${props.registrant.lastName}`}
                    eventID={props.event.id}
                  />
                  <ButtonRow>
                    <CancelButton toggleCartModal={props.toggleCartModal} />
                  </ButtonRow>
                </ModalCard>
              ) : (
                <Fragment />
              )}

              {props.event.stripeLinkedID &&
              props.event.paymentStripeEnabled ? (
                <ModalCard
                  body
                  outline
                  color="secondary"
                  showit={(paymentOptionSelected === 'stripe').toString()}
                >
                  <Stripe
                    cartTotal={props.cartTotal}
                    stripeLinkedID={props.event.stripeLinkedID}
                    submitObject={props.submitObject}
                    toggleModal={props.toggleCartModal}
                    description={`${props.event.name.substring(
                      0,
                      70,
                    )} Golf Invite Registration from ${
                      props.registrant.firstName
                    } ${props.registrant.lastName}`}
                    eventID={props.event.id}
                    slug={props.event.slug}
                  />
                  <ButtonRow>
                    <CancelButton toggleCartModal={props.toggleCartModal} />
                  </ButtonRow>
                </ModalCard>
              ) : (
                <Fragment />
              )}

              <ModalCard
                body
                outline
                color="secondary"
                showit={(paymentOptionSelected === 'check').toString()}
              >
                <ButtonText>{props.event.checkAddress}</ButtonText>
                <ButtonText>{props.event.checkAddressInstructions}</ButtonText>
                <ButtonRow>
                  <Button
                    color="success"
                    onClick={props.submitRegistration}
                    data-payment-method="check"
                  >
                    Pay By Check
                  </Button>
                  <CancelButton toggleCartModal={props.toggleCartModal} />
                </ButtonRow>
              </ModalCard>

              <ModalCard
                body
                outline
                color="secondary"
                showit={(paymentOptionSelected === 'other').toString()}
              >
                <ButtonText>{props.event.paymentOtherInstructions}</ButtonText>
                <ButtonRow>
                  <Button
                    color="success"
                    onClick={props.submitRegistration}
                    data-payment-method="other"
                  >
                    {props.event.paymentOtherTitle
                      ? `I Will Pay With ${props.event.paymentOtherTitle}`
                      : 'Submit Registration'}
                  </Button>
                  <CancelButton toggleCartModal={props.toggleCartModal} />
                </ButtonRow>
              </ModalCard>
            </Fragment>
          )}
        </Container>
      </ModalFooter>
    </Modal>
  )
}

export default CartModal
