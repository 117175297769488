//
export function validateEmail(email) {
  var re = /\S[^,<>]+@\S[^,<>]+\.\S[^,<>]+/
  const emailArray = [...email]
  let spaceFound = false
  emailArray.forEach(letter => {
    if (letter === ' ') {
      spaceFound = true
    }
  })
  if (spaceFound) return false
  const numberOfAtSymbols = email.split('@').length - 1
  if (numberOfAtSymbols !== 1) return false

  //checking for consecutive periods
  const domain = email.split('@')[1]
  const consecutivePeriodCheck = domain.split('..').length
  if (consecutivePeriodCheck > 1) return false

  if (re.test(email)) {
    const newEmail = re.exec(email)
    if (newEmail && newEmail.length > 0 && newEmail[0] === email) {
      return true
    } else {
      return false
    }
  }
  return false
}

// Create our number formatter.
export const currencyFormatter = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
})

// a little function to help us with reordering the result
export const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list)
  const [removed] = result.splice(startIndex, 1)
  result.splice(endIndex, 0, removed)

  return result
}

// Generate unique IDs for use as pseudo-private/protected names.
// Similar in concept to
// <http://wiki.ecmascript.org/doku.php?id=strawman:names>.
//
// The goals of this function are twofold:
//
// * Provide a way to generate a string guaranteed to be unique when compared
//   to other strings generated by this function.
// * Make the string complex enough that it is highly unlikely to be
//   accidentally duplicated by hand (this is key if you're using `ID`
//   as a private/protected name on an object).
//
// Use:
//
//     var privateName = ID();
//     var o = { 'public': 'foo' };
//     o[privateName] = 'bar';
export const generateID = function() {
  // Math.random should be unique because of its seeding algorithm.
  // Convert it to base 36 (numbers + letters), and grab the first 9 characters
  // after the decimal.
  return (
    '_' +
    Math.random()
      .toString(36)
      .substr(2, 9)
  )
}

export const returnOnlyNumbers = (originalValue, input) => {
  let goodValue = input
  if (input.split('.').length > 2) {
    //more than 1 decimal?
    goodValue = originalValue
  } else if (!isNaN(input) || input.endsWith('.') || input.startsWith('.')) {
    //good to go
    goodValue = input
  } else {
    goodValue = originalValue
  }

  return goodValue
}
