//
import React, {Fragment, useState, useEffect, useRef} from 'react'
// import paypal from 'paypal-checkout'
import Spinner from './Spinner'
import axios from 'axios'
import styled from 'styled-components/macro'
import Swal from 'sweetalert2'
import * as Sentry from '@sentry/browser'
// import scriptLoader from 'react-async-script-loader'

const SpinnerContainer = styled.div`
  width: 250px;
  height: 200px;
`

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`

const PaypalBox = styled.div`
  width: 250px;
  display: block;
`

const PayNote = styled.aside`
  font-size: 14px;
  color: #808080;
  margin-bottom: 10px;
  max-width: 600px;
`

const Paypal = props => {
  let {
    cartTotal,
    payee,
    description,
    payCallback,
    submitObject,
    toggleModal,
  } = props
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(null)
  const [scriptLoaded, setScriptLoaded] = useState(false)
  const [buttonsLoaded, setButtonsLoaded] = useState(false)
  const paypalRef = useRef()
  const cartTotalRef = useRef()
  const payCallbackRef = useRef()
  const submitObjectRef = useRef()

  //needed because cartTotal props changes were being ignored
  useEffect(() => {
    cartTotalRef.current = cartTotal
  }, [cartTotal])

  useEffect(() => {
    payCallbackRef.current = payCallback
  }, [payCallback])

  useEffect(() => {
    submitObjectRef.current = submitObject
  }, [submitObject])

  useEffect(() => {
    if (window && !window.paypal) {
      if (document && document.body) {
        var tag = document.createElement('script')
        tag.async = false
        tag.src = `https://www.paypal.com/sdk/js?client-id=${process.env
          .REACT_APP_PAYPAL_CLIENT ||
          ''}&merchant-id=${payee}&disable-funding=credit&integration-date=2018-11-30`

        if (document.body) {
          document.body.appendChild(tag)
        }

        tag.onload = () => {
          setScriptLoaded(true)
        }
        tag.onerror = error => {
          console.error('error loading Paypal script', error)
          setError(true)
        }
      } else {
        setError(true)
      }
    } else if (window.paypal) {
      setScriptLoaded(true)
    } else {
      setError(true)
    }
  }, [payee])

  useEffect(() => {
    const createOrder = () => {
      // Set up a url on your server to create the payment
      var CREATE_URL = `${process.env.REACT_APP_API_URL ||
        ''}/paypal/create-payment`

      // Make a call to your server to set up the payment

      return axios
        .post(CREATE_URL, {
          amount: cartTotalRef.current,
          payee,
          description,
          submitObject: submitObjectRef.current,
        })
        .then(res => {
          return res.data.id
        })
        .catch(error => {
          console.error(error.response)
          return null
        })
    }
    // const onAuthorize = (data, actions) => {
    const onAuthorize = data => {
      setLoading(true)
      var EXECUTE_URL = `${process.env.REACT_APP_API_URL ||
        ''}/paypal/execute-payment`

      return axios
        .post(EXECUTE_URL, {
          orderID: data.orderID,
          submitObject: submitObjectRef.current,
        })
        .then(response => {
          setLoading(false)
          if (response?.data?.issue) {
            if (response.data.issue === 'INSTRUMENT_DECLINED') {
              Swal.fire(
                'Instrument Declined',
                'The card/account used was denied by PayPal. Please try again.',
                'error',
              )
              // console.log('actions.restart')
              // actions.restart()
            } else {
              console.error('paypal unknown error', response?.data)
              Swal.fire(
                'PayPal Error',
                'There was an error while processing the payment. Please try again and if you continue to have problems, contact us at support@golfinvite.com',
                'error',
              )
              Sentry.captureException(response?.data)
            }
            if (toggleModal) toggleModal()
          } else {
            if (response.data.id) {
              if (payCallbackRef && payCallbackRef.current) {
                payCallbackRef.current(response.data.id)
              } else {
                payCallback(response.data.id)
              }
            } else {
              setError(true)
            }
          }
        })
        .catch(function(error) {
          if (error?.response?.data?.message === 'Sold out') {
            Swal.fire(
              'Items Sold Out',
              'Sorry but one of the items sold out before your registration was processed. The page will be refreshed to get the new available counts.',
              'error',
            ).then(() => {
              console.error('Items sold out', submitObject)
              window.location.reload()
            })
          } else {
            Swal.fire(
              'PayPal Error',
              'There was an error while processing the payment. Please check the card/account used and refresh the page to try again. If you continue to have problems, contact us at support@golfinvite.com',
              'error',
            ).then(() => {
              console.error('Problem with registration', submitObject)
            })
          }
          console.error(error)
          Sentry.captureException(error)
          if (toggleModal) toggleModal()
        })
    }

    let style = {
      layout: 'horizontal', //vertical will show the cc buttons that don't work with personal accounts
      size: 'responsive', // small | medium | large | responsive
      shape: 'rect', // pill | rect
      color: 'blue', // gold | blue | silver | black
    }

    if (window && window.paypal && !loading && scriptLoaded && !buttonsLoaded) {
      // Render the PayPal marks
      window.paypal
        .Buttons({
          createOrder,
          onApprove: onAuthorize,
          style,
          onError: err => {
            setError(err)
            console.error('error from paypal buttons', err)
            setLoading(false)
          },
        })
        .render(paypalRef.current)
      setButtonsLoaded(true)
    }
  }, [
    buttonsLoaded,
    cartTotal,
    description,
    loading,
    payCallback,
    payee,
    scriptLoaded,
    submitObject,
    toggleModal,
  ])

  // let PayPalButton = paypal.Button.driver('react', {React, ReactDOM})

  return (
    <Fragment>
      {loading || !scriptLoaded ? (
        <SpinnerContainer>
          <Spinner spinnerType="ball-scale-multiple" />
        </SpinnerContainer>
      ) : (
        <Container data-cy="paypalContainer">
          <PayNote>
            {`You do not need a PayPal account to pay with a credit card. If you have previously logged into a PayPal account within your current browser or are currently logged into PayPal you will not have this option.`}
            <br />
            <br />
            {`After clicking the PayPal button, click ‘Pay with a Debit or Credit Card’. Enter an email, continue to payment, enter the card information & deselect the box ’Save info & create your PayPal account’ at the bottom of the page.`}
          </PayNote>
          <PaypalBox ref={paypalRef} />
        </Container>
      )}
      {error && (
        <div>
          The Paypal client was unable to load, please check your connection and
          try again.
        </div>
      )}
    </Fragment>
  )
}

export default Paypal
