import axios from 'axios'
import {print} from 'graphql'
import gql from 'graphql-tag'
import {useQuery} from 'react-query'
import moment from 'moment'

export const GET_EVENTS_REPORT = gql`
  {
    getEventsReport {
      eventID
      name
      eventDate
      courseName
      planner
      paypalPayments
      payments
      playerCapacity
      playerCount
      contact
      salesRep
      slug
    }
  }
`

export const sortAndConvertEvents = events => {
  let sortedEvents = events.map(event => {
    //check if missing leading 0
    event.eventDate =
      event.eventDate.charAt(1) === '/'
        ? '0' + event.eventDate
        : event.eventDate

    //could be missing leading 0 in day
    event.eventDate =
      event.eventDate.charAt(4) === '/'
        ? `${event.eventDate.substring(0, 3)}0${event.eventDate.substring(3)}`
        : event.eventDate
    event.eventDate = event.eventDate.substr(0, 10) //may have multiple dates 05/29/2020 & 05/30/2020

    event.eventDateConverted = moment(event.eventDate, 'MM/DD/YYYY')
    return event
  })
  sortedEvents.sort((a, b) => {
    if (a && b) {
      if (a.eventDateConverted < b.eventDateConverted) {
        return -1
      }
      if (a.eventDateConverted > b.eventDateConverted) {
        return 1
      }
    }
    return 0
  })
  return sortedEvents
}

function useEventsReport(user) {
  return useQuery(
    'eventsReport',
    async () => {
      const response = await axios.post('', {
        query: print(GET_EVENTS_REPORT),
      })
      return sortAndConvertEvents(response?.data?.data?.getEventsReport || [])
    },
    {
      enabled: Boolean(user && user.email),
      onError: err => {
        console.error(err)
      },
    },
  )
}

export default useEventsReport
