//
import React, {Fragment, useState, useEffect} from 'react'
import styled from 'styled-components/macro'
import {Card, CardBody, CardHeader} from 'reactstrap'
import {useLocation} from 'react-router-dom'

import CurrencyDisplay from './CurrencyDisplay'

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: white;
  min-height: 100vh;
`

const StyledCard = styled(Card)`
  margin: 10px;
  width: 90%;
  max-width: 800px;
  table {
    width: 100%;
  }
`

const EventInformation = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
`

const EventRow = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  margin-bottom: 5px;
`

const EventLabel = styled.span`
  font-weight: bold;
`

const EventValue = styled.span`
  text-align: right;
`

const TotalFooter = styled(CardHeader)`
  display: flex;
  flex-direction: column;

  font-size: 1.3rem;
  padding: 15px;
`

const PaymentFooter = styled.div`
  display: flex;
  justify-content: center;
  font-size: 1.2rem;
`

const TotalFooterRow = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 10px;
`

const SubTotalFooterRow = styled.div`
  display: flex;
  justify-content: space-between;
  font-size: 1.2rem;
`

const RequiredFlag = styled.sup`
  font-size: 0.7rem;
  color: #dc3545;
  margin-left: 5px;
`

const TotalNonMonetaryDisclosure = styled.span`
  font-size: 0.8rem;
  font-style: italic;
`

const PaypalConfirmationText = styled.span`
  font-size: 0.8rem;
  margin-left: 0.8rem;
  display: flex;
  align-items: center;
`

const Header = styled.h2`
  display: flex;
  width: 100%;
  justify-content: center;
  padding-bottom: 30px;
`

const ThankYouHeader = styled.h3`
  display: flex;
  width: 100%;
  justify-content: center;
`

const ThankYouSubHeader = styled.h6`
  display: flex;
  width: 100%;
  justify-content: center;
  text-align: center;
`

const CenterCell = styled.td`
  text-align: center;
`

const CenterHeaderCell = styled.th`
  text-align: center;
`

const RightCell = styled.td`
  text-align: right;
`

const RightHeaderCell = styled.th`
  text-align: right;
`

const Highlighted = styled.div`
  font-size: 1.2rem;
  background-color: yellow;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-top: 15px;
  padding: 10px;
  gap: 16px;
`

// const CenterTable = styled.div`
//   display: flex;
//   flex-direction: column;
//   justify-content: center;
//   align-items: center;
//   width: 100%;
//   table {
//     width: 100%;
//     max-width: 600px;
//     margin: 20px;
//   }
// `

function createTextLinks_(text) {
  return (text || '').replace(
    /([^\S]|^)(((https?:\/\/)|(www\.))(\S+))/gi,
    function(match, space, url) {
      var hyperlink = url
      if (!hyperlink.match('^https?://')) {
        hyperlink = 'http://' + hyperlink
      }
      return (
        space + '<a href="' + hyperlink + '" target="_blank">' + url + '</a>'
      )
    },
  )
}

const RegistrationReceipt = props => {
  const location = useLocation()
  const pathname = location.pathname
  const volunteersOnly = pathname.includes('/volunteer')
  const [sponsorshipItemCount, setSponsorshipItemCount] = useState(0)
  const [otherItemCount, setOtherItemCount] = useState(0)
  const [sponsorshipTotal, setSponsorshipTotal] = useState(0)
  const [otherTotal, setOtherTotal] = useState(0)
  const [donationTotal, setDonationTotal] = useState(0)
  const [otherInstructionsWithLinks, setOtherInstructionsWithLinks] = useState(
    '',
  )

  useEffect(() => {
    let localSponsorshipCount = 0,
      localOtherCount = 0,
      localSponsorshipTotal = 0,
      localOtherTotal = 0,
      localDonationTotal = 0
    props.event.sponsorshipItems.forEach(item => {
      if (item && item.quantityInCart && item.kind === 'sponsorship') {
        localSponsorshipCount += item.quantityInCart
        localSponsorshipTotal += item.quantityInCart * item.price
      }
    })
    props.event.sponsorshipItems.forEach(item => {
      if (item && item.quantityInCart && item.kind === 'other') {
        localOtherCount += item.quantityInCart
        localOtherTotal += item.quantityInCart * item.price
      }
    })
    if (props.donations) {
      props.donations.forEach(donation => {
        localDonationTotal += Number(donation?.value) || 0
      })
    }
    setSponsorshipItemCount(localSponsorshipCount)
    setSponsorshipTotal(localSponsorshipTotal)
    setOtherItemCount(localOtherCount)
    setOtherTotal(localOtherTotal)
    setDonationTotal(localDonationTotal)
    if (props.paymentMethod === 'other') {
      setOtherInstructionsWithLinks(
        createTextLinks_(props.event.paymentOtherInstructions),
      )
    }
  }, [
    props.event.paymentOtherInstructions,
    props.event.sponsorshipItems,
    props.paymentMethod,
    props.donations,
  ])

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  return (
    <Fragment>
      {props.registrationComplete && !props.adminMode && (
        <Container>
          <Header data-cy="registrationReceiptHeader">
            Registration Receipt
          </Header>
          {volunteersOnly ? (
            <ThankYouHeader>Thank you for volunteering!</ThankYouHeader>
          ) : (
            <ThankYouHeader>Thank you for your purchase!</ThankYouHeader>
          )}
          <ThankYouSubHeader>
            A receipt has been emailed to {props.registrant.email}
          </ThankYouSubHeader>
          {/* <ThankYouSubHeader>
            Confirmation ID {props.confirmationID}
          </ThankYouSubHeader> */}
          <StyledCard>
            <CardHeader>Event Information</CardHeader>
            <CardBody>
              <EventInformation>
                <EventRow>
                  <EventLabel>Event Name</EventLabel>
                  <EventValue>{props.event.name}</EventValue>
                </EventRow>
                <EventRow>
                  <EventLabel>Event Website</EventLabel>
                  <EventValue>
                    https://www.golfinvite.net/{props.event.slug}
                  </EventValue>
                </EventRow>
                <EventRow>
                  <EventLabel>Event Date</EventLabel>
                  <EventValue>{props.event.eventDate}</EventValue>
                </EventRow>
                <EventRow>
                  <EventLabel>Golf Start Time</EventLabel>
                  <EventValue>{props.event.golfStartTime}</EventValue>
                </EventRow>
                <EventRow>
                  <EventLabel>Course Name</EventLabel>
                  <EventValue>{props.event.courseName}</EventValue>
                </EventRow>
                <EventRow>
                  <EventLabel>Course Address</EventLabel>
                  <EventValue>{props.event.courseAddress}</EventValue>
                </EventRow>
              </EventInformation>
            </CardBody>
          </StyledCard>
          {props.players && props.players.length > 0 ? (
            <StyledCard>
              <CardHeader>Player Information</CardHeader>
              <CardBody>
                <table>
                  <thead>
                    <tr>
                      <th>First Name</th>
                      <th>Last Name</th>
                      <th>Item Name</th>
                      {/* <th>Price</th> */}
                    </tr>
                  </thead>
                  <tbody>
                    {props.players.map((player, index) => {
                      if (!player) return null
                      return (
                        <tr key={index}>
                          <td>
                            {player.firstName ||
                              `Player${player.id ? player.id : ''}`}{' '}
                          </td>
                          <td>{player.lastName || 'LastName'}</td>
                          <td>{player.playerOptionName}</td>
                        </tr>
                      )
                    })}
                  </tbody>
                </table>
              </CardBody>
            </StyledCard>
          ) : (
            <Fragment />
          )}

          {props.guests && props.guests.length > 0 ? (
            <StyledCard>
              <CardHeader>Guest Information</CardHeader>
              <CardBody>
                <table>
                  <thead>
                    <tr>
                      <th>First Name</th>
                      <th>Last Name</th>
                      <th>Item Name</th>
                      <RightHeaderCell>Price</RightHeaderCell>
                    </tr>
                  </thead>
                  <tbody>
                    {props.guests.map((guest, index) => {
                      if (!guest) return null
                      return (
                        <tr key={index}>
                          <td>
                            {guest.firstName ||
                              `Guest${
                                guest.guestNumber ? guest.guestNumber : ''
                              }`}
                          </td>
                          <td>{guest.lastName || ''}</td>
                          <td>{guest.guestOptionName}</td>
                          <RightCell>
                            <CurrencyDisplay
                              amount={Number(guest.guestFee) || 0}
                            />
                          </RightCell>
                        </tr>
                      )
                    })}
                  </tbody>
                </table>
              </CardBody>
            </StyledCard>
          ) : (
            <Fragment />
          )}

          {props.donations && props.donations.length ? (
            <StyledCard>
              <CardHeader>Donation Information</CardHeader>
              <CardBody>
                <table>
                  <thead>
                    <tr>
                      <th>Donation Description</th>
                      <RightHeaderCell>Value</RightHeaderCell>
                    </tr>
                  </thead>
                  <tbody>
                    {props.donations &&
                      props.donations.map((donation, index) => {
                        if (!donation) return null
                        return (
                          <tr key={index}>
                            <td>{donation.description}</td>
                            <RightCell>
                              <CurrencyDisplay
                                amount={Number(donation.value)}
                              />
                            </RightCell>
                          </tr>
                        )
                      })}
                  </tbody>
                </table>
              </CardBody>
            </StyledCard>
          ) : (
            <Fragment />
          )}

          {sponsorshipItemCount > 0 ? (
            <StyledCard>
              <CardHeader>Sponsorship Items</CardHeader>
              <CardBody>
                <table>
                  <thead>
                    <tr>
                      <th>Item</th>
                      <CenterHeaderCell>Quantity</CenterHeaderCell>
                      <RightHeaderCell>Value</RightHeaderCell>
                    </tr>
                  </thead>
                  <tbody>
                    {props.event.sponsorshipItems &&
                      props.event.sponsorshipItems.map((item, index) => {
                        if (
                          !item ||
                          item.quantityInCart < 1 ||
                          item.kind === 'other'
                        )
                          return null
                        return (
                          <Fragment key={item && item.id}>
                            {item && item.quantityInCart > 0 ? (
                              <tr key={index}>
                                <td>{item && item.itemName}</td>
                                <CenterCell>
                                  {item && item.quantityInCart}
                                </CenterCell>
                                <RightCell>
                                  <CurrencyDisplay
                                    amount={
                                      (item &&
                                        item.price * item.quantityInCart) ||
                                      0
                                    }
                                  />
                                </RightCell>
                              </tr>
                            ) : (
                              <Fragment />
                            )}
                          </Fragment>
                        )
                      })}
                  </tbody>
                </table>
              </CardBody>
            </StyledCard>
          ) : (
            <Fragment />
          )}
          {otherItemCount > 0 ? (
            <StyledCard>
              <CardHeader>Other Items</CardHeader>
              <CardBody>
                <table>
                  <thead>
                    <tr>
                      <th>Item</th>
                      <CenterHeaderCell>Quantity</CenterHeaderCell>
                      <RightHeaderCell>Value</RightHeaderCell>
                    </tr>
                  </thead>
                  <tbody>
                    {props.event.sponsorshipItems &&
                      props.event.sponsorshipItems.map((item, index) => {
                        if (
                          !item ||
                          item.quantityInCart < 1 ||
                          item.kind === 'sponsorship'
                        )
                          return null
                        return (
                          <Fragment key={item && item.id}>
                            {item && item.quantityInCart > 0 ? (
                              <tr key={index}>
                                <td>{item && item.itemName}</td>
                                <CenterCell>
                                  {item && item.quantityInCart}
                                </CenterCell>
                                <RightCell>
                                  <CurrencyDisplay
                                    amount={
                                      (item &&
                                        item.price * item.quantityInCart) ||
                                      0
                                    }
                                  />
                                </RightCell>
                              </tr>
                            ) : (
                              <Fragment />
                            )}
                          </Fragment>
                        )
                      })}
                  </tbody>
                </table>
              </CardBody>
            </StyledCard>
          ) : (
            <Fragment />
          )}
          {!volunteersOnly && (
            <StyledCard>
              <TotalFooter>
                {props.playerTotal && props.playerTotal ? (
                  <SubTotalFooterRow>
                    <span>Player Totals</span>
                    <span>
                      <CurrencyDisplay amount={props.playerTotal || 0} />
                    </span>
                  </SubTotalFooterRow>
                ) : (
                  <Fragment />
                )}
                {props.guestTotal && props.guestTotal > 0 ? (
                  <SubTotalFooterRow>
                    <span>Guest Totals</span>
                    <span>
                      <CurrencyDisplay amount={props.guestTotal || 0} />
                    </span>
                  </SubTotalFooterRow>
                ) : (
                  <Fragment />
                )}
                {sponsorshipTotal > 0 ? (
                  <SubTotalFooterRow>
                    <span>Sponsorship Items Totals</span>
                    <span>
                      <CurrencyDisplay amount={sponsorshipTotal || 0} />
                    </span>
                  </SubTotalFooterRow>
                ) : (
                  <Fragment />
                )}
                {otherTotal > 0 ? (
                  <SubTotalFooterRow>
                    <span>Other Items Total</span>
                    <span>
                      <CurrencyDisplay amount={otherTotal || 0} />
                    </span>
                  </SubTotalFooterRow>
                ) : (
                  <Fragment />
                )}
                {donationTotal > 0 ? (
                  <SubTotalFooterRow>
                    <span>Donations</span>
                    <span>
                      <CurrencyDisplay amount={donationTotal || 0} />
                    </span>
                  </SubTotalFooterRow>
                ) : (
                  <Fragment />
                )}
                <TotalFooterRow>
                  <span>
                    Registration Total{' '}
                    {props.donations && props.donations.length > 0 ? (
                      <TotalNonMonetaryDisclosure>
                        <RequiredFlag>*</RequiredFlag>
                        <span>Does not include non-monetary donations</span>
                      </TotalNonMonetaryDisclosure>
                    ) : (
                      <Fragment />
                    )}
                  </span>
                  <span data-testid="cart-total">
                    <CurrencyDisplay amount={props.cartTotal || 0} />
                  </span>
                </TotalFooterRow>

                {props.paypalConfirmationID ? (
                  <PaymentFooter>
                    Paid via Paypal{' '}
                    <PaypalConfirmationText>
                      (Confirmation ID: {props.paypalConfirmationID})
                    </PaypalConfirmationText>
                  </PaymentFooter>
                ) : (
                  props.cartTotal > 0 &&
                  ((props.paymentMethod === 'check' &&
                    props.event.checkAddressInstructions) ||
                    (props.paymentMethod === 'other' &&
                      otherInstructionsWithLinks)) && (
                    <PaymentFooter>
                      <Highlighted>
                        {props.paymentMethod === 'check' ? (
                          <div>{props.event.checkAddressInstructions}</div>
                        ) : (
                          <></>
                        )}
                        {props.paymentMethod === 'other' && (
                          <span
                            dangerouslySetInnerHTML={{
                              __html: otherInstructionsWithLinks,
                            }}
                          />
                        )}
                        {props.paymentMethod === 'check' &&
                        props.event.checkAddress ? (
                          <div> {props.event.checkAddress}</div>
                        ) : (
                          <></>
                        )}
                      </Highlighted>
                    </PaymentFooter>
                  )
                )}
              </TotalFooter>
            </StyledCard>
          )}
        </Container>
      )}
    </Fragment>
  )
}

export default RegistrationReceipt
