import * as React from 'react'
import {changePageAndScroll} from './tableWithHooksHelpers'
import {
  PaginationFooter,
  PaginationCenter,
  PaginationButtons,
  PaginationPageCounts,
  PaginationPageDropDown,
} from './tableStyles'

const TablePaginationControls = ({
  scrollRef,
  nextPage,
  previousPage,
  canPreviousPage,
  gotoPage,
  canNextPage,
  pageIndex,
  pageOptions,
  setPageSize,
  pageSize,
}) => {
  return (
    <PaginationFooter>
      <PaginationButtons
        type="button"
        onClick={() =>
          changePageAndScroll('previous', scrollRef, nextPage, previousPage)
        }
        disabled={!canPreviousPage}
      >
        Previous
      </PaginationButtons>
      <PaginationCenter>
        <PaginationPageCounts>
          Page{' '}
          <input
            type="number"
            data-cy="paginationPageNumber"
            onChange={e => {
              const page = Number(e.target.value) - 1
              gotoPage(page)
            }}
            value={pageIndex + 1}
          />{' '}
          of {pageOptions.length}
        </PaginationPageCounts>
        <PaginationPageDropDown>
          <select
            value={pageSize}
            onChange={e => {
              setPageSize(Number(e.target.value))
            }}
          >
            {[25, 50, 100, 500].map(pageSize => (
              <option key={pageSize} value={pageSize}>
                Show {pageSize}
              </option>
            ))}
          </select>
        </PaginationPageDropDown>
      </PaginationCenter>
      <PaginationButtons
        type="button"
        onClick={() =>
          changePageAndScroll('next', scrollRef, nextPage, previousPage)
        }
        disabled={!canNextPage}
      >
        Next
      </PaginationButtons>
    </PaginationFooter>
  )
}
export default TablePaginationControls
