//     
import React, {Fragment} from 'react'
import styled from 'styled-components/macro'
import moment from 'moment'
import Header from './Header'
                                             

const ClosedMessage = styled.h2`
  text-align: center;
  margin-top: 20px;
`

              
                   
 

const ClosedEvent = (props       ) => {
  let message = 'Registration is closed'

  if (
    props.event &&
    props.event.openDate &&
    props.event.openDate instanceof Date &&
    props.event.closeDate
  ) {
    message =
      props.event.openDate > new Date()
        ? `Registration begins on ${moment(props.event.openDate).format(
            'MM/DD/YYYY',
          ) || ''} `
        : `Registration is closed`
  }

  return (
    <Fragment>
      <Header
        eventDate={props.event.eventDate || ''}
        homeHeaderHTML={props.event.homeHeaderHTML || ''}
        golfStartTime={
          props.event.golfStartTime &&
          typeof props.event.golfStartTime === 'string'
            ? props.event.golfStartTime
            : ''
        }
        name={props.event.name}
      />{' '}
      : <Fragment />
      <ClosedMessage data-testid="closed-message">{message}</ClosedMessage>
    </Fragment>
  )
}

export default ClosedEvent
