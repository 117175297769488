import React from 'react'
import styled from 'styled-components/macro'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {Link} from 'react-router-dom'
import moment from 'moment'

const FooterContainer = styled.footer`
  display: grid;
  width: 100%;
  grid-template-areas:
    'leftcolumn rightcolumn'
    'centercolumn centercolumn';
  grid-template-columns: 1fr 1fr;
  @media (min-width: 1000px) {
    grid-template-areas: 'leftcolumn           centercolumn        rightcolumn';
    grid-template-columns: 1fr 1fr 1fr;
  }

  padding: 20px 10px 20px 10px;
  text-align: center;
  font-size: 0.9rem;
  color: white;
  z-index: 99;
`

const LeftColumn = styled.div`
  grid-area: leftcolumn;
`

const CenterColumn = styled.div`
  grid-area: centercolumn;
  margin-top: 20px;
  @media (min-width: 1000px) {
    margin-top: 0;
  }
  address {
    margin-bottom: 0;
  }
`

const RightColumn = styled.div`
  grid-area: rightcolumn;
`

const SocialIcons = styled.div`
  display: flex;
  margin: 5px auto 15px auto;
  width: 250px;
  justify-content: space-evenly;
  font-size: 1.2rem;
  & a {
    width: 26px;
    height: 26px;
    border-radius: 5px;
    background-color: white;
    color: #343a40 !important;
  }
`

// const PaymentSubmit = styled.input`
//   background: none !important;
//   color: inherit;
//   border: none;
//   padding: 0 !important;
//   font: inherit;
//   /*border is optional*/
//   cursor: pointer;
// `

const StyledA = styled.a`
  color: white;
  :hover {
    color: white;
  }
`

const Copyright = styled.div`
  width: 100%;
  bottom: 5px;
  right: 5px;
`

const Footer = () => (
  <FooterContainer className="bg-dark">
    <LeftColumn>
      <div>
        <strong>Support</strong>
      </div>

      <div>
        <StyledA href="mailto:support@golfinvite.com">Contact Support</StyledA>
      </div>
      <div>
        <Link className="text-white" to="/FAQ">
          FAQ
        </Link>
      </div>
    </LeftColumn>
    <CenterColumn>
      <address>
        <strong>Golf Invite, Inc.</strong>
        <br />
        PO Box 9648
        <br />
        Naperville, IL 60567
        <br />
        P: 630.657.5700 F: 630.657.5710
        <br />
        T: 877.657.5700
      </address>
    </CenterColumn>
    <RightColumn>
      <strong>Connect With Us</strong>
      <br />
      <SocialIcons>
        <a
          href="https://instagram.com/golfinviteinc?igshid=1ue4k70vwlxv7"
          target="_blank"
          rel="noopener noreferrer"
        >
          <FontAwesomeIcon icon={['fab', 'instagram']} />
        </a>
        <a
          href=" https://www.linkedin.com/company/golf-invite-inc/"
          target="_blank"
          rel="noopener noreferrer"
        >
          <FontAwesomeIcon icon={['fab', 'linkedin-in']} />
        </a>
        <a
          href="https://www.facebook.com/golfeventstoatee/"
          target="_blank"
          rel="noopener noreferrer"
        >
          <FontAwesomeIcon icon={['fab', 'facebook-f']} />
        </a>
        <a
          href="https://www.pinterest.com/golfinviteinc/_created/"
          target="_blank"
          rel="noopener noreferrer"
        >
          <FontAwesomeIcon icon={['fab', 'pinterest-p']} />
        </a>
        <a
          href="https://www.tiktok.com/@golfinviteinc"
          target="_blank"
          rel="noopener noreferrer"
        >
          <FontAwesomeIcon icon={['fab', 'tiktok']} />
        </a>
        <a
          href="https://twitter.com/golfinviteinc"
          target="_blank"
          rel="noopener noreferrer"
        >
          <FontAwesomeIcon icon={['fab', 'twitter']} />
        </a>
        <a
          href="https://www.youtube.com/channel/UCCEy9hk8GLhBG7nMXCwWscQ"
          target="_blank"
          rel="noopener noreferrer"
        >
          <FontAwesomeIcon icon={['fab', 'youtube']} />
        </a>
      </SocialIcons>
      <Copyright>
        Copyright &copy; Golf Invite {moment().format('YYYY')}
      </Copyright>
    </RightColumn>
  </FooterContainer>
)

export default Footer
