//
import * as React from 'react'

import styled from 'styled-components/macro'
import {Link, useLocation} from 'react-router-dom'
import {Button} from 'reactstrap'
import Swal from 'sweetalert2'

const StyledSubmitButton = styled.div`
  margin-top: 10px;
  margin-bottom: 10px;
  text-align: center;
`

const PaymentsDisabled = styled.div`
  font-size: 14px;
  margin-bottom: 10px;
`

function getKeysByValue(object, value) {
  return Object.keys(object).filter(key => object[key] === value)
}

function scrollToTargetAdjusted(element) {
  const offset = 45
  const bodyRect = document.body.getBoundingClientRect().top
  const elementRect = element.getBoundingClientRect().top
  const elementPosition = elementRect - bodyRect
  const offsetPosition = elementPosition - offset

  window.scrollTo({
    top: offsetPosition,
    behavior: 'smooth',
  })
}

const returnTopError = errors => {
  let topErrorPos = 9999999
  let topError
  let errorType
  errors.forEach(err => {
    err = err.toLowerCase()
    //sweet alert focuses active element after 100 timeout
    const foundElement = document.getElementsByName(err)[0]
    const errorPos = foundElement?.getBoundingClientRect().top
    if (errorPos && foundElement && errorPos < topErrorPos) {
      topErrorPos = errorPos
      topError = foundElement
      errorType = 'div'
    }
  })
  const invalidClasses = document.getElementsByClassName('is-invalid')
  invalidClasses.forEach(foundElement => {
    const errorPos = foundElement?.getBoundingClientRect().top
    if (errorPos && foundElement && errorPos < topErrorPos) {
      topErrorPos = errorPos
      topError = foundElement
      errorType = 'input'
    }
  })
  return {
    error: topError,
    type: errorType,
  }
}

function focusOnProblem(props) {
  if (props.errors && props.cartItemCount > 0) {
    // let allErrors = getKeysByValue(props.errors, true)
    // let topError = returnTopError(allErrors)
    // let foundElement
    // if (topError) {
    //   topError = topError.toLowerCase()
    //   foundElement = document.getElementsByName(topError)[0]
    // } else {
    //   foundElement = document.getElementById('registrationProfile')
    // }
    const allErrors = getKeysByValue(props.errors, true)
    const errorObj = returnTopError(allErrors)
    if (errorObj?.type === 'input') {
      errorObj.error.focus()
    } else if (errorObj?.type === 'div') {
      scrollToTargetAdjusted(errorObj.error)
    } else {
      //scroll to registration
      const registrationSection = document.getElementById('registrationProfile')
      scrollToTargetAdjusted(registrationSection)
    }
  }
}

const EventPageSubmitButton = props => {
  const location = useLocation()
  const pathname = location.pathname
  const volunteersOnly = pathname.includes('/volunteer')
  const buttonClick = callback => {
    if (
      props.formError ||
      (props.cartItemCount === 0 && callback === 'showCart')
    ) {
      if (props.cartItemCount === 0 && callback === 'showCart') {
        Swal.fire({
          title: 'Empty Cart',
          text: 'Please add an item to the cart before continuing',
          icon: 'warning',
          didClose: () => {
            focusOnProblem(props)
          },
        })
      } else {
        Swal.fire({
          title: 'Required information missing',
          icon: 'error',
          text: 'Please fill out all required fields',
          didClose: () => {
            focusOnProblem(props)
          },
        })
      }
    } else {
      if (callback === 'showCart') {
        props.toggleCartModal()
      } else {
        props.submitRegistration()
      }
    }
  }
  return (
    <>
      {props.event && !props.adminMode && volunteersOnly ? (
        //Volunteer button
        <StyledSubmitButton>
          <Button
            type="button"
            color="primary"
            className="btn styledSubmit"
            onClick={() => buttonClick('submit')}
            data-testid="submitButton"
          >
            Send Registration
          </Button>
        </StyledSubmitButton>
      ) : props.event && !props.adminMode ? (
        <StyledSubmitButton>
          <Button
            type="button"
            color="primary"
            className="btn styledSubmit"
            onClick={() => buttonClick('showCart')}
            data-testid="submitButton"
            // disabled={props.formError || props.cartItemCount === 0}
          >
            Review Registration & Payment $
            {props.cartTotal?.toLocaleString(navigator.language, {
              minimumFractionDigits: 2,
            })}
          </Button>
        </StyledSubmitButton>
      ) : (
        //admin mode
        <>
          <StyledSubmitButton>
            <button
              type="button"
              className="btn btn-success styledSubmit"
              onClick={() =>
                props.cartItemCount === 0
                  ? Swal.fire({
                      title: 'Empty Cart',
                      text: 'Please add an item to the cart before continuing',
                      icon: 'warning',
                    })
                  : props.submitRegistration()
              }
            >
              Save
            </button>
            <Link
              to={`/${props.event ? props.event.slug : ''}/admin/registrations`}
            >
              <button type="button" className="btn btn-danger styledSubmit">
                Cancel
              </button>
            </Link>
          </StyledSubmitButton>

          <PaymentsDisabled>
            Payment options are not available in admin mode
          </PaymentsDisabled>
        </>
      )}
    </>
  )
}

export default EventPageSubmitButton
