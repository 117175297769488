import gql from 'graphql-tag'

export const UPDATE_EVENT = gql`
  mutation UpdateEvent($input: UpdatedEvent!) {
    updateEvent(input: $input) {
      id
    }
  }
`

export const UPDATE_EVENT_ADMIN = gql`
  mutation UpdateEventAdmin($input: UpdatedEventAdmin!) {
    updateEventAdmin(input: $input) {
      id
      invoiced
      invoiceNumber
      contact
      salesRep
      user
    }
  }
`

// export const GET_EVENTS = gql`
//   {
//     allEvents {
//       id
//       name
//       slug
//       eventDate
//       golfStartTime
//       status
//     }
//   }
// `

export const GET_EVENTS_WITH_ADMIN = gql`
  {
    allEvents {
      events {
        id
        name
        slug
        eventDate
        openDate
        closeDate
        golfStartTime
        status
        user
        invoiced
        contact
        salesRep
      }
      admin
    }
  }
`

export const CHECK_SLUG = gql`
  query getEventBySlug($slug: String!) {
    getEventBySlug(slug: $slug) {
      id
    }
  }
`

export const NEW_EVENT_REGISTRATION = gql`
  mutation NewEventRegistration($input: NewEventRegistration!) {
    newEventRegistration(input: $input) {
      id
    }
  }
`

export const EDIT_EVENT_REGISTRATION = gql`
  mutation UpdateEventRegistration($input: UpdatedEventRegistration!) {
    updateEventRegistration(input: $input) {
      id
    }
  }
`

// export const NEW_VOLUNTEER_REGISTRATION = gql`
//   mutation NewVolunteerRegistration($input: NewVolunteerRegistration!) {
//     newVolunteerRegistration(input: $input) {
//       id
//       registrant {
//         firstName
//         lastName
//       }
//     }
//   }
// `

// export const EDIT_VOLUNTEER_REGISTRATION = gql`
//   mutation UpdateVolunteerRegistration($input: UpdatedVolunteerRegistration!) {
//     updateVolunteerRegistration(input: $input) {
//       id
//       eventID
//       registrant {
//         firstName
//         lastName
//         email
//         address
//         city
//         stateAddress
//         zipCode
//         phone
//         comments
//       }
//     }
//   }
// `

export const GET_EVENT_REGISTRATIONS = gql`
  query getAllEventRegistrations($input: String!) {
    getAllEventRegistrations(id: $input) {
      registrationID
      firstName
      lastName
      itemName
      email
      comments
      phone
      date
      type
      confirmationID
      roundID
      roundName
    }
  }
`

export const GET_EVENT_PAYMENTS = gql`
  query getEventPayments($input: String!) {
    getEventPayments(id: $input) {
      id
      eventID
      cartTotal
      created
      amount
      paymentMethod
    }
  }
`

// export const GET_REGISTRATIONS_WITH_PAYMENTS = gql`
//   query getEventRegistrationsWithPayments($id: String!) {
//     getEventRegistrationsWithPayments(id: $id) {
//       id
//       registrationID
//       confirmationID
//       created
//       eventID
//       paypalConfirmationID
//       cartTotal
//       playerTotal
//       guestTotal
//       sponsorshipItemTotal
//       donationTotal
//       paymentMethod
//       paymentTotal
//       balance
//       registrant {
//         firstName
//         lastName
//         email
//       }
//     }
//   }
// `

export const CHECK_COUPON = gql`
  query ValidateCoupon($code: String!) {
    validateCoupon(code: $code) {
      id
      name
      code
      created
      priceWithCoupon
      expirationDate
      disabled
      user
    }
  }
`

export const GET_INCOME_REPORT = gql`
  query getIncomeReport($input: String!) {
    getIncomeReport(id: $input) {
      id
      playerTotal
      guestTotal
      sponsorshipTotal
      otherTotal
      playerCount
      playerCapacity
      guestCount
      monetaryDonations
      playerDiscounts
      guestDiscounts
      sponsorshipItemDiscounts
      donationDiscounts
      payments
      sponsorshipItemTotals {
        sponsorshipID
        itemName
        price
        quantity
        capacity
        total
      }
      otherItemTotals {
        sponsorshipID
        itemName
        price
        quantity
        capacity
        total
      }
      customItems {
        eventID
        id
        label
        amount
      }
      totalIncome
      totalExpenses
      unreadMessageCount
    }
  }
`

export const GET_EVENT_BY_SLUG = gql`
  query getEventBySlug($slug: String!) {
    getEventBySlug(slug: $slug) {
      id
      adminMode
      name
      slug
      status
      paypalPayee
      paymentPaypalEnabled
      paymentCheckEnabled
      eventInstructions
      checkAddress
      checkAddressInstructions
      paymentOtherEnabled
      paymentOtherTitle
      paymentOtherInstructions
      eventDate
      golfStartTime
      openDate
      closeDate
      organizationName
      organizationWebsite
      courseName
      courseAddress
      courseWebsite
      courseDescription
      sponsorshipItems {
        id
        itemName
        description
        price
        quantityTotal
        quantityAvailable
        quantityInCart
        playersIncluded
        kind
        roundID
        display
        icon
        order
      }
      customQuestions {
        id
        question
        questionType
        options
        required
        appliesTo
        roundID
      }
      rounds {
        name
        description
        roundID
        available
        quantityInCart
      }
      playerCapacity
      playersAvailable
      playerFeeOptions {
        id
        playerFee
        foursomeDiscount
        twosomeDiscount
        playerOptionName
        playerRegistrationDescription
        roundID
        purchased
        available
        soldAs
        icon
        order
      }
      guestFeeOptions {
        guestFee
        guestOptionName
        guestRegistrationDescription
        icon
      }
      homepageHTML
      homeHeaderHTML
      showHeaderOnLandingPage
      format
      startType
      sponsorImages {
        name
        website
        image
        order
        display
      }
      playerEmailDataCollection
      playerAddressDataCollection
      playerPhoneDataCollection
      playerOrganizationDataCollection
      registrantAddressDataCollection
      registrantPhoneDataCollection
      registrantOrganizationDataCollection
      guestEmailDataCollection
      guestAddressDataCollection
      guestPhoneDataCollection
      guestOrganizationDataCollection
      allowDonors
      allowVolunteers
      showPairings
      showContactPlanner
      buttonColor
      multipleRounds
      registrationButtonText
    }
  }
`

export const GET_PAIRINGS = gql`
  query getPlayersAndTeams($input: GetPairings) {
    getPlayersAndTeams(input: $input) {
      players {
        id
        firstName
        lastName
        comments
      }
      teams {
        id
        teamName
        holeNumber
        players {
          id
          firstName
          lastName
          registrationID
        }
      }
    }
  }
`

export const GET_SUPER_ADMIN = gql`
  query getSuperAdmin {
    SuperAdmin {
      id
      user
    }
  }
`
