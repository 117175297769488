import React from 'react'
import styled from 'styled-components/macro'

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100vw;
  height: 100vh;
  padding: 10px;
`

const ErrorBoundary = () => (
  <Container>
    <h1>An error has ocurred.</h1>
    <h3>
      Please refresh the page and contact support at support@golfinvite.com if
      you continue to have problems.
    </h3>
  </Container>
)

// class ErrorBoundary extends React.Component {
//   constructor(props) {
//     super(props)
//     this.state = {
//         hasError: false
//     }
// }

//   static getDerivedStateFromError(error) {
//     // Update state so the next render will show the fallback UI.
//     console.error(error)
//     return { hasError: true };
//   }

//   componentDidCatch(error, info) {
//     // Log the error to an error reporting service
//     console.error(error, info);
//   }

//   render() {
//     if (this.state.hasError) {
//       // You can render any custom fallback UI
//       return (<Container><h1>An error has ocurred.</h1>
//       <h3>Please refresh the page and contact support at support@golfinvite.com if you continue to have problems.</h3></Container>);
//     }

//     return this.props.children;
//   }
// }

export default ErrorBoundary
