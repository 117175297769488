import {useQuery, useQueryClient} from 'react-query'

function useUserLookup() {
  const cache = useQueryClient()
  return useQuery('currentUser', async () => {
    const user = await cache.getQueryData('currentUser')
    return user
  })
}

export default useUserLookup
