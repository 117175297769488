//
import React, {Fragment} from 'react'
import CustomQuestionItem from './CustomQuestionItem'
import styled from 'styled-components/macro'

const QuestionListContainer = styled.div`
  margin-top: 1rem;
  margin-bottom: 0;
`

const CustomQuestions = props => (
  <QuestionListContainer data-cy={`${props.errorIDBase}CustomQuestions`}>
    {props.questions &&
      props.questions.map(question => (
        <Fragment key={question && question.id}>
          {question ? (
            <CustomQuestionItem
              errorIDBase={props.errorIDBase}
              question={question}
              handleChange={props.handleChange}
              errors={props.errors}
              response={
                props.responses
                  ? props.responses.find(
                      response =>
                        response && response.questionID === question.id,
                    ) || {
                      questionID: '',
                      question: '',
                      response: '',
                    }
                  : {
                      questionID: '',
                      question: '',
                      response: '',
                    }
              }
            />
          ) : (
            <Fragment />
          )}
        </Fragment>
      ))}
  </QuestionListContainer>
)

export default CustomQuestions
