//
import React, {useEffect, useState} from 'react'
import styled from 'styled-components/macro'
import {useParams} from 'react-router-dom'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
// import faGolfBall from "@fortawesome/fontawesome-free-solid/faGolfBall";
import Banner from '../../assets/images/Banner.svg'
import Product from '../../assets/images/Product.svg'
import ShoppingBag from '../../assets/images/ShoppingBag.svg'
import Sign from '../../assets/images/Sign.svg'
import Standard from '../../assets/images/Standard.svg'
import Ticket from '../../assets/images/Ticket.svg'
import {Panel, RegistrationButton} from '../../assets/elements'

const Icon = styled.img`
  height: 70px;
  @media (min-width: 1000px) {
    height: 100px;
  }
`

const ItemDescriptionClose = styled.div`
  display: flex;
  padding: 0 10px;
`

const ItemDescription = styled.div`
  white-space: pre-wrap;
  margin-top: 3px;
`

const IconAndDescription = styled.div`
  display: flex;
  max-width: 640px;
  img {
    margin-bottom: 5px;
  }
`

const ItemPanel = styled(Panel)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  @media (min-width: 1000px) {
    flex-direction: row;
    justify-content: space-between;
  }
`

const ButtonText = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  @media (min-width: 1000px) {
    flex-direction: column;
  }
`

const Price = styled.span`
  font-weight: 700;
  font-size: 1.2rem;
  padding-left: 2px;
  @media (min-width: 1000px) {
    padding-left: 0;
  }
`

const QuantityInCart = styled.div`
  font-size: 0.9rem;
`

const CloseButton = styled.button`
  display: flex;
  justify-content: center;
  padding: 0.35rem;
  width: 2em;
  height: 2em;
  margin-right: 5px;
`

const SponsorshipItemCard = props => {
  const {id} = useParams()
  const [enoughPlayers, setEnoughPlayers] = useState(true)
  useEffect(() => {
    //make sure players are available
    if (!id) {
      if (props.item.playersIncluded > 0) {
        if (props.event.multipleRounds) {
          props.event.rounds.map(r => {
            if (r && props.item && r.roundID === props.item.roundID) {
              const roundAvailable = Number(r.available) - r.quantityInCart
              setEnoughPlayers(roundAvailable >= props.item.playersIncluded)
            }
            return r
          })
        } else {
          let available =
            (props.event.playersAvailable || 0) -
            (props.players ? props.players.length : 0)
          setEnoughPlayers(available >= props.item.playersIncluded)
        }
      }
    }
  }, [
    id,
    props.event.multipleRounds,
    props.event.playersAvailable,
    props.event.rounds,
    props.item,
    props.item.playersIncluded,
    props.players,
  ])
  return (
    <ItemPanel data-cy="sponsorship-item-card">
      <IconAndDescription>
        {((!props.item.icon && props.kind === 'sponsorship') ||
          props.item.icon === 'Sign') && <Icon src={Sign} />}
        {((!props.item.icon && props.item.kind !== 'sponsorship') ||
          props.item.icon === 'Ticket') && <Icon src={Ticket} />}
        {props.item.icon === 'Banner' && <Icon src={Banner} />}
        {props.item.icon === 'Product' && <Icon src={Product} />}
        {props.item.icon === 'ShoppingBag' && <Icon src={ShoppingBag} />}
        {props.item.icon === 'Standard' && <Icon src={Standard} />}

        <ItemDescriptionClose>
          {props.item.quantityInCart > 0 && (
            <CloseButton
              type="button"
              className="btn btn-sm btn-secondary"
              onClick={props.removeItemClick}
              data-itemid={props.item && props.item.id}
              data-testid={`card-si-${props.item.id}`}
              data-cy="removeSponsorshipButton"
            >
              <FontAwesomeIcon icon="times-circle" />
            </CloseButton>
          )}
          <ItemDescription>
            <h5 data-cy="sponsorshipItemTitle">{props.item.itemName}</h5>
            <h6 data-cy="sponsorshipItemDescription">
              {props.item.description}
            </h6>
            <h6 data-cy="sponsorshipItemPlayersIncluded">
              {props.item.playersIncluded
                ? `${props.item.playersIncluded} Players Included`
                : ''}
            </h6>
          </ItemDescription>
        </ItemDescriptionClose>
      </IconAndDescription>

      <RegistrationButton
        type="button"
        fontColor="#555555"
        backgroundColor="#e0e1e2"
        data-cy="addSponsorshipItem"
        onClick={() => props.addItem(props.item)}
        disabled={
          (!id &&
            (props.item.quantityAvailable || 0) - props.item.quantityInCart <=
              0) ||
          !enoughPlayers
        }
      >
        <ButtonText>
          {!id && props.item.quantityAvailable === 0 ? (
            <>
              {props.item.quantityInCart > 0 && (
                <span className="text-muted">
                  {props.item.quantityInCart || 0} In Cart
                </span>
              )}
              <span className="text-muted">Sold Out</span>
            </>
          ) : !id && !enoughPlayers ? (
            <>
              {props.item.quantityInCart > 0 && (
                <span className="text-muted">
                  {props.item.quantityInCart || 0} In Cart
                </span>
              )}
              <span className="text-muted">Players Sold Out</span>
            </>
          ) : (
            <span>
              Add to cart
              <br />
              <Price>
                $
                {Number(props.item.price).toLocaleString(undefined, {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                })}
              </Price>
              <QuantityInCart>
                {props.item.quantityInCart || 0} In Cart
              </QuantityInCart>
              <QuantityInCart>
                {(props.item.quantityAvailable || 0) -
                  (props.item.quantityInCart || 0)}{' '}
                available
              </QuantityInCart>
            </span>
          )}
        </ButtonText>
      </RegistrationButton>
    </ItemPanel>
  )
}

export default SponsorshipItemCard
