//
import * as React from 'react'

import {Link} from 'react-router-dom'
import PlannerMessageModal from './PlannerMessageModal'
import styled from 'styled-components/macro'
import {Button, Card} from 'reactstrap'

const StyledButton = styled(Button)`
  margin: 1px 0;
  background-color: ${p => (p.bcolor ? p.bcolor : '#353432')};
  :focus {
    box-shadow: none;
  }
  color: ${p => (p.textcolor ? p.textcolor : '#ffffff')};
`

const ActionButtonsContainer = styled(Card)`
  width: 100%;
  max-width: 300px;
`
const LandingPageButtons = props => {
  const [showModal, setShowModal] = React.useState(false)
  return (
    <ActionButtonsContainer>
      <div data-cy="landingPageButtons">
        {props.event.status === 'active' ? (
          <>
            <Link to={`/${props.event ? props.event.slug : ''}/registration`}>
              <StyledButton
                block
                bcolor={props.event.buttonColor}
                textcolor={props.textColor}
              >
                {props.event.registrationButtonText || 'Register For Event'}
              </StyledButton>
            </Link>
            {props.showSponsorshipButton && (
              <Link to={`/${props.event ? props.event.slug : ''}/sponsorship`}>
                <StyledButton
                  block
                  bcolor={props.event.buttonColor}
                  textcolor={props.textColor}
                >
                  Sponsor This Event
                </StyledButton>
              </Link>
            )}

            {props.event.allowDonors && (
              <Link to={`/${props.event ? props.event.slug : ''}/donation`}>
                <StyledButton
                  block
                  bcolor={props.event.buttonColor}
                  textcolor={props.textColor}
                >
                  Make A Donation
                </StyledButton>
              </Link>
            )}
            {props.event.allowVolunteers && (
              <Link to={`/${props.event ? props.event.slug : ''}/volunteer`}>
                <StyledButton
                  block
                  bcolor={props.event.buttonColor}
                  textcolor={props.textColor}
                >
                  Be A Volunteer
                </StyledButton>
              </Link>
            )}
          </>
        ) : (
          <StyledButton
            block
            bcolor={props.event.buttonColor}
            textcolor={props.textColor}
          >
            Registration Closed
          </StyledButton>
        )}
        {props.event.showPairings && (
          <Link
            to={`/${props.event ? props.event.slug : ''}/pairings`}
            target="_blank"
          >
            <StyledButton
              block
              bcolor={props.event.buttonColor}
              textcolor={props.textColor}
            >
              Pairings
            </StyledButton>
          </Link>
        )}
        {props.event && props.event.organizationWebsite && (
          <a
            href={
              props.event.organizationWebsite.startsWith('http')
                ? props.event.organizationWebsite
                : `https://${props.event.organizationWebsite}`
            }
            target="_blank"
            rel="noopener noreferrer"
          >
            <StyledButton
              block
              title={`Click here to visit ${
                props.event && props.event.organizationName
                  ? props.event.organizationName
                  : ''
              }`}
              bcolor={props.event.buttonColor}
              textcolor={props.textColor}
            >
              {props.event.slug === 'ubcf' || props.event.slug === 'ubcftest'
                ? 'Nonprofit Partner'
                : 'Organization'}
            </StyledButton>
          </a>
        )}
        {props.event && props.event.showContactPlanner && (
          <StyledButton
            block
            bcolor={props.event.buttonColor}
            onClick={() => setShowModal(!showModal)}
            textcolor={props.textColor}
          >
            Contact Planner
          </StyledButton>
        )}
        {showModal && (
          <PlannerMessageModal
            eventID={props.event.id}
            showModal={showModal}
            toggleModal={() => setShowModal(!showModal)}
          />
        )}
      </div>
    </ActionButtonsContainer>
  )
}

export default LandingPageButtons
