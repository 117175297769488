import React, {Suspense, lazy} from 'react'
import {Switch, Route, Redirect} from 'react-router-dom'

import LoadingSpinner from './assets/Spinner'
import EventPageContainer from './EventPage/EventPageContainer'
import LandingPageContainer from './EventPage/LandingPageContainer'
import ScrollToTop from './marketing/ScrollToTop'
import styled from 'styled-components/macro'
import EventsReport from 'PlannerAdmin/EventsReport'
import UnderMaintenance from 'UnderMaintenance'

function retry(fn, retriesLeft = 5, interval = 500) {
  return new Promise((resolve, reject) => {
    fn()
      .then(resolve)
      .catch(error => {
        setTimeout(() => {
          if (retriesLeft === 1) {
            // reject('maximum retries exceeded');
            alert('Network error, please refresh the page')
            console.error('network failure')
            reject(error)
            return
          }
          // Passing on "reject" is the important part
          retry(fn, retriesLeft - 1, interval).then(resolve, reject)
        }, interval)
      })
  })
}

const PlannerLogin = lazy(() =>
  retry(() => import('./PlannerAdmin/PlannerLogin')),
)
const ForgotPassword = lazy(() =>
  retry(() => import('./PlannerAdmin/ForgotPassword')),
)
const ProfileAdmin = lazy(() =>
  retry(() => import('./PlannerAdmin/ProfileAdmin')),
)
const PlannerSignup = lazy(() =>
  retry(() => import('./PlannerAdmin/PlannerSignup')),
)
const PlannerAdmin = lazy(() =>
  retry(() => import('./PlannerAdmin/PlannerAdmin')),
)
const SuperAdmin = lazy(() => retry(() => import('./PlannerAdmin/SuperAdmin')))
const Nav = lazy(() => retry(() => import('./marketing/Nav')))
const Footer = lazy(() => retry(() => import('./marketing/Footer')))
const EventContainer = lazy(() =>
  retry(() => import('./EventAdmin/EventContainer')),
)
// const VolunteerPageContainer = lazy(() =>
//   retry(() => import('./EventPage/VolunteerPageContainer')),
// )
// const DonationPageContainer = lazy(() =>
//   retry(() => import('./EventPage/Donations/DonationPageContainer')),
// )
const Pairings = lazy(() => retry(() => import('./EventPage/Pairings')))
const Firebase = lazy(() => retry(() => import('./PlannerAdmin/Firebase')))
const Big3Tour = lazy(() => retry(() => import('./Big3Tour/Big3Tour')))
const Payment = lazy(() => retry(() => import('./Payment')))
const StripeAccountResponse = lazy(() =>
  retry(() => import('./EventAdmin/Payments/StripeAccountResponse')),
)

const RootDiv = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
`

const AppLayout = props => {
  return (
    <RootDiv>
      {props.navBar ? React.createElement(props.navBar) : null}

      <ScrollToTop>{props.children}</ScrollToTop>

      {props.footer ? React.createElement(props.footer) : null}
    </RootDiv>
  )
}

const AppRoute = ({component, ...routeProps}) => {
  return (
    <Route
      {...routeProps}
      render={props => {
        return (
          <AppLayout {...props} {...routeProps}>
            {React.createElement(component, props)}
          </AppLayout>
        )
      }}
    />
  )
}

const PrivateRoute = ({component, ...routeProps}) => {
  const loggedIn = localStorage.getItem('loggedIn')
  if (!loggedIn) {
    return <Redirect to="/login" />
  } else {
    return (
      <Route
        {...routeProps}
        render={props => {
          return (
            <AppLayout {...props} {...routeProps}>
              {React.createElement(component, props)}
            </AppLayout>
          )
        }}
      />
    )
  }
}

const Routes = () => {
  return process.env.REACT_APP_MAINTENANCE_MODE === 'true' ? (
    <Suspense fallback={<LoadingSpinner />}>
      <Switch>
        <AppRoute
          path="/"
          component={UnderMaintenance}
          navBar={Nav}
          footer={Footer}
        />
      </Switch>
    </Suspense>
  ) : (
    <Suspense fallback={<LoadingSpinner />}>
      <Switch>
        <AppRoute
          exact
          path="/"
          component={PlannerLogin}
          navBar={Nav}
          footer={Footer}
        />
        <AppRoute path="/big3Tour" component={Big3Tour} />
        <AppRoute
          path="/login"
          component={PlannerLogin}
          navBar={Nav}
          footer={Footer}
        />
        <AppRoute
          path="/signup"
          navBar={Nav}
          footer={Footer}
          component={PlannerSignup}
        />
        <AppRoute
          path="/forgot"
          navBar={Nav}
          footer={Footer}
          component={ForgotPassword}
        />
        <AppRoute
          path="/payment"
          component={Payment}
          navBar={Nav}
          footer={Footer}
        />
        <PrivateRoute
          path="/:event/registration/:id"
          component={EventPageContainer}
        />
        <PrivateRoute
          path="/:event/volunteer/:id"
          component={EventPageContainer}
        />
        {/* <PrivateRoute
          path="/:event/donation/:id"
          component={EventPageContainer}
        /> */}
        <AppRoute path="/:event/registration" component={EventPageContainer} />
        <AppRoute path="/:event/sponsorship" component={EventPageContainer} />
        <AppRoute path="/:event/donation" component={EventPageContainer} />
        <AppRoute path="/:event/volunteer" component={EventPageContainer} />
        {/* <AppRoute path="/:event/donation" component={DonationPageContainer} /> */}
        <AppRoute path="/:event/pairings" component={Pairings} />
        <AppRoute
          path="/__/auth/:action"
          component={Firebase}
          navBar={Nav}
          footer={Footer}
        />
        <AppRoute
          path="/stripesetup"
          component={StripeAccountResponse}
          navBar={false}
          footer={false}
        />
        <PrivateRoute
          path="/admin"
          component={PlannerAdmin}
          navBar={Nav}
          footer={Footer}
        />
        <PrivateRoute
          path="/superadmin/report"
          component={EventsReport}
          navBar={Nav}
          footer={Footer}
        />
        <PrivateRoute
          path="/superadmin"
          component={SuperAdmin}
          navBar={Nav}
          footer={Footer}
        />
        <PrivateRoute
          path="/profileAdmin"
          component={ProfileAdmin}
          navBar={Nav}
          footer={Footer}
        />
        <PrivateRoute
          path="/:event/admin/:tab/:section"
          component={EventContainer}
          navBar={Nav}
          footer={Footer}
        />
        <PrivateRoute
          path="/:event/admin/:tab"
          component={EventContainer}
          navBar={Nav}
          footer={Footer}
        />
        <PrivateRoute
          path="/:event/admin"
          component={EventContainer}
          navBar={Nav}
          footer={Footer}
        />
        <AppRoute path="/:event" component={LandingPageContainer} />{' '}
        {/*This one needs to be last */}
      </Switch>
    </Suspense>
  )
}

export default Routes
