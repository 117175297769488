//
import React, {Fragment} from 'react'
import {useLocation} from 'react-router-dom'
import styled from 'styled-components/macro'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'

//import headerImage from "../assets/images/Bolingbrook_Demo2.png";

import RegistrationProfile from './RegistrationProfile'
import PlayerButton from './PlayerRegistration/PlayerButton'
import GuestItemList from './GuestRegistration/GuestItemList'
import SponsorshipItemList from './SponsorshipItems/SponsorshipItemList'
import DonationItems from './Donations/DonationItems'
import EventPageSubmitButton from './EventPageSubmitButton'

import CustomQuestions from './CustomQuestions'
import CartPreview from './CartPreview'
import Header from './Header'
import {AdminBanner, AdminBannerPush} from '../assets/elements'
//import EventInformationSection from "./EventInformationSection";
//import EventPageContainer from "./EventPageContainer";
import {Card, CardHeader, CardBody} from 'reactstrap'
import MissingInformationMessages from './MissingInformationMessages'

//import type { PlayerType, GuestType, ItemType } from "../TypeAliases";

const Container = styled.div`
  max-width: 1200px;
  margin: 0 auto;
  width: 98%;
  @media (min-width: 600px) {
    width: 90%;
  }
`

const BodySection = styled.div`
  display: flex;
  flex-direction: column;
  @media (min-width: 1000px) {
    flex-direction: row;
  }
`

const Panel = styled.div`
  width: 100%;
  position: relative;
  background: #fff;
  box-shadow: 0 1px 2px 0 rgba(34, 36, 38, 0.15);
  margin: 0;
  padding: 0.5em;
  @media (min-width: 600px) {
    padding: 1em;
  }
  border-radius: 0.28571429rem;
  border: 1px solid rgba(34, 36, 38, 0.15);
`

const LeftPanel = styled(Panel)`
  width: 100%;
  .styledSubmit,
  .missingInformationMessages {
    display: none;
  }
  @media (min-width: 1000px) {
    width: 72%;
    .styledSubmit,
    .missingInformationMessages {
      display: inline-block;
    }
  }
`

const RightPanel = styled(Panel)`
  width: 100%;
  @media (min-width: 1000px) {
    width: 28%;
  }
`

const AdminHeader = styled.h3`
  text-align: center;
`

const AdminHeaderPanel = styled(Panel)`
  background: #ccc;
  border-color: darkblue;
  border-width: 3px;
`

const EventInstructions = styled.div`
  white-space: pre-wrap;
`

const StickyCart = styled.div`
  position: -webkit-sticky;
  position: sticky;
  top: ${props => (props.adminMode ? '45px' : '10px')};
`

const AdminRequiredMessage = styled.div`
  font-size: 13px;
  color: #00c;
  text-align: center;
  padding-top: 3px;
  margin-bottom: -5px;
`

const Attributions = styled.div`
  font-size: 11px;
  font-style: italic;
  a {
    margin-left: 2px;
  }
`

const EventPage = props => {
  const location = useLocation()
  const pathname = location.pathname
  const donationsOnly = pathname.includes('/donation')
  const sponsorsOnly = pathname.includes('/sponsorship')
  const volunteersOnly =
    pathname.includes('/volunteer') && props.event.allowVolunteers
  const allElements = !donationsOnly && !sponsorsOnly && !volunteersOnly

  return (
    <Container>
      {props.event && !props.registrationComplete ? (
        <Fragment>
          {props.adminMode ? (
            <Fragment>
              <AdminBanner onClick={props.adminGoBack} data-cy="adminBanner">
                You are in Planner Mode, click here to return - All fields are
                optional in planner mode
              </AdminBanner>
              <AdminBannerPush />
            </Fragment>
          ) : (
            <Fragment />
          )}
          <Header
            homeHeaderHTML={props.event.homeHeaderHTML || ''}
            data-cy="eventPageHeader"
          />
          <Fragment />
          {props.editingRegistration ? (
            <AdminHeaderPanel>
              <AdminHeader>Planner Mode - Editing Registration</AdminHeader>
            </AdminHeaderPanel>
          ) : (
            <Fragment />
          )}
          <form data-testid="registrationForm" noValidate>
            <BodySection>
              <LeftPanel>
                {volunteersOnly && <h3>Volunteer Registration</h3>}
                {props.event && (
                  <RegistrationProfile
                    handleChange={props.registrantProfileChange}
                    registrant={props.registrant}
                    event={props.event}
                    errors={props.errors}
                    commentsPlaceholder={
                      volunteersOnly
                        ? 'Please describe what you would like to volunteer for!'
                        : ''
                    }
                  />
                )}
                {(props.showDonorQuestions ||
                  props.showSponsorshipQuestions ||
                  volunteersOnly) && (
                  <CustomQuestions
                    errorIDBase={`registrant`}
                    questions={props.registrantQuestions}
                    responses={props.questionResponses || []}
                    handleChange={props.registrantQuestionChanged}
                    errors={props.errors}
                  />
                )}
                {props.showSponsorshipQuestions && (
                  <CustomQuestions
                    errorIDBase={`sponsorship`}
                    questions={props.sponsorshipQuestions}
                    responses={props.questionResponses || []}
                    handleChange={props.registrantQuestionChanged}
                    errors={props.errors}
                  />
                )}
                {volunteersOnly && (
                  <CustomQuestions
                    errorIDBase={`volunteer`}
                    questions={props.volunteerQuestions}
                    responses={props.questionResponses || []}
                    handleChange={props.registrantQuestionChanged}
                    errors={props.errors}
                  />
                )}
                <div className="card">
                  <div className="card-body">
                    {props.event && allElements && (
                      <PlayerButton
                        event={props.event}
                        players={props.players}
                        addPlayerClick={props.addPlayerClick}
                        removePlayerClick={props.removePlayerClick}
                        removeGroupClick={props.removeGroupClick}
                        playerChange={props.playerChange}
                        // availablePlayers={props.availablePlayers}
                        questions={props.playerQuestions}
                        errors={props.errors}
                      />
                    )}
                    {/* )} */}
                    {props.event &&
                      pathname &&
                      props.event.guestFeeOptions.length > 0 &&
                      allElements && (
                        <GuestItemList
                          event={props.event}
                          guests={props.guests}
                          addGuestClick={props.addGuestClick}
                          removeGuestClick={props.removeGuestClick}
                          guestChange={props.guestChange}
                          questions={props.guestQuestions}
                          errors={props.errors}
                        />
                      )}
                  </div>
                </div>

                {props.event &&
                  props.event.sponsorshipItems &&
                  (allElements || sponsorsOnly) && (
                    <SponsorshipItemList
                      event={props.event}
                      addItem={props.addItem}
                      players={props.players}
                      sponsorshipItems={props.event.sponsorshipItems}
                      playerChange={props.playerChange}
                      kind="sponsorship"
                      questions={props.playerQuestions}
                      errors={props.errors}
                      removeItemClick={props.removeItemClick}
                    />
                  )}

                {props.event && props.event.sponsorshipItems && allElements && (
                  <SponsorshipItemList
                    event={props.event}
                    addItem={props.addItem}
                    kind="other"
                    removeItemClick={props.removeItemClick}
                  />
                )}

                {props.event?.allowDonors && (allElements || donationsOnly) && (
                  <DonationItems
                    donations={props.donations || []}
                    updateDonations={props.updateDonations}
                  />
                )}

                {props.showDonorQuestions && (
                  <CustomQuestions
                    errorIDBase={`donor`}
                    questions={props.donorQuestions}
                    responses={props.questionResponses || []}
                    handleChange={props.registrantQuestionChanged}
                    errors={props.errors}
                  />
                )}

                {props.adminMode ? (
                  <AdminRequiredMessage>
                    <FontAwesomeIcon icon="info-circle" /> All fields are
                    optional in Planner Mode
                  </AdminRequiredMessage>
                ) : (
                  <MissingInformationMessages
                    registrationError={props.registrationError}
                    playerError={props.playerError}
                    guestError={props.guestError}
                    donorError={props.donorError}
                  />
                )}

                {props.event && (
                  <EventPageSubmitButton
                    adminMode={props.adminMode}
                    event={props.event}
                    cartItemCount={props.cartItemCount}
                    cartTotal={props.cartTotal}
                    formError={props.formError}
                    errors={props.errors}
                    submitRegistration={props.submitRegistration}
                    toggleCartModal={props.toggleCartModal}
                  />
                )}
              </LeftPanel>
              <RightPanel>
                {props.event && (
                  <StickyCart adminMode={props.adminMode}>
                    {!volunteersOnly && (
                      <CartPreview
                        players={props.players}
                        guests={props.guests}
                        event={props.event}
                        donations={props.donations}
                        sponsorshipItems={props.sponsorshipItems}
                        removeGroupClick={props.removeGroupClick}
                        removeGuestClick={props.removeGuestClick}
                        removeItemClick={props.removeItemClick}
                        removeDonationClick={props.removeDonationClick}
                        cartTotal={props.cartTotal}
                        playerDiscounts={props.playerDiscounts}
                      />
                    )}

                    {props.adminMode ? (
                      <AdminRequiredMessage>
                        <FontAwesomeIcon icon="info-circle" /> All fields are
                        optional in Planner Mode
                      </AdminRequiredMessage>
                    ) : (
                      <MissingInformationMessages
                        registrationError={props.registrationError}
                        playerError={props.playerError}
                        guestError={props.guestError}
                        donorError={props.donorError}
                      />
                    )}

                    <EventPageSubmitButton
                      adminMode={props.adminMode}
                      event={props.event}
                      cartItemCount={props.cartItemCount}
                      cartTotal={props.cartTotal}
                      formError={props.formError}
                      submitRegistration={props.submitRegistration}
                      toggleCartModal={props.toggleCartModal}
                      submitObject={props.submitObject}
                      errors={props.errors}
                    />

                    {props.event && props.event.eventInstructions ? (
                      <Card>
                        <CardHeader>Event Instructions</CardHeader>
                        <CardBody>
                          <EventInstructions>
                            {props.event.eventInstructions}
                          </EventInstructions>
                        </CardBody>
                      </Card>
                    ) : (
                      <Fragment />
                    )}
                  </StickyCart>
                )}
              </RightPanel>
            </BodySection>
          </form>
          <Attributions>
            <div>
              Icons made by
              <a
                href="https://www.flaticon.com/authors/freepik"
                title="Freepik"
              >
                Freepik
              </a>{' '}
              <a
                href="https://www.flaticon.com/authors/smashicons"
                title="Smashicons"
              >
                Smashicons
              </a>{' '}
              <a
                href="https://www.flaticon.com/authors/vectors-market"
                title="Vectors Market"
              >
                Vectors Market
              </a>{' '}
              <a
                href="https://www.flaticon.com/authors/darius-dan"
                title="Darius Dan"
              >
                Darius Dan
              </a>{' '}
              <a
                href="https://www.flaticon.com/authors/ultimatearm"
                title="ultimatearm"
              >
                ultimatearm
              </a>{' '}
              <a
                href="https://www.flaticon.com/authors/flat-icons"
                title="Flat Icons"
              >
                Flat Icons
              </a>{' '}
              from{' '}
              <a href="https://www.flaticon.com/" title="Flaticon">
                www.flaticon.com
              </a>
            </div>
          </Attributions>
        </Fragment>
      ) : null}
    </Container>
  )
}

export default EventPage
