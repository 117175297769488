import * as React from 'react'
import styled from 'styled-components/macro'
import Nav from '../marketing/Nav'
import Footer from '../marketing/Footer'

const Container = styled.div`
  display: flex;
  flex-direction: column;
`

const Error = props => {
  return (
    <Container>
      <Nav />
      <div className="container bg-light">
        <div className="jumbotron">
          <h1 className="display-4 text-center">{props.header}</h1>
          <p className="lead">{props.body}</p>
        </div>
      </div>
      <Footer />
    </Container>
  )
}
export default Error
