//     
import * as React from 'react'

import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Container,
  FormGroup,
  Label,
  Input,
} from 'reactstrap'
import styled from 'styled-components/macro'
                                               

const AdminContainer = styled.div`
  padding: 15px;
`

const FormContainer = styled.div`
  padding: 15px;
`

const MonetaryCheckbox = styled.div`
  padding: 10px;
  margin-left: -25px;
`

              
                             
                           
                            
                                                                
                         
                     
 

//on focus - select entire input value
const onFocus = event => event.target.select()

const DonationModal = (props       ) => {
  // const valueRef = React.useRef(null)
  // const focusOnValue = () => {
  //   if (valueRef?.current) {
  //     valueRef.current.focus()
  //   }
  // }
  return (
    <Modal
      isOpen={props.showDonationModal}
      toggle={props.toggleDonationModal}
      // onOpened={focusOnValue}
      autoFocus={false}
    >
      <ModalHeader>New Donation</ModalHeader>
      <ModalBody>
        <AdminContainer>
          <Container>
            <FormContainer>
              <FormGroup row>
                <Label for="value">Value</Label>
                <Input
                  type="text"
                  name="value"
                  id="value"
                  placeholder="Value"
                  data-cy="donationValue"
                  onChange={props.updateDonation}
                  // ref={valueRef}
                  autoFocus={true}
                  invalid={
                    !props.editingDonation.value ||
                    props.editingDonation.value === ''
                  }
                  value={props.editingDonation.value}
                  onFocus={onFocus}
                />
              </FormGroup>
              <FormGroup check>
                <MonetaryCheckbox>
                  <Label for="monetary" check>
                    <Input
                      type="checkbox"
                      name="monetary"
                      id="monetary"
                      data-cy="monetaryInput"
                      onChange={props.updateDonation}
                      checked={props.editingDonation.monetary}
                    />
                    Monetary Donation?
                  </Label>
                </MonetaryCheckbox>
              </FormGroup>
              {props.editingDonation.monetary ? null : (
                <FormGroup row>
                  <Label for="description">Description</Label>
                  <Input
                    type="text"
                    name="description"
                    id="description"
                    placeholder="Description of donation"
                    data-cy="nonmonetaryDescription"
                    onChange={props.updateDonation}
                    onFocus={onFocus}
                    invalid={
                      !props.editingDonation.description ||
                      props.editingDonation.description === ''
                    }
                    value={props.editingDonation.description}
                  />
                </FormGroup>
              )}
            </FormContainer>
          </Container>
        </AdminContainer>
      </ModalBody>
      <ModalFooter>
        <Button
          type="button"
          color="success"
          disabled={
            Number(props.editingDonation.value) < 0.01 ||
            (!props.editingDonation.monetary &&
              !props.editingDonation.description)
          }
          onClick={props.formSubmit}
        >
          {props.editingDonation.id !== '' ? 'Update' : 'Add'}
        </Button>{' '}
        <Button type="button" color="secondary" onClick={props.cancel}>
          Cancel
        </Button>
      </ModalFooter>
    </Modal>
  )
}

export default DonationModal
