//      
import React from 'react'

const CurrencyDisplay = ({amount = '0'}                           ) => (
  <span>
    {Number(amount).toLocaleString('en-US', {
      style: 'currency',
      currency: 'USD',
    })}
  </span>
)

export default CurrencyDisplay
