import * as React from 'react'
import styled from 'styled-components/macro'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'

const MissingInformationMessage = styled.div`
  font-size: 13px;
  color: #cc0000;
  text-align: center;
  padding-top: 3px;
  margin-bottom: -5px;
`

const Container = styled.div`
  text-align: center;
  width: 100%;
`

const MissingInformationMessages = props => {
  return (
    <Container
      className="missingInformationMessages"
      data-cy="missingInformation"
    >
      {props.registrationError && (
        <MissingInformationMessage>
          <FontAwesomeIcon icon="exclamation-triangle" /> Missing required info
          in Registration Profile
        </MissingInformationMessage>
      )}
      {props.playerError && (
        <MissingInformationMessage>
          <FontAwesomeIcon icon="exclamation-triangle" /> Missing required info
          in Player Section
        </MissingInformationMessage>
      )}
      {props.guestError && (
        <MissingInformationMessage>
          <FontAwesomeIcon icon="exclamation-triangle" /> Missing required info
          in Guest Section
        </MissingInformationMessage>
      )}
      {props.donorError && (
        <MissingInformationMessage>
          <FontAwesomeIcon icon="exclamation-triangle" /> Missing required info
          in Donor Section
        </MissingInformationMessage>
      )}
    </Container>
  )
}
export default MissingInformationMessages
