import * as React from 'react'
import styled from 'styled-components/macro'

const Container = styled.div`
  min-height: calc(100vh - 197px);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`

const UnderMaintenance = () => {
  return (
    <Container>
      <h2>We are currently down for maintenance.</h2>
      <h5>
        Please try again later. Contact support@golfinvite.com if you need
        immediate assistance
      </h5>
    </Container>
  )
}
export default UnderMaintenance
