//
import React, {Fragment} from 'react'
import styled from 'styled-components/macro'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {Card, CardHeader, CardBody, CardFooter} from 'reactstrap'

import {calculatePlayerTotal} from './EventPageContainerHelpers'

import CurrencyDisplay from './CurrencyDisplay'

const CartContainer = styled(Card)`
  padding: 0;
`

const StyledCardBody = styled(CardBody)`
  padding: 1.25rem 0.5rem;
`

const CartItem = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 5px 0;
  font-size: 15px;
`

const TotalFooter = styled.div`
  display: flex;
  justify-content: space-between;
`

const PriceSpan = styled.span`
  /* min-width: 70px; */
  margin-top: 7px;
  text-align: right;
  /* white-space: nowrap; */
`

const ItemName = styled.div`
  margin: 7px 5px;
  display: flex;
  align-items: flex-start;
`

const RequiredFlag = styled.sup`
  font-size: 0.7rem;
  color: #dc3545;
  margin-left: 5px;
`

const CloseButton = styled.button`
  display: flex;
  justify-content: center;
  width: 1.75em;
  height: 1.75em;
  margin-top: 7px;
`

const CloseButtonAndItemName = styled.span`
  display: flex;
`

const TotalNonMonetaryDisclosure = styled.span`
  font-size: 0.8rem;
  font-style: italic;
`
const CartPreview = props => {
  const nonMonetaryDonations = props.donations
    ? props.donations.filter(d => d && !d.monetary)
    : []
  return (
    <CartContainer data-cy="shoppingCartPanel">
      <CardHeader>Shopping Cart</CardHeader>
      <StyledCardBody>
        {props.event &&
          props.event.playerFeeOptions &&
          props.event.playerFeeOptions.map((option, index) => {
            if (
              !option ||
              !option.quantityInCart ||
              option.quantityInCart < 1
            ) {
              return null
            }

            let playerTotal = option.quantityInCart * (option.playerFee || 0)
            if (option.soldAs === 1) {
              playerTotal = calculatePlayerTotal(
                option.quantityInCart,
                option.playerFee || 0,
                option.twosomeDiscount || 0,
                option.foursomeDiscount || 0,
              )
            }

            return (
              <div key={index}>
                <CartItem data-cy="groupCartItemContainer">
                  <CloseButtonAndItemName>
                    <CloseButton
                      type="button"
                      className="btn btn-sm btn-secondary"
                      onClick={props.removeGroupClick}
                      data-optionid={option.id || ''}
                      data-testid={`popt-${option.id || ''}`}
                    >
                      <FontAwesomeIcon icon="times-circle" />
                    </CloseButton>
                    <ItemName>
                      {option.quantityInCart}x {option.playerOptionName}{' '}
                    </ItemName>
                  </CloseButtonAndItemName>
                  <PriceSpan data-testid="playeroption-fee">
                    <CurrencyDisplay amount={playerTotal} />
                  </PriceSpan>
                </CartItem>
              </div>
            )
          })}

        {props.guests &&
          props.guests.map(guest => {
            if (!guest) return null
            return (
              <div key={guest.guestNumber}>
                <CartItem>
                  <CloseButtonAndItemName>
                    <CloseButton
                      type="button"
                      className="btn btn-sm btn-secondary"
                      onClick={props.removeGuestClick}
                      data-guestid={guest.guestNumber}
                      data-testid={`gu-${guest ? guest.guestNumber : ''}`}
                    >
                      <FontAwesomeIcon icon="times-circle" />
                    </CloseButton>
                    {/* <AvatarImage src={Meal} /> */}
                    <ItemName>
                      {guest.firstName ||
                        `Guest${
                          guest.guestNumber ? guest.guestNumber : ''
                        }`}{' '}
                      {guest.lastName || ''}
                    </ItemName>
                  </CloseButtonAndItemName>

                  <PriceSpan data-testid="guest-fee">
                    <CurrencyDisplay amount={Number(guest.guestFee) || 0} />
                  </PriceSpan>
                </CartItem>
              </div>
            )
          })}

        {props.donations &&
          props.donations.map((donation, index) => {
            if (!donation) return null
            return (
              <div key={index}>
                <CartItem>
                  <CloseButtonAndItemName>
                    <CloseButton
                      type="button"
                      className="btn btn-sm btn-secondary"
                      onClick={props.removeDonationClick}
                      data-itemid={donation.id || ''}
                      data-testid={`dopt-${donation.id || ''}`}
                    >
                      <FontAwesomeIcon icon="times-circle" />
                    </CloseButton>
                    <ItemName>{donation.description} </ItemName>
                  </CloseButtonAndItemName>
                  <PriceSpan data-testid="donation-fee">
                    <CurrencyDisplay amount={Number(donation.value)} />
                    {donation.monetary ? (
                      <Fragment />
                    ) : (
                      <RequiredFlag>*</RequiredFlag>
                    )}
                  </PriceSpan>
                </CartItem>
              </div>
            )
          })}
        {props.event.sponsorshipItems &&
          props.event.sponsorshipItems.map(item => {
            if (!item || item.quantityInCart < 1) return null
            return (
              <Fragment key={item && item.id}>
                {item && item.quantityInCart > 0 ? (
                  <div>
                    <CartItem>
                      <CloseButtonAndItemName>
                        <CloseButton
                          type="button"
                          className="btn btn-sm btn-secondary"
                          onClick={props.removeItemClick}
                          data-itemid={item && item.id}
                          data-testid={`si-${item.id}`}
                        >
                          <FontAwesomeIcon icon="times-circle" />
                        </CloseButton>
                        <ItemName>
                          {item && item.quantityInCart}x {item && item.itemName}{' '}
                        </ItemName>
                      </CloseButtonAndItemName>

                      <PriceSpan>
                        <CurrencyDisplay
                          amount={
                            (item && item.price * item.quantityInCart) || 0
                          }
                        />
                      </PriceSpan>
                    </CartItem>
                  </div>
                ) : (
                  <Fragment />
                )}
              </Fragment>
            )
          })}
      </StyledCardBody>
      <CardFooter>
        <TotalFooter>
          <span>
            Total{' '}
            {nonMonetaryDonations.length > 0 ? (
              <TotalNonMonetaryDisclosure>
                <RequiredFlag>*</RequiredFlag>
                <span>Does not include non-monetary donations</span>
              </TotalNonMonetaryDisclosure>
            ) : (
              <Fragment />
            )}
          </span>
          <span data-testid="cart-total">
            <CurrencyDisplay amount={props.cartTotal || 0} />
          </span>
        </TotalFooter>
      </CardFooter>
    </CartContainer>
  )
}

export default CartPreview
