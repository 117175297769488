//     
import React from 'react'
import styled from 'styled-components/macro'
import Meal from '../../assets/images/Meal.svg'
import Buffet from '../../assets/images/Buffet.svg'
import Catering from '../../assets/images/Catering.svg'
import {Panel, RegistrationButton} from '../../assets/elements'

const Icon = styled.img`
  height: 70px;
  @media (min-width: 1000px) {
    height: 100px;
  }
`

const ItemDescription = styled.div`
  padding: 0 10px;
`

const IconAndDescription = styled.div`
  display: flex;
  max-width: 640px;
  img {
    margin-bottom: 5px;
  }
`

const GuestPanel = styled(Panel)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  @media (min-width: 1000px) {
    flex-direction: row;
    justify-content: space-between;
  }
`

const ButtonText = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  @media (min-width: 1000px) {
    flex-direction: column;
  }
`
const GuestButtonTitle = styled.div`
  font-size: 0.9rem;
  white-space: nowrap;
`

const Price = styled.span`
  font-weight: 700;
  font-size: 1.2rem;
  padding-left: 2px;
  @media (min-width: 1000px) {
    padding-left: 0;
  }
`

              
                                                                
                           
                                        
                            
                     
                      
 

const GuestItem = (props       ) => (
  <GuestPanel data-cy="guestPanel">
    <IconAndDescription>
      {(!props.guestIcon || props.guestIcon === 'Meal') && <Icon src={Meal} />}
      {props.guestIcon === 'Buffet' && <Icon src={Buffet} />}
      {props.guestIcon === 'Catering' && <Icon src={Catering} />}
      <ItemDescription>
        <h5>{props.guestOptionName}</h5>
        <h6>{props.guestRegistrationDescription}</h6>
      </ItemDescription>
    </IconAndDescription>

    <RegistrationButton
      type="button"
      onClick={props.addGuestClick}
      data-testid="add-guest-button"
      data-optionindex={props.optionIndex}
    >
      <ButtonText>
        <span>
          <GuestButtonTitle>Add reception guest </GuestButtonTitle>

          <Price>
            $
            {Number(props.guestFee).toLocaleString(undefined, {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            })}
          </Price>
        </span>
      </ButtonText>
    </RegistrationButton>
  </GuestPanel>
)

export default GuestItem
