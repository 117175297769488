export const calculateCartTotal = (event, players, guests, donations) => {
  if (event) {
    let playerTotal = calculatePlayerCartTotals(players, event)
    let guestTotal = 0
    guests.forEach(guest => {
      if (guest) {
        guestTotal = guestTotal + guest.guestFee
      }
    })
    const sponsorshipItemsTotal = event.sponsorshipItems.reduce(
      (accumulator, currentValue) =>
        currentValue
          ? accumulator + currentValue.price * currentValue.quantityInCart
          : accumulator,
      0,
    )
    const donationTotal = donations.reduce(
      (accumulator, currentValue) =>
        currentValue && currentValue.monetary
          ? accumulator + Number(currentValue.value)
          : accumulator,
      0,
    )
    const groupItemsTotal = event.playerFeeOptions.reduce(
      (acc, curr) =>
        curr && curr.quantityInCart && curr.soldAs && curr.soldAs > 1
          ? acc + Number(curr.playerFee || 0) * Number(curr.quantityInCart)
          : acc,
      0,
    )
    playerTotal = playerTotal + groupItemsTotal
    const newTotal =
      playerTotal + guestTotal + sponsorshipItemsTotal + donationTotal
    const sponsorshipItemCount = event.sponsorshipItems.reduce(
      (accumulator, currentValue) =>
        currentValue ? accumulator + currentValue.quantityInCart : accumulator,
      0,
    )
    const newItemCount =
      players.length +
      guests.length +
      sponsorshipItemCount +
      (donations ? donations.length : 0)
    let fullPricePlayerTotal = 0
    players.forEach(player => {
      if (
        player &&
        player.playerFeeOptionIndex !== undefined &&
        !player.groupID
      ) {
        fullPricePlayerTotal = fullPricePlayerTotal + Number(player.playerFee)
      }
    })
    const playerDiscounts = fullPricePlayerTotal - playerTotal
    return {
      cartTotal: newTotal,
      cartItemCount: newItemCount,
      playerTotal,
      guestTotal,
      sponsorshipItemsTotal,
      donationTotal,
      playerDiscounts,
    }
  }
}

export const calculatePlayerTotal = (
  quantity,
  playerFee,
  twosomeDiscount,
  foursomeDiscount,
) => {
  let total = 0
  let remainingPlayers = quantity
  let foursomes = Math.floor(remainingPlayers / 4)
  remainingPlayers = remainingPlayers - foursomes * 4
  total = foursomes * (4 * (playerFee || 0) - (foursomeDiscount || 0))
  let twosomes = Math.floor(remainingPlayers / 2)
  remainingPlayers = remainingPlayers - twosomes * 2
  total = total + twosomes * (2 * (playerFee || 0) - (twosomeDiscount || 0))
  total = total + remainingPlayers * (playerFee || 0)
  return total
}

export const calculatePlayerCartTotals = (players, event) => {
  let total = 0
  const playerGroups = []
  players.forEach(player => {
    let option = null
    if (player && player.playerFeeOptionID) {
      option = event.playerFeeOptions.find(
        o => o && player && o.id === player.playerFeeOptionID,
      )
    }

    if (!option && player) {
      //option id not found - search by name
      option = event.playerFeeOptions.find(
        o => o && o.playerOptionName === player.playerOptionName,
      )
    }
    if (!option && player) {
      //can't find by id or name - use index
      option = event.playerFeeOptions[player.playerFeeOptionIndex]
    }

    if (player?.playerFeeOptionID && option?.soldAs === 1) {
      const foundGroupIndex = playerGroups.findIndex(
        group =>
          group &&
          player &&
          group.playerFeeOptionID === player.playerFeeOptionID,
      )
      if (foundGroupIndex > -1) {
        if (playerGroups[foundGroupIndex]) {
          playerGroups[foundGroupIndex].quantity =
            playerGroups[foundGroupIndex].quantity + 1
        }
      } else {
        playerGroups.push({
          playerFeeOptionID: player.playerFeeOptionID,
          twosomeDiscount: player.twosomeDiscount,
          foursomeDiscount: player.foursomeDiscount,
          playerFee: player.playerFee,
          quantity: 1,
        })
      }
    }
  })

  playerGroups.forEach(group => {
    if (group && group.quantity) {
      const foursomes = Math.floor(group.quantity / 4)
      const twosomes = Math.floor((group.quantity - foursomes * 4) / 2)
      const groupCost =
        group.quantity * (group.playerFee || 0) -
        group.twosomeDiscount * twosomes -
        group.foursomeDiscount * foursomes
      total = total + groupCost
    }
  })

  return total
}

export const removeDonation = (e, donations) => {
  const clickedId = e.currentTarget.dataset.itemid
  const items = donations
  const clickedDonation = items.find(item => item && item.id === clickedId)
  let newItems = []
  if (clickedId === '' && clickedDonation) {
    //a new donation (not saved yet) was just deleted - find by number
    newItems = items.reduce((res, item) => {
      if (item && item.id !== clickedDonation.id) {
        res.push(item)
      }
      return res
    }, [])
  } else {
    newItems = items.reduce((res, item) => {
      if (item && item.id !== clickedId) {
        res.push(item)
      }
      return res
    }, [])
  }
  return newItems
}
