//
import React from 'react'
import {print} from 'graphql'
import axios from 'axios'
import gql from 'graphql-tag'
import {
  Button,
  Modal,
  ModalBody,
  Container,
  Form,
  FormGroup,
  Label,
  Input,
} from 'reactstrap'
import styled from 'styled-components/macro'
import Swal from 'sweetalert2'
// import moment from "moment";

import {validateEmail} from '../assets/helpers'

const AdminContainer = styled.div`
  padding: 15px;
`

const ButtonRow = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
  button {
    margin-left: 5px;
  }
`

const RequiredFlag = styled.sup`
  font-size: 0.7rem;
  color: #dc3545;
  margin-left: 5px;
`

const NEW_PLANNER_MESSAGE = gql`
  mutation InsertPlannerMessage($input: InputPlannerMessage!) {
    insertPlannerMessage(input: $input) {
      id
      email
      name
      message
      subject
      eventID
      created
    }
  }
`

const createMessage = async message => {
  const variables = {input: message}
  try {
    const response = await axios.post('', {
      query: print(NEW_PLANNER_MESSAGE),
      variables,
    })
    return response
  } catch (error) {
    console.error(error)
    return null
  }
}

const InvalidEmail = styled.span`
  color: red;
  font-style: italic;
  font-size: 10px;
`

const reducer = (state, action) => {
  switch (action.type) {
    case 'formChange':
      var field = action?.data?.field
      var value = action?.data?.value
      return {
        ...state,
        [field]: value,
        validEmail: field === 'email' ? validateEmail(value) : state.validEmail,
      }
    default:
      return state
  }
}

const initialState = {
  showMessageWindow: false,
  subject: '',
  message: '',
  name: '',
  email: '',
  validEmail: false,
}

const PlannerMessageModal = props => {
  const [state, dispatch] = React.useReducer(reducer, initialState)

  const formChange = e => {
    const field = e.currentTarget.name
    const value = e.currentTarget.value
    dispatch({type: 'formChange', data: {field, value}})
    // this.setState({[field]: value})
    // if (field === 'email') {
    //   this.setState({validEmail: validateEmail(value)})
    // }
  }

  const sendMessage = async () => {
    const messageToSubmit = {
      eventID: props.eventID,
      subject: state.subject,
      message: state.message,
      name: state.name,
      email: state.email,
    }
    const response = await createMessage(messageToSubmit)

    if (!response) {
      // Swal.fire({
      //   type: 'error',
      //   title: 'Error',
      //   text: `There was a problem, check your entries and try again`,
      // })
      Swal.fire(
        'Error',
        `There was a problem, check your entries and try again`,
        'error',
      )
    } else {
      // Swal.fire({
      //   type: 'success',
      //   text: `Message Sent!`,
      // })
      Swal.fire('Message Sent', 'The message was sent successfully', 'success')

      props.toggleModal()
    }
  }

  const {showModal, toggleModal} = props
  return (
    <Modal isOpen={showModal} toggle={toggleModal}>
      <Form>
        <ModalBody>
          <AdminContainer>
            <Container>
              <FormGroup>
                <Label for="name">
                  Name<RequiredFlag>*</RequiredFlag>
                </Label>
                <Input
                  type="text"
                  name="name"
                  id="name"
                  onChange={formChange}
                  value={state.name}
                />
              </FormGroup>
              <FormGroup>
                <Label for="email">
                  Email<RequiredFlag>*</RequiredFlag>
                </Label>
                <Input
                  type="text"
                  name="email"
                  id="email"
                  onChange={formChange}
                  value={state.email}
                />
                {!state.validEmail && (
                  <InvalidEmail>EMail is invalid</InvalidEmail>
                )}
              </FormGroup>
              <FormGroup>
                <Label for="subject">
                  Subject<RequiredFlag>*</RequiredFlag>
                </Label>
                <Input
                  type="text"
                  name="subject"
                  id="subject"
                  onChange={formChange}
                  value={state.subject}
                />
              </FormGroup>
              <FormGroup>
                <Label for="message">
                  Message<RequiredFlag>*</RequiredFlag>
                </Label>
                <Input
                  type="textarea"
                  name="message"
                  id="message"
                  onChange={formChange}
                  value={state.message}
                />
              </FormGroup>
              <ButtonRow>
                <Button color="warning" outline onClick={props.toggleModal}>
                  Cancel
                </Button>
                <Button
                  onClick={sendMessage}
                  color="primary"
                  disabled={
                    !state.name ||
                    !state.email ||
                    !state.subject ||
                    !state.message ||
                    !state.validEmail
                  }
                >
                  Send Message
                </Button>
              </ButtonRow>
            </Container>
          </AdminContainer>
        </ModalBody>
      </Form>
    </Modal>
  )
}

export default PlannerMessageModal
