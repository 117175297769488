import {initializeApp} from 'firebase/app'
import {getAuth} from 'firebase/auth'

const config = {
  apiKey:
    process.env.REACT_APP_API_KEY || 'AIzaSyBgs5jZTm7Cb2YhK1G2juRpA1NQdvPfuJc',
  authDomain: process.env.REACT_APP_AUTH_DOMAIN,
  databaseURL: process.env.REACT_APP_DATABASE_URL,
  projectId: process.env.REACT_APP_PROJECT_ID,
  storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID,
}
export const firebaseApp = initializeApp(config)
export const auth = getAuth(firebaseApp)
