import React, {useState} from 'react'
import {NavLink, useLocation} from 'react-router-dom'
import styled from 'styled-components/macro'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import SignOut from '../SignOut.js'
import Logo from '../assets/images/Logo.jpg'
import GreyLogo from '../assets/images/LogoGreySmall.png'
// import {useUserState} from '../Session/context'
import useUserLookup from '../hooks/useUserLookup'

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  width: 99vw;
  button {
    height: 33px;
  }
  @media (min-width: 992px) {
    flex-direction: row;
    width: 90vw;
  }
`

const NavBar = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
`

const StyledNavLink = styled(NavLink)`
  font-size: 30px;
`

const ListContainer = styled.div`
  width: 100%;
  white-space: nowrap;
  ul {
    display: flex;
    justify-content: center;
    align-items: center;

    a,
    li {
      color: rgba(0, 0, 0, 0.5) !important;
      display: flex;
      flex-direction: column;
      align-items: center;
    }
    a {
      :hover {
        color: rgba(0, 0, 0, 0.9) !important;
      }
    }
  }
`

const StyledLogo = styled.img`
  width: 80px;
  @media only screen and (min-width: 1000px) {
    width: 130px;
  }
  margin-right: 30px;
`

const StyledNav = styled.nav`
  display: flex;
  justify-content: center;
  position: sticky;
  top: 0;
  z-index: 99;
  border-bottom: 1px solid #ccc;
  padding: 0;
`

const Nav = () => {
  const [navOpen, setNavOpen] = useState(false)
  const {data: user} = useUserLookup()
  const adminLink = user && user.super ? '/superadmin' : '/admin'
  const location = useLocation()

  return (
    <StyledNav className="navbar navbar-expand-lg navbar-light">
      <Container>
        <NavBar>
          <StyledNavLink className="navbar-brand" to="/admin">
            <StyledLogo
              src={
                location?.pathname !== '/login' && location?.pathname !== '/'
                  ? Logo
                  : GreyLogo
              }
            />
            Online Event Planner
          </StyledNavLink>
          <button
            className="navbar-toggler navbar-toggler-right"
            type="button"
            data-toggle="collapse"
            data-target="#navbarResponsive"
            aria-controls="navbarResponsive"
            aria-expanded="false"
            aria-label="Toggle navigation"
            onClick={() => setNavOpen(!navOpen)}
          >
            <span className="navbar-toggler-icon" />
          </button>
        </NavBar>

        {location?.pathname !== '/login' && location?.pathname !== '/' && (
          <ListContainer
            className={`collapse navbar-collapse ${navOpen ? 'show' : ''}`}
            id="navbarResponsive"
            onClick={() => setNavOpen(!navOpen)}
            onKeyUp={() => setNavOpen(!navOpen)}
            role="button"
            tabIndex={0}
          >
            <ul className="navbar-nav ml-auto">
              {user ? (
                <li className="nav-item">
                  <NavLink className="nav-link" to="/profileAdmin">
                    <FontAwesomeIcon size="lg" icon="user" />
                    <span>{user.displayName}</span>
                  </NavLink>
                </li>
              ) : (
                <></>
              )}
              {user ? (
                <li className="nav-item">
                  <NavLink className="nav-link" to={adminLink}>
                    <FontAwesomeIcon size="lg" icon="list" />
                    <span>Event List</span>
                  </NavLink>
                </li>
              ) : (
                <></>
              )}
              <li className="nav-item">
                {user ? (
                  <>
                    <SignOut />
                  </>
                ) : (
                  <NavLink className="nav-link" to="/login">
                    <FontAwesomeIcon size="lg" icon="sign-in-alt" />
                    <span>Planner Login</span>
                  </NavLink>
                )}
              </li>
            </ul>
          </ListContainer>
        )}
      </Container>
    </StyledNav>
  )
}

export default Nav
