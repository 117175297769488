//
import React, {useState} from 'react'
import LandingPageButtons from './LandingPageButtons.js'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import styled from 'styled-components/macro'

const Container = styled.nav`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  border-bottom: 1px solid hsl(0, 0%, 95%);
  @media (min-width: 1000px) {
    width: 90vw;
    display: none;
  }
`

const NavBarContainer = styled.div`
  display: flex;
  max-height: ${p => (p.navOpen ? '1000px' : '0')};
  overflow: hidden;
  transition: max-height 0.5s ease;
  justify-content: flex-end;
  width: 300px;
`

const HeaderBar = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
`

const NavBarButton = styled.button`
  background-color: #fff;
  border: 1px solid lightgray;
  border-radius: 3px;
  color: #888;
  padding: 6px 18px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  :focus {
    outline: none;
  }
`

const LandingPageNav = props => {
  const [navOpen, setNavOpen] = useState(false)
  const {event, textColor, showSponsorshipButton} = props
  return (
    <Container className="navbar fixed-top navbar-expand-lg navbar-light fixed-top">
      <HeaderBar>
        <a className="navbar-brand" href="index.html">
          <img
            id="logo"
            src="https://res.cloudinary.com/dcvhcqzp7/image/upload/c_scale,w_100/v1517603164/GolfInvite_cob4cc.jpg"
            alt="Golf Invite Logo"
          />
        </a>
        <NavBarButton
          type="button"
          aria-controls="navbarResponsive"
          aria-expanded="false"
          aria-label="Toggle navigation"
          onClick={() => setNavOpen(!navOpen)}
        >
          <FontAwesomeIcon icon="bars" />
          {/* <span className="navbar-toggler-icon" /> */}
        </NavBarButton>
      </HeaderBar>

      <NavBarContainer
        id="navbarResponsive"
        navOpen={navOpen}
        onClick={() => setNavOpen(!navOpen)}
        onKeyUp={() => setNavOpen(!navOpen)}
        role="button"
        tabIndex={0}
      >
        <LandingPageButtons
          event={event}
          textColor={textColor}
          showSponsorshipButton={showSponsorshipButton}
        />
      </NavBarContainer>
    </Container>
  )
}

export default LandingPageNav
