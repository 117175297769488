import React from 'react'

const UserStateContext = React.createContext({user: null})
const UserDispatchContext = React.createContext(null)

const userReducer = (state, action) => {
  let newuser
  // console.log('context', action)
  switch (action.type) {
    case 'addUser': {
      return {...state, user: action.data}
    }
    case 'super': {
      newuser = state.user
      newuser.super = action.data
      return {...state, user: newuser}
    }
    case 'changeName': {
      newuser = state.user
      newuser.displayName = action.data
      return {...state, user: newuser}
    }
    case 'removeUser': {
      return {...state, user: null}
    }
    default: {
      return state
    }
  }
}

function UserProvider({children}) {
  const [state, dispatch] = React.useReducer(userReducer, {user: null})

  return (
    <UserStateContext.Provider value={state}>
      <UserDispatchContext.Provider value={dispatch}>
        {children}
      </UserDispatchContext.Provider>
    </UserStateContext.Provider>
  )
}

function useUserState() {
  const context = React.useContext(UserStateContext)
  if (context === undefined) {
    throw new Error('useUserState must be used within a UserProvider')
  }
  return context
}
function useUserDispatch() {
  const context = React.useContext(UserDispatchContext)
  if (context === undefined) {
    throw new Error('useUserDispatch must be used within a UserProvider')
  }
  return context
}
export {UserProvider, useUserState, useUserDispatch}
