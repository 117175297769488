//      
import React, {useState, useEffect, Fragment} from 'react'
import styled from 'styled-components/macro'

import SponsorshipItemCard from './SponsorshipItemCard'
import PlayerItemList from '../PlayerRegistration/PlayerItemList'
             
                  
             
            
                 
                          

              
                   
                            
                  
                                        
                         
            
                                   
                                                                  
               
                                  
               
 

const ItemsGrid = styled.div`
  display: grid;
  grid-template-columns: 100%;
`

const SponsorshipItemsList = (props       ) => {
  const [items, setItems] = useState([])
  useEffect(() => {
    const theseItems = []
    props.event.sponsorshipItems.forEach(item => {
      if (item && item.kind === props.kind) theseItems.push(item)
    })
    setItems(theseItems)
  }, [props.event.sponsorshipItems, props.kind])
  return (
    <Fragment>
      {items.length > 0 ? (
        <div className="card" data-cy="sponsorshipItemSection">
          <div className="card-body">
            <h4
              className="card-title"
              attached="top"
              data-cy="sponsorshipSectionTitle"
            >
              {props.kind === 'sponsorship'
                ? 'Sponsorship Items'
                : 'Other Event Items'}
            </h4>
            <ItemsGrid>
              {props.event.sponsorshipItems.map((item, index) => {
                let playersForThisOption = []
                if (props.players) {
                  playersForThisOption = props.players.filter(
                    player => player && player.sponsorshipItemID === item.id,
                  )
                  if (
                    playersForThisOption.length < item.playersIncluded &&
                    item.quantityInCart > 0
                  ) {
                    console.error(
                      'Player not found for sponsorship item',
                      item,
                      props.players,
                    )
                  }
                }
                return (
                  <Fragment key={item.kind + item.id}>
                    {item && item.kind === props.kind && (
                      <span data-cy="sponsorship-item-panel">
                        <SponsorshipItemCard
                          item={item}
                          addItem={props.addItem}
                          kind={props.kind}
                          optionIndex={index}
                          removeItemClick={props.removeItemClick}
                          event={props.event}
                          players={props.players}
                        />
                        {item.quantityInCart > 0 &&
                        item.playersIncluded > 0 &&
                        props.playerChange &&
                        props.questions ? (
                          <PlayerItemList
                            event={props.event}
                            players={props.players}
                            playerChange={props.playerChange}
                            // availablePlayers={props.availablePlayers}
                            questions={props.questions}
                            errors={props.errors}
                            playersForThisOption={playersForThisOption}
                            option={null}
                            roundID={item.roundID}
                          />
                        ) : (
                          ''
                        )}
                      </span>
                    )}
                  </Fragment>
                )
              })}
            </ItemsGrid>
          </div>
        </div>
      ) : (
        <Fragment />
      )}
    </Fragment>
  )
  // }
}

SponsorshipItemsList.defaultProps = {
  sponsorshipItems: [],
}

export default SponsorshipItemsList
