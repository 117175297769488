import React from 'react'
import styled from 'styled-components/macro'
import Nav from './Nav'
import Footer from './Footer'

const FourOhFourContainer = styled.div`
  display: flex;
  flex-direction: column;
`

const FourOhFour = () => (
  <FourOhFourContainer>
    <Nav />
    <div className="container bg-light">
      <div className="jumbotron">
        <h1 className="display-4 text-center">Page not found</h1>
        <p className="lead">
          The page you requested was not found, please check your request or
          contact support at support@golfinvite.com if you continue to have
          problems.
        </p>
      </div>
    </div>
    <Footer />
  </FourOhFourContainer>
)

export default FourOhFour
