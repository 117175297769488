import axios from 'axios'
import {print} from 'graphql'
import gql from 'graphql-tag'
import {useQuery} from 'react-query'
import useUserLookup from 'hooks/useUserLookup'

const GET_EVENT_BY_SLUG = gql`
  query getEventBySlug($slug: String!) {
    getEventBySlug(slug: $slug) {
      id
      adminMode
      name
      slug
      status
      eventDate
      golfStartTime
      paypalPayee
      paymentPaypalEnabled
      paymentCheckEnabled
      eventInstructions
      checkAddress
      checkAddressInstructions
      courseName
      courseAddress
      paymentOtherEnabled
      paymentOtherTitle
      paymentOtherInstructions
      homeHeaderHTML
      showHeaderOnLandingPage
      sponsorshipItems {
        id
        itemName
        description
        price
        quantityTotal
        quantityAvailable
        quantityInCart
        playersIncluded
        kind
        roundID
        display
        icon
        order
      }
      customQuestions {
        id
        question
        questionType
        options
        required
        appliesTo
        roundID
      }
      rounds {
        name
        description
        roundID
        available
        quantityInCart
      }
      playerCapacity
      playersAvailable
      playerFeeOptions {
        id
        playerFee
        foursomeDiscount
        twosomeDiscount
        playerOptionName
        playerRegistrationDescription
        roundID
        purchased
        available
        soldAs
        icon
      }
      guestFeeOptions {
        guestFee
        guestOptionName
        guestRegistrationDescription
        icon
      }
      format
      startType
      sponsorImages {
        name
        website
        image
        order
        display
      }
      playerEmailDataCollection
      playerAddressDataCollection
      playerPhoneDataCollection
      playerOrganizationDataCollection
      registrantAddressDataCollection
      registrantPhoneDataCollection
      registrantOrganizationDataCollection
      guestEmailDataCollection
      guestAddressDataCollection
      guestPhoneDataCollection
      guestOrganizationDataCollection
      allowDonors
      allowVolunteers
      showPairings
      showContactPlanner
      buttonColor
      multipleRounds
      registrationButtonText
      stripeLinkedID
      paymentStripeEnabled
    }
  }
`

function useEventLookup(slug) {
  const variables = {slug: slug.toLowerCase()}
  const loggedIn = localStorage.getItem('loggedIn')
  const {data: user} = useUserLookup()

  const enabled = loggedIn ? user && slug : slug
  return useQuery(
    ['eventRegistrationPage', slug],
    async () => {
      const response = await axios.post('', {
        query: print(GET_EVENT_BY_SLUG),
        variables,
      })
      return response?.data?.data?.getEventBySlug
    },
    {
      enabled: Boolean(enabled),
      refetchOnReconnect: false,
      refetchOnWindowFocus: false,
      onError: err => {
        console.error(err)
      },
    },
  )
}

export default useEventLookup
