//
import React, {Fragment} from 'react'
// import gql from 'graphql-tag'
import {useParams, useLocation} from 'react-router-dom'
// import * as Sentry from '@sentry/react'
import {cloneDeep, pick} from 'lodash'
import toastr from 'toastr' // to use for cart updated
import Swal from 'sweetalert2'

import {calculateCartTotal, removeDonation} from './EventPageContainerHelpers'
import showToast from '../assets/showToastr'
import useEventLookup from './hooks/useEventLookup'
import useNewRegistration from './hooks/useNewRegistration'
import useEditRegistration from './hooks/useEditRegistration'
import useRegistrationLookup from './hooks/useRegistrationLookup'
import useUserLookup from '../hooks/useUserLookup'
import {
  loadEventFiller,
  editEventForRegistration,
  addPlayer,
  removePlayer,
} from './eventPageHelpers'

import history from '../history'
import CartModal from './CartModal'
import EventPage from './EventPage'
import LoadingSpinner from '../assets/Spinner'
import ClosedEvent from './ClosedEvent'
import RegistrationReceipt from './RegistrationReceipt'
import Error from '../assets/Error'
import {validateEmail} from '../assets/helpers'
//import {useDebouncedEffect} from '../hooks/useDebouncedEffect'

const initialState = {
  event: null,
  loading: true,
  eventFound: null,
  cartTotal: 0,
  playerDiscounts: 0,
  cartItemCount: 0,
  players: [],
  guests: [],
  donations: [],
  donorQuestions: [],
  playerQuestions: [],
  guestQuestions: [],
  sponsorshipQuestions: [],
  volunteerQuestions: [],
  showSponsorshipQuestions: false,
  registrantQuestions: [],
  questionResponses: [],
  nextPlayerNumber: 1,
  nextGuestNumber: 1,
  nextItemNumber: 1,
  playerTotal: 0,
  guestTotal: 0,
  sponsorshipItemsTotal: 0,
  showingCart: false,
  // hidePlayers: false,
  submitObject: {},
  registrant: {
    firstName: '',
    lastName: '',
    organization: '',
    email: '',
    address: '',
    city: '',
    stateAddress: '',
    zipCode: '',
    phone: '',
    comments: '',
    questionResponses: [],
  },
  player1Changed: false,
  paypalConfirmationID: '',
  confirmationID: '',
  errors: {
    firstName: undefined,
    lastName: undefined,
    email: undefined,
    address: undefined,
    city: undefined,
    stateAddress: undefined,
    zipCode: undefined,
    phone: undefined,
    organization: undefined,
  },
  playerError: false,
  guestError: false,
  registrationError: false,
  // sponsorshipQuestionError: false,
  formError: true,
  optionsObject: {
    headers: {
      authorization: '',
    },
  },
  registrationComplete: false,
  paymentMethod: '',
}

const reducer = (state, action) => {
  switch (action.type) {
    case 'setState':
      return {...state, ...action.data}
    case 'setErrors':
      return {...state, ...action.data}
    case 'updateCartTotal':
      return {...state, ...action.data}
    default:
      return state
  }
}

const EventPageContainer = () => {
  const location = useLocation()
  const pathname = location.pathname
  const volunteersOnly = pathname.includes('/volunteer')
  let {event: eventParam, id} = useParams()
  const {data: user, status: userStatus} = useUserLookup()
  const {
    data: eventAPI,
    status: eventStatus,
    error: eventError,
  } = useEventLookup(eventParam)
  const {
    data: registrationLookup,
    status: registrationLookupStatus,
    error: registrationLookupError,
  } = useRegistrationLookup(id, user)
  const {mutateAsync: postNewRegistration} = useNewRegistration()
  const {mutateAsync: editRegistration} = useEditRegistration()
  const [state, dispatch] = React.useReducer(reducer, initialState)

  // let nextGuestNumber = 1
  let player1Changed = false

  let stringErrors = JSON.stringify(state.errors)

  React.useEffect(() => {
    const checkForFormErrors = () => {
      let foundError = false
      let guestError = false
      let registrationError = false
      // let sponsorshipQuestionError = false
      let donorError = false
      let playerError = false

      for (let k in state.errors) {
        if (state.errors[k]) {
          if (k.includes('Player')) {
            playerError = true
          } else if (k.includes('Guest')) {
            guestError = true
          } else if (k.includes('Donor')) {
            donorError = true
          } else {
            registrationError = true
          }
          foundError = true
        }
      }

      if (
        state.errors.firstName !== false ||
        state.errors.lastName !== false ||
        state.errors.email !== false
      ) {
        foundError = true
        registrationError = true
      }

      dispatch({
        type: 'setErrors',
        data: {
          formError: foundError,
          registrationError,
          playerError,
          guestError,
          donorError,
        },
      })
    }
    checkForFormErrors()
  }, [state.errors, stringErrors])

  React.useEffect(() => {
    const newState = calculateCartTotal(
      state.event,
      state.players,
      state.guests,
      state.donations,
    )
    dispatch({type: 'updateCartTotal', data: newState})
  }, [state.event, state.donations, state.guests, state.players])

  React.useEffect(() => {
    const showCartToast = newCartTotal => {
      toastr.options = {
        closeButton: true,
        positionClass: 'toast-bottom-right',
        showDuration: '300',
        hideDuration: '1000',
        timeOut: '5000',
        extendedTimeOut: '1000',
        showEasing: 'swing',
        hideEasing: 'linear',
        showMethod: 'fadeIn',
        hideMethod: 'fadeOut',
      }

      setTimeout(
        () =>
          toastr.success(
            `Cart updated $${newCartTotal.toLocaleString(navigator.language, {
              minimumFractionDigits: 2,
            })}`,
          ),
        150,
      )
    }

    if (state.cartTotal > 0) {
      showCartToast(state.cartTotal)
    }
  }, [state.cartTotal])

  React.useEffect(() => {
    //event or registration was loaded
    //if there is an id, check that both event and registration are loaded
    if ((id && eventAPI && registrationLookup) || (!id && eventAPI)) {
      const {
        event,
        guestQuestions,
        playerQuestions,
        registrantQuestions,
        sponsorshipQuestions,
        donorQuestions,
        volunteerQuestions,
        errors,
        formError,
      } = loadEventFiller(eventAPI, volunteersOnly)
      dispatch({
        type: 'setState',
        data: {
          event,
          guestQuestions,
          playerQuestions,
          registrantQuestions,
          sponsorshipQuestions,
          donorQuestions,
          volunteerQuestions,
          errors,
          formError,
        },
      })
      if (id) {
        //* We have an ID - we are editing a registration *//
        const {
          registration,
          event: eventAfterRegistration,
          errors: newErrors,
          player1Changed,
          nextGuestNumber,
          showSponsorshipQuestions,
          showDonorQuestions,
        } = editEventForRegistration(registrationLookup, event, errors)
        dispatch({
          type: 'setState',
          data: {
            event: eventAfterRegistration,
            errors: newErrors,
            player1Changed,
            nextGuestNumber,
            showSponsorshipQuestions,
            showDonorQuestions,
            guestQuestions,
            playerQuestions,
            registrant: registration.registrant,
            players: registration.players,
            guests: registration.guests,
            donations: registration.donations,
            questionResponses: registration
              ? registration.questionResponses
              : [],
          },
        })
      }
    }
  }, [eventAPI, id, registrationLookup, volunteersOnly])

  const addPlayerClick = e => {
    const optionIndex = Number(e.currentTarget.dataset.optionindex)
    if (state.event) {
      const option = state.event.playerFeeOptions[optionIndex]
      const count = option ? option.soldAs : 1
      const returnObject = addPlayer({
        event: state.event,
        registrant: state.registrant,
        players: state.players,
        playerQuestions: state.playerQuestions,
        count,
        optionIndex,
        type: 'player',
        errors: state.errors,
        nextNumber: state.nextPlayerNumber,
        editingRegistration: id,
      })
      // console.log(returnObject)
      dispatch({type: 'setState', data: returnObject})
    }
  }

  const removePlayerClick = e => {
    const clickedId = e.currentTarget.dataset.playerid
    if (id) {
      Swal.fire({
        title: 'Delete Player?',
        text:
          'All information about this player will be lost. You will need to save the registration after making changes.',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Delete',
      }).then(result => {
        if (result.isConfirmed) {
          removePlayerConfirmed(clickedId)
        }
      })
    } else {
      removePlayerConfirmed(clickedId)
    }
  }

  const removePlayerConfirmed = clickedId => {
    const event = cloneDeep(state.event)
    const player = state.players.find(p => p && p.id === clickedId)
    if (player && event) {
      //lower quantity in cart so cart preview is correct
      const optionIndex = event.playerFeeOptions.findIndex(
        o => o && o.id === player.playerFeeOptionID,
      )
      if (optionIndex > -1 && event.playerFeeOptions[optionIndex]) {
        const currentQuantity =
          event.playerFeeOptions[optionIndex].quantityInCart || 0
        if (currentQuantity > 0) {
          event.playerFeeOptions[optionIndex].quantityInCart =
            currentQuantity - 1
        }
      }

      const returnedObject = removePlayer(
        event,
        state.errors,
        state.players,
        [clickedId],
        player1Changed,
      )
      dispatch({type: 'setState', data: returnedObject})
    }
  }

  const removeGroupClick = e => {
    const clickedId = e.currentTarget.dataset.optionid
    if (id) {
      Swal.fire({
        title: 'Delete Players?',
        text:
          'All information about these players will be lost. You will need to save the registration after making changes.',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Delete',
      }).then(result => {
        if (result.isConfirmed) {
          removeGroup(clickedId)
        }
      })
    } else {
      removeGroup(clickedId)
    }
  }

  const removeGroup = clickedId => {
    const event = cloneDeep(state.event)
    let groupID = ''
    const removedIDs = []
    if (event) {
      event.playerFeeOptions.map(option => {
        if (option && option.id === clickedId) {
          option.quantityInCart =
            option && option.quantityInCart ? option.quantityInCart - 1 : 0

          if (option.groupIDs && option.groupIDs.length > 0) {
            groupID = option.groupIDs[0]
            option.groupIDs.shift() // remove the first element
            //find players with this groupID and remove
            state.players.forEach(player => {
              if (player && player.groupID && player.groupID === groupID) {
                removedIDs.push(player.id)
              }
            })
          } else {
            //not a group find player id and remove

            state.players.forEach(player => {
              if (
                player &&
                player.playerFeeOptionID &&
                player.playerFeeOptionID === clickedId
              ) {
                removedIDs.push(player.id)
              }
            })
          }
        }
        return option
      })

      if (removedIDs && removedIDs.length > 0) {
        const returnedObject = removePlayer(
          event,
          state.errors,
          state.players,
          removedIDs,
          player1Changed,
        )
        dispatch({type: 'setState', data: returnedObject})
      }
    }
  }

  const playerChange = (e, playerID) => {
    updatePlayer(
      e.currentTarget.name,
      e.currentTarget.value,
      e.currentTarget.type,
      e.currentTarget.checked,
      playerID,
    )
    if (state.players && state.players[0]) {
      if (state.players[0].id === playerID) {
        //updating the first player - don't update from registrant anymore
        player1Changed = true
      }
    }
  }

  const updatePlayer = (
    updatedField,
    newValue,
    type,
    checked,
    playerID,
    registrant,
    errors,
  ) => {
    //these might come in if from updating registrant
    if (!errors) errors = cloneDeep(state.errors)
    // if (!registrant) registrant = cloneDeep(state.registrant)

    // first clone the players so we don't mutate state but keep order
    const players = cloneDeep(state.players)
    // now find the index of the chnaged player
    const changedPlayerIndex = state.players.findIndex(
      player => player && player.id === playerID,
    )
    // grab that guy and update it
    const changedPlayer = players[changedPlayerIndex]
    const event = state.event

    if (changedPlayer) {
      if (
        event &&
        (updatedField === 'firstName' ||
          updatedField === 'lastName' ||
          updatedField === 'email' ||
          updatedField === 'phone' ||
          updatedField === 'organization' ||
          updatedField === 'address') &&
        updatedField &&
        changedPlayer
      ) {
        changedPlayer[updatedField] = newValue
        if (updatedField === 'email') {
          const errorID = `emailPlayer${changedPlayer.id}`
          if (event.playerEmailDataCollection === 'required') {
            errors[errorID] = newValue === '' || !validateEmail(newValue)
          } else {
            if (newValue !== '') {
              errors[errorID] = !validateEmail(newValue)
            } else {
              errors[errorID] = false
            }
          }
        }
        if (
          updatedField === 'phone' &&
          event.playerPhoneDataCollection === 'required'
        ) {
          const errorID = `phonePlayer${changedPlayer.id}`
          errors[errorID] = newValue === ''
        }
        if (
          updatedField === 'organization' &&
          event.playerOrganizationDataCollection === 'required'
        ) {
          const errorID = `organizationPlayer${changedPlayer.id}`
          errors[errorID] = newValue === ''
        }
        if (
          updatedField === 'address' &&
          event.playerAddressDataCollection === 'required'
        ) {
          const errorID = `addressPlayer${changedPlayer.id}`
          errors[errorID] = newValue === ''
        }
      } else {
        //a question was updated
        const responses = changedPlayer.questionResponses || []
        // const target = e.currentTarget
        const questionID = updatedField.substring(0, 24)
        //remove base

        const responseFoundIndex = responses.findIndex(
          x => x && x.questionID === questionID,
        )
        //get the question to see if it is required
        const allQuestions = state.playerQuestions
        let response = {questionID, response: newValue, question: ''}
        const question = allQuestions.find(x => x && x.id === questionID)
        if (responseFoundIndex > -1) {
          let currentResponse = responses[responseFoundIndex]
            ? responses[responseFoundIndex].response
            : ''
          if (type === 'checkbox' && currentResponse) {
            // if its a checkbox, need to change response
            let responseArray = currentResponse.split(',')
            if (checked) {
              //checkbox was selected - need to add value
              responseArray.push(newValue)
              response.response = responseArray.join(',')
            } else {
              //checkbox was deselected - need to remove value
              response.response = responseArray
                .filter(x => x !== newValue)
                .join(',')
            }
          }
          responses[responseFoundIndex] = response
        } else {
          responses.push(response)
        }
        response.question = question ? question.question : ''
        if (question && question.required) {
          const playerQuestionID = `${question.id}Player${changedPlayer.id}`
          if (response.response === '') {
            errors[playerQuestionID] = true
          } else {
            errors[playerQuestionID] = false
          }
        }
        changedPlayer.questionResponses = responses
      }
    }

    // update in cloned array
    players[changedPlayerIndex] = changedPlayer

    if (registrant) {
      dispatch({type: 'setState', data: {players, errors, registrant}})
    } else {
      dispatch({type: 'setState', data: {players, errors}})
    }
  }

  const addGuestClick = e => {
    //find guest fee option
    if (
      e.currentTarget.dataset.optionindex !== undefined &&
      e.currentTarget.dataset.optionindex !== null
    ) {
      const optionIndex = Number(e.currentTarget.dataset.optionindex)
      if (state.event) {
        const option = state.event.guestFeeOptions[optionIndex]
        //look for custom questions that apply to guests and add to this new guests
        if (option) {
          const guestQuestions = []
          let errors = cloneDeep(state.errors)
          if (state.event) {
            const event = state.event
            //create empty questions
            state.guestQuestions.forEach(question => {
              if (question) {
                const guestQuestion = {
                  questionID: question.id,
                  question: question.question,
                  response: '',
                }
                guestQuestions.push(guestQuestion)
                if (question.required) {
                  errors[
                    `${question.id}Guest${String(state.nextGuestNumber)}`
                  ] = true
                }
              }
            })

            //create errors for required data collection fields
            // guestEmailDataCollection: string,
            // guestAddressDataCollection: string,
            // guestPhoneDataCollection: string,
            // guestOrganizationDataCollection: string
            if (event && event.guestEmailDataCollection === 'required') {
              errors[`emailGuest${String(state.nextGuestNumber)}`] = true
            }
            if (event && event.guestAddressDataCollection === 'required') {
              errors[`addressGuest${String(state.nextGuestNumber)}`] = true
            }
            if (event && event.guestPhoneDataCollection === 'required') {
              errors[`phoneGuest${String(state.nextGuestNumber)}`] = true
            }
            if (event && event.guestOrganizationDataCollection === 'required') {
              errors[`organizationGuest${String(state.nextGuestNumber)}`] = true
            }

            dispatch({
              type: 'setState',
              data: {
                nextGuestNumber: state.nextGuestNumber + 1,
                guests: [
                  ...state.guests,
                  {
                    firstName: '',
                    lastName: '',
                    guestNumber: state.nextGuestNumber,
                    questionResponses: guestQuestions,
                    email: '',
                    phone: '',
                    address: '',
                    organization: '',
                    guestFee: Number(option.guestFee) || 0,
                    guestFeeOptionIndex: optionIndex || 0,
                    guestOptionName: option.guestOptionName || '',
                  },
                ],
                errors,
              },
            })
          }
        }
      }
    }
  }

  const removeGuestClick = e => {
    const clickedId = Number(e.currentTarget.dataset.guestid)
    let errors = cloneDeep(state.errors)
    //check errors to see if there are any for this guest and remove them if found
    for (var property1 in errors) {
      if (property1.endsWith(`Guest${clickedId}`)) {
        delete errors[property1]
      }
    }

    dispatch({
      type: 'setState',
      data: {
        guests: state.guests.filter(
          guest => guest && guest.guestNumber !== clickedId,
        ),
        errors,
      },
    })
  }

  const guestChange = (e, guestNumber) => {
    // first clone the guests so we don't mutate state but keep order
    const guests = cloneDeep(state.guests)
    // now find the index of the changed guest
    const changedGuestIndex = state.guests.findIndex(
      guest => guest && guest.guestNumber === guestNumber,
    )
    // grab that guy and update it
    const changedGuest = guests[changedGuestIndex]

    const errors = cloneDeep(state.errors)
    //the field that was just updated
    const updatedField = e.currentTarget.name

    const event = state.event

    if (changedGuest) {
      if (
        event &&
        (updatedField === 'firstName' ||
          updatedField === 'lastName' ||
          updatedField === 'email' ||
          updatedField === 'phone' ||
          updatedField === 'organization' ||
          updatedField === 'address')
      ) {
        changedGuest[e.currentTarget.name] = e.currentTarget.value
        // if (
        //   updatedField === 'email' &&
        //   event.guestEmailDataCollection === 'required'
        // ) {
        //   const errorID = `emailGuest${changedGuest.guestNumber}`
        //   errors[errorID] =
        //     e.currentTarget.value === '' && validateEmail(e.currentTarget.value)
        // }
        const newValue = e.currentTarget.value
        if (updatedField === 'email') {
          const errorID = `emailGuest${changedGuest.guestNumber}`
          if (event.guestEmailDataCollection === 'required') {
            errors[errorID] = newValue === '' || !validateEmail(newValue)
          } else {
            if (newValue !== '') {
              errors[errorID] = !validateEmail(newValue)
            } else {
              errors[errorID] = false
            }
          }
        }
        if (
          updatedField === 'phone' &&
          event.guestPhoneDataCollection === 'required'
        ) {
          const errorID = `phoneGuest${changedGuest.guestNumber}`
          errors[errorID] = e.currentTarget.value === ''
        }
        if (
          updatedField === 'organization' &&
          event.guestOrganizationDataCollection === 'required'
        ) {
          const errorID = `organizationGuest${changedGuest.guestNumber}`
          errors[errorID] = e.currentTarget.value === ''
        }
        if (
          updatedField === 'address' &&
          event.guestAddressDataCollection === 'required'
        ) {
          const errorID = `addressGuest${changedGuest.guestNumber}`
          errors[errorID] = e.currentTarget.value === ''
        }
      } else {
        //a question was updated
        const responses = changedGuest.questionResponses || []
        const target = e.currentTarget
        const questionID = target.name.substring(0, 24)
        //remove base

        const responseFoundIndex = responses.findIndex(
          x => x && x.questionID === questionID,
        )
        //get the question to see if it is required
        const allQuestions = state.guestQuestions
        let response = {questionID, response: target.value, question: ''}
        const question = allQuestions.find(x => x && x.id === questionID)
        if (responseFoundIndex > -1) {
          let currentResponse = responses[responseFoundIndex]
            ? responses[responseFoundIndex].response
            : ''
          if (target.type === 'checkbox' && currentResponse) {
            // if its a checkbox, need to change response
            let responseArray = currentResponse.split(',')
            if (target.checked) {
              //checkbox was selected - need to add target.value
              responseArray.push(target.value)
              response.response = responseArray.join(',')
            } else {
              //checkbox was deselected - need to remove target.value
              response.response = responseArray
                .filter(x => x !== target.value)
                .join(',')
            }
          }
          responses[responseFoundIndex] = response
        } else {
          responses.push(response)
        }
        response.question = question ? question.question : ''
        if (question && question.required) {
          const guestQuestionID = `${question.id}Guest${changedGuest.guestNumber}`
          if (response.response === '') {
            errors[guestQuestionID] = true
          } else {
            errors[guestQuestionID] = false
          }
        }
        changedGuest.questionResponses = responses
      }
    }

    // update in cloned array
    guests[changedGuestIndex] = changedGuest

    // finally update state with this cloned array
    dispatch({type: 'setState', data: {guests, errors}})
  }

  const addItem = item => {
    //look to see if item already exists and if so add to quantity
    let event = cloneDeep(state.event)
    let errors = cloneDeep(state.errors)
    let players = cloneDeep(state.players)
    let showSponsorshipQuestions = state.showSponsorshipQuestions
    let nextPlayerNumber = state.nextPlayerNumber
    if (event) {
      let sponsorshipItems = event.sponsorshipItems
      let sponsorshipIndex = sponsorshipItems.findIndex(
        x => x && x.id === item.id,
      )
      if (sponsorshipItems.length > 0 && sponsorshipIndex > -1) {
        const foundItem = sponsorshipItems[sponsorshipIndex]

        //add players if item comes with free ones
        if (foundItem && foundItem.playersIncluded) {
          const returnedFromAddPlayer = addPlayer({
            event,
            registrant: state.registrant,
            players: state.players,
            playerQuestions: state.playerQuestions,
            count: foundItem.playersIncluded,
            optionIndex: sponsorshipIndex,
            type: 'sponsorship',
            errors: state.errors,
            nextNumber: state.nextPlayerNumber,
            editingRegistration: id,
          })
          event = returnedFromAddPlayer.event
          players = returnedFromAddPlayer.players
          errors = returnedFromAddPlayer.errors
          nextPlayerNumber = returnedFromAddPlayer.nextPlayerNumber
        } else {
          //addplayer adds quantity so need to add here if no players
          foundItem && foundItem.quantityInCart++
        }
        if (foundItem && foundItem.kind === 'sponsorship') {
          //if sponsor item check for and add questions here
          const requiredQuestions = state.sponsorshipQuestions.filter(
            q => q && q.required,
          )
          requiredQuestions.forEach(q => {
            if (q && errors[`${q.id}Sponsor`] === undefined) {
              errors[`${q.id}Sponsor`] = true
            }
          })

          //now look for 'all' questions
          const requiredAllQuestions = state.registrantQuestions.filter(
            q => q && q.required,
          )
          requiredAllQuestions.forEach(q => {
            if (q && errors[`${q.id}Registrant`] === undefined) {
              errors[`${q.id}Registrant`] = true
            }
          })

          showSponsorshipQuestions = true
        }
      }
      //event.sponsorshipItems = sponsorshipItems
      dispatch({
        type: 'setState',
        data: {
          showSponsorshipQuestions,
          errors,
          event,
          players,
          nextPlayerNumber,
        },
      })
    }
  }
  const removeItemClick = e => {
    const clickedId = e.currentTarget.dataset.itemid
    const removedItemIndex = state.event.sponsorshipItems.findIndex(
      item => item && item.id === clickedId,
    )
    const removedItem = state.event.sponsorshipItems[removedItemIndex]

    let playersForThisOption = state.players.filter(
      p => p.sponsorshipItemID === removedItem.id,
    )
    if (id && playersForThisOption.length) {
      Swal.fire({
        title: 'Delete Players?',
        text:
          'All information about these players will be lost. You will need to save the registration after making changes.',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Delete',
      }).then(result => {
        if (result.isConfirmed) {
          removeItemConfirmed(removedItemIndex, playersForThisOption)
        }
      })
    } else {
      removeItemConfirmed(removedItemIndex, playersForThisOption)
    }
  }

  const removeItemConfirmed = (removedItemIndex, playersForThisOption) => {
    const event = cloneDeep(state.event)
    const players = cloneDeep(state.players)
    let showSponsorshipQuestions = state.showSponsorshipQuestions
    if (event) {
      if (
        removedItemIndex > -1 &&
        event.sponsorshipItems[removedItemIndex] &&
        event.sponsorshipItems[removedItemIndex].quantityInCart > 0
      ) {
        event.sponsorshipItems[removedItemIndex].quantityInCart--
      }

      //if no sponsor items left, remove questions
      let sponsorItemsRemaining = false
      event.sponsorshipItems.forEach(item => {
        if (item.kind === 'Sponsorship' || sponsorItemsRemaining === true) {
          sponsorItemsRemaining = true
        }
      })
      const errors = cloneDeep(state.errors)

      if (!sponsorItemsRemaining) {
        showSponsorshipQuestions = false
        state.sponsorshipQuestions.forEach(q => {
          if (q && errors[`${q.id}Sponsor`]) delete errors[`${q.id}Sponsor`]
        })
        //remove registrant errors if no sponsorship and no donations
        if (!state.donations || state.donations.length === 0) {
          state.registrantQuestions.forEach(q => {
            if (q && errors[`${q.id}Registrant`])
              delete errors[`${q.id}Registrant`]
          })
        }
      }

      //remove players for this
      let remainingPlayers = []
      let lastGroupID = ''

      if (playersForThisOption && playersForThisOption.length > 0) {
        lastGroupID =
          playersForThisOption[playersForThisOption.length - 1].groupID
        playersForThisOption.forEach(player => {
          if (player && player.groupID === lastGroupID) {
            //remove player errors
            let playerString = `Player${player.id}`
            for (var property1 in errors) {
              if (property1.endsWith(playerString)) {
                delete errors[property1]
              }
            }
          }
        })
      }

      players.forEach(player => {
        if (player && player.groupID !== lastGroupID) {
          remainingPlayers.push(player)
        }
      })

      dispatch({
        type: 'setState',
        data: {
          event,
          errors,
          players: remainingPlayers,
          showSponsorshipQuestions,
        },
      })
    }
  }

  const registrantQuestionChanged = e => {
    //a question was updated
    const responses = state.questionResponses
    const target = e.currentTarget
    let questionType = 'Registrant'
    //remove base
    const questionID = target.name.substring(0, 24)

    if (target.name.includes('donor')) {
      questionType = 'Donor'
    } else if (target.name.includes('sponsor')) {
      questionType = 'Sponsor'
    } else if (target.name.includes('volunteer')) {
      questionType = 'Volunteer'
    }

    const responseFoundIndex = responses.findIndex(
      x => x && x.questionID === questionID,
    )
    //get the question to see if it is required
    const allQuestions = [
      ...state.sponsorshipQuestions,
      ...state.donorQuestions,
      ...state.registrantQuestions,
      ...state.volunteerQuestions,
    ]
    let response = {
      questionID: questionID,
      response: target.value,
      question: '',
    }
    const question = allQuestions.find(x => x && x.id === questionID)
    response.question = question ? question.question : ''
    const errors = state.errors
    if (responseFoundIndex > -1) {
      let currentResponse = responses[responseFoundIndex]
        ? responses[responseFoundIndex].response
        : ''
      if (target.type === 'checkbox' && currentResponse) {
        // if its a checkbox, need to change response
        let responseArray = currentResponse.split(',')
        if (target.checked) {
          //checkbox was selected - need to add target.value
          responseArray.push(target.value)
          response.response = responseArray.join(',')
        } else {
          //checkbox was deselected - need to remove target.value
          response.response = responseArray
            .filter(x => x !== target.value)
            .join(',')
        }
      }
      responses[responseFoundIndex] = response
    } else {
      responses.push(response)
    }

    if (question && question.required) {
      if (response.response === '') {
        errors[`${questionID}${questionType}`] = true
      } else {
        errors[`${questionID}${questionType}`] = false
      }
    }
    dispatch({type: 'setState', data: {questionResponses: responses, errors}})
  }

  const updateDonations = donations => {
    // console.log(donations)
    let errors = cloneDeep(state.errors)

    const requiredQuestions = state.donorQuestions.filter(q => q && q.required)
    requiredQuestions.forEach(q => {
      if (donations.length === 0) {
        //donations removed also remove errors
        delete errors[`${q.id}Donor`]
      } else if (q && errors[`${q.id}Donor`] === undefined) {
        //donations just added - look to see if error object exist and add them if not
        errors[`${q.id}Donor`] = true
      }
    })

    //now look for 'all' questions
    const requiredAllQuestions = state.registrantQuestions.filter(
      q => q && q.required,
    )
    requiredAllQuestions.forEach(q => {
      if (donations.length === 0 && !state.showSponsorshipQuestions) {
        //donations removed also remove errors
        delete errors[`${q.id}Registrant`]
      } else if (q && errors[`${q.id}Registrant`] === undefined) {
        //donations just added - look to see if error object exist and add them if not
        errors[`${q.id}Registrant`] = true
      }
    })

    dispatch({
      type: 'setState',
      data: {
        donations,
        showDonorQuestions: donations.length > 0,
        errors,
      },
    })
  }

  const updateRegistrant = e => {
    const name = e.currentTarget.dataset.type
    let value = e.currentTarget.value
    const type = e.currentTarget.type
    const checked = e.currentTarget.checked
    const errors = cloneDeep(state.errors)
    const registrant = cloneDeep(state.registrant)
    if (value === '' || (name === 'email' && !validateEmail(value))) {
      errors[name] = true
    } else {
      errors[name] = false
    }

    if (
      state.event &&
      state.event.registrantPhoneDataCollection !== 'required'
    ) {
      errors.phone = false
    }
    if (
      state.event &&
      state.event.registrantAddressDataCollection !== 'required'
    ) {
      errors.address = false
      errors.city = false
      errors.stateAddress = false
      errors.zipCode = false
    }
    if (
      state.event &&
      state.event.registrantOrganizationDataCollection !== 'required'
    ) {
      errors.organization = false
    }

    if (name === 'email') {
      value = value.toLowerCase()
    }

    registrant[name] = value

    //update player 1 if it hasn't already changed
    if (state.players.length > 0 && state.players[0] && !player1Changed) {
      //get id for player 1
      const playerID = state.players[0].id
      updatePlayer(name, value, type, checked, playerID, registrant, errors)
    } else {
      dispatch({type: 'setState', data: {registrant, errors}})
    }
  }

  const createSubmitObject = (paymentMethod, paypalConfirmationID) => {
    dispatch({
      type: 'setState',
      data: {showingCart: false, paymentMethod, paypalConfirmationID},
    })

    const strippedSponsorshipItems = []
    const event = cloneDeep(state.event)
    if (event) {
      event.sponsorshipItems.forEach(item => {
        if (item.quantityInCart > 0) {
          const strippedItem = pick(
            item,
            'id',
            'price',
            'quantityInCart',
            'itemName',
            'playersIncluded',
            'kind',
          )
          strippedSponsorshipItems.push(strippedItem)
        }
      })

      const players = [...state.players]
      //check for names and add if missing
      if (state.players && state.players.length > 0) {
        //if none of the players have email, add registrant email to player 1
        let playerEmailExists = false

        players.map(player => {
          if (player) {
            if (player.email && player.email !== '') {
              playerEmailExists = true
            }
            player.firstName =
              player.firstName === '' ? 'Guest' : player.firstName
            player.lastName =
              player.lastName === ''
                ? state.registrant.lastName || 'Player'
                : player.lastName
            return player
          } else {
            return null
          }
        })
        //if none of the players have email, add registrant email to player 1
        if (
          !playerEmailExists &&
          players[0] &&
          (!players[0].email || players[0].email === '')
        ) {
          players[0].email = state.registrant.email.toLowerCase()
        }
      }

      const guests = [...state.guests]
      if (state.guests && state.guests.length > 0) {
        let guestEmailExists = false
        guests.map(guest => {
          if (guest) {
            if (guest.email && guest.email !== '') {
              guestEmailExists = true
            }
            guest.firstName = guest.firstName === '' ? 'Guest' : guest.firstName
            guest.lastName =
              guest.lastName === ''
                ? state.registrant.lastName || 'Guest'
                : guest.lastName
            return guest
          } else {
            return null
          }
        })
        //if none of the guests have email, add registrant email to guest 1
        if (
          !guestEmailExists &&
          guests[0] &&
          (!guests[0].email || guests[0].email === '')
        ) {
          guests[0].email = state.registrant.email
        }
      }

      //remove id from donations
      const donations = []
      state.donations.forEach(donation => {
        const strippedDonation = pick(donation, [
          'description',
          'monetary',
          'value',
        ])
        donations.push(strippedDonation)
      })

      const submitObject = {
        eventID: event.id,
        // questionResponses: questionsObject,
        registrant: state.registrant,
        players: players,
        guests: guests,
        sponsorshipItems: strippedSponsorshipItems,
        cartTotal: state.cartTotal,
        playerTotal: state.playerTotal,
        guestTotal: state.guestTotal,
        sponsorshipItemTotal: state.sponsorshipItemsTotal,
        id: event.registrationID,
        paymentMethod: paymentMethod,
        paypalConfirmationID: paypalConfirmationID,
        questionResponses: state.questionResponses,
        donations,
        isVolunteer: volunteersOnly,
      }

      //remove player responses if round specific and for a different round
      submitObject.players.forEach(player => {
        if (player) {
          const newResponses = []
          const clonedResponses = cloneDeep(player.questionResponses)
          clonedResponses.forEach(response => {
            if (response) {
              //could have questions for a different round - remove
              const question = event.customQuestions.find(
                q => q.id === response.questionID,
              )
              if (question && question.roundID && question.roundID !== '') {
                if (player.roundID === question.roundID) {
                  newResponses.push(response)
                }
              } else {
                newResponses.push(response)
              }
            }
          })
          player.questionResponses = newResponses
        }
      })
      return submitObject
    }
  }

  const submitRegistration = async (paymentMethod, paypalConfirmationID) => {
    let registrationResult = null

    const slug = state.event ? state.event.slug : ''
    const submitObject = createSubmitObject(paymentMethod, paypalConfirmationID)
    if (submitObject) {
      if (id) {
        dispatch({type: 'setState', data: {submitting: true}})
        registrationResult = await editRegistration(submitObject)
        dispatch({type: 'setState', data: {submitting: false}})
        if (registrationResult && !registrationResult.errors) {
          showToast({
            success: true,
            response: `Registration was updated successfully.`,
          })
          history.push(`/${slug}/admin/registrations`)
        } else {
          Swal.fire(
            'Registration Error',
            `There was a problem with this registration. Please refresh the page and try again. If this problem continues, please contact support.`,
            'error',
          ).then(() => {
            window.location.reload()
          })
        }
      } else {
        delete submitObject.id
        dispatch({type: 'setState', data: {submitting: true}})
        registrationResult = await postNewRegistration(submitObject)
        dispatch({type: 'setState', data: {submitting: false}})
        if (registrationResult) {
          if (state.event.adminMode) {
            Swal.fire(
              'Registration Completed',
              submitObject.registrant.email
                ? `Registration completed! A confirmation was sent to ${submitObject.registrant.email}`
                : ``,
              'success',
            )
            history.push(`/${slug}/admin/registrations`)
          } else {
            dispatch({
              type: 'setState',
              data: {
                registrationComplete: true,
                confirmationID: registrationResult.confirmationID,
              },
            })
          }
        } else {
          Swal.fire(
            'Registration Error',
            `There was a problem with this registration. Please refresh the page and try again. If this problem continues, please contact support.`,
            'error',
          ).then(() => {
            console.error('Problem with registration', submitObject)
            // window.location.reload()
          })
        }
      }
    }

    state.loading = false
  }

  const clickSubmitRegistration = e => {
    if (e) {
      const paymentMethod = e.currentTarget.dataset.paymentMethod
      e.preventDefault()
      submitRegistration(paymentMethod)
    } else {
      submitRegistration()
    }
  }

  const payAndSubmitRegistration = paypalID => {
    submitRegistration('paypal', paypalID)
  }

  const toggleCartModal = () => {
    let submitObject = {}
    if (!state.showingCart) {
      submitObject = createSubmitObject('prelim', '')
    }
    dispatch({
      type: 'setState',
      data: {showingCart: !state.showingCart, submitObject},
    })
  }

  const adminGoBack = () => {
    let lastPage = localStorage.getItem('lastPage')
    if (lastPage && lastPage !== '') {
      history.push(`/${state.event ? state.event.slug : ''}/admin/${lastPage}`)
    } else {
      history.push(`/${state.event ? state.event.slug : ''}/admin`)
    }
  }

  const removeDonationClick = e => {
    const newItems = removeDonation(e, state.donations)
    updateDonations(newItems)
  }

  if (eventError || registrationLookupError) {
    return (
      <Error
        header={'Event Loading Error'}
        body={`There was an error loading this event. Please check your entry and try refreshing the page. 
          Please contact support at supprt@golfinvite.com if you continue to have problems.`}
      />
    )
  }

  if (
    !eventStatus ||
    eventStatus === 'loading' ||
    eventStatus === 'idle' ||
    !state.event ||
    (id &&
      (registrationLookupStatus === 'loading' || userStatus === 'loading')) ||
    state.submitting
    // userStatus === 'loading' ||
    // isFetching
  ) {
    return <LoadingSpinner />
  }

  if (
    //check registration date if not an admin
    state.event &&
    !state.event.adminMode &&
    state.event.status !== 'active'
  ) {
    return <ClosedEvent event={state.event} />
  }

  return (
    <Fragment>
      <CartModal
        event={state.event}
        players={state.players}
        guests={state.guests}
        donations={state.donations}
        registrant={state.registrant}
        sponsorshipItems={state.event.sponsorshipItems}
        removeGroupClick={removeGroupClick}
        removeGuestClick={removeGuestClick}
        removeItemClick={removeItemClick}
        removeDonationClick={removeDonationClick}
        cartTotal={state.cartTotal}
        playerDiscounts={state.playerDiscounts}
        showingCart={state.showingCart}
        submitRegistration={clickSubmitRegistration}
        payAndSubmitRegistration={payAndSubmitRegistration}
        toggleCartModal={toggleCartModal}
        cartItemCount={state.cartItemCount}
        submitObject={state.submitObject}
      />
      <EventPage
        adminMode={state.event.adminMode}
        adminGoBack={adminGoBack}
        editingRegistration={id ? true : false}
        event={state.event}
        players={state.players}
        playerQuestions={state.playerQuestions}
        addPlayerClick={addPlayerClick}
        removePlayerClick={removePlayerClick}
        removeGroupClick={removeGroupClick}
        playerChange={playerChange}
        guests={state.guests}
        guestQuestions={state.guestQuestions}
        addGuestClick={addGuestClick}
        removeGuestClick={removeGuestClick}
        guestChange={guestChange}
        sponsorshipQuestions={state.sponsorshipQuestions}
        showSponsorshipQuestions={state.showSponsorshipQuestions}
        questionResponses={state.questionResponses}
        registrantQuestionChanged={registrantQuestionChanged}
        donations={state.donations}
        updateDonations={updateDonations}
        removeDonationClick={removeDonationClick}
        donorQuestions={state.donorQuestions}
        showDonorQuestions={state.showDonorQuestions}
        volunteerQuestions={state.volunteerQuestions}
        registrantQuestions={state.registrantQuestions}
        toggleCartModal={toggleCartModal}
        sponsorshipItems={state.event.sponsorshipItems}
        addItem={addItem}
        removeItemClick={removeItemClick}
        cartTotal={state.cartTotal}
        cartItemCount={state.cartItemCount}
        playerDiscounts={state.playerDiscounts}
        registrantProfileChange={updateRegistrant}
        registrant={state.registrant}
        showingCart={state.showingCart}
        submitRegistration={clickSubmitRegistration}
        errors={state.errors}
        formError={state.formError}
        playerError={state.playerError}
        guestError={state.guestError}
        registrationError={state.registrationError}
        donorError={state.donorError}
        sponsorshipQuestionError={state.sponsorshipQuestionError}
        payAndSubmitRegistration={payAndSubmitRegistration}
        history={history}
        registrationComplete={state.registrationComplete}
      />
      {state.event &&
        state.registrant &&
        state.registrationComplete &&
        !state.event.adminMode && (
          <RegistrationReceipt
            event={state.event}
            playerDiscounts={state.playerDiscounts}
            registrant={state.registrant}
            cartTotal={state.cartTotal}
            players={state.players}
            guests={state.guests}
            donations={state.donations}
            paypalConfirmationID={state.paypalConfirmationID}
            confirmationID={state.confirmationID}
            paymentMethod={state.paymentMethod}
            playerTotal={state.playerTotal}
            guestTotal={state.guestTotal}
            sponsorshipItemTotal={state.sponsorshipItemsTotal}
            registrationComplete={state.registrationComplete}
          />
        )}
    </Fragment>
  )
}

export default EventPageContainer
