//
import React, {Fragment} from 'react'
import styled from 'styled-components/macro'
import {Col, FormGroup, Label, Input, FormFeedback} from 'reactstrap'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import GuestItem from './GuestItem'

import {Message} from '../../assets/elements'
import CustomQuestions from '../CustomQuestions'

const GuestListPanel = styled.div`
  display: grid;
  grid-template-columns: 3fr 3fr 40px;
  grid-template-rows: auto;
  grid-template-areas:
    'first        last         button'
    'email        email        email'
    'phone        phone        phone'
    'address      address      address'
    'organization organization organization'
    'questions    questions    questions';
  grid-column-gap: 5px;
  align-items: center;
  padding: 1rem 0.5rem 0 0.5rem;
  margin: 1rem 0;
  border: 1px solid rgba(34, 36, 38, 0.15);
  border-top: 2px solid #b5cc18 !important;
  box-shadow: 0 1px 2px 0 rgba(34, 36, 38, 0.15);
  border-radius: 0.28571429rem;
`

const CloseButton = styled.button`
  grid-area: button;
  display: flex;
  justify-content: center;
  padding: 0.35rem;
  width: 2em;
  height: 2em;
`

const RequiredFlag = styled.sup`
  font-size: 0.7rem;
  color: #dc3545;
  margin-left: 5px;
`

const FirstName = styled.input`
  grid-area: first;
`

const LastName = styled.input`
  grid-area: last;
`

const Questions = styled.div`
  grid-area: questions;
`

const EMail = styled.div`
  padding: 10px 5px 0 5px;
  margin-bottom: -10px;
  grid-area: email;
`

const Address = styled.div`
  padding: 10px 5px 0 5px;
  margin-bottom: -10px;
  grid-area: address;
`

const Phone = styled.div`
  padding: 10px 5px 0 5px;
  margin-bottom: -10px;
  grid-area: phone;
`

const Organization = styled.div`
  padding: 10px 5px 0 5px;
  margin-bottom: -10px;
  grid-area: organization;
`

const GuestItemList = props => (
  <Fragment>
    {props.event.guestFeeOptions.map((option, index) => {
      const guestsForThisOption = props.guests.filter(
        guest => guest && guest.guestFeeOptionIndex === index,
      )
      return (
        <Fragment key={index}>
          <GuestItem
            guestFee={(option && option.guestFee) || '0'}
            addGuestClick={props.addGuestClick}
            guestOptionName={option && option.guestOptionName}
            guestIcon={option && option.icon}
            guestRegistrationDescription={
              option && option.guestRegistrationDescription
            }
            optionIndex={index}
          />
          {guestsForThisOption && guestsForThisOption.length ? (
            <Fragment>
              <Message>
                <h4>Reception Guest Names</h4>
                <p>
                  {`Please enter reception guest names below. If you don't know the guest names
              yet just leave the names blank.`}
                </p>
              </Message>
              <div>
                {guestsForThisOption.map(guest => (
                  <div color="blue" key={guest && guest.guestNumber}>
                    <GuestListPanel>
                      <FirstName
                        className="form-control"
                        name="firstName"
                        placeholder="First name"
                        data-testid="guest-first-name"
                        onChange={e =>
                          props.guestChange(
                            e,
                            (guest && guest.guestNumber) || 0,
                          )
                        }
                        value={guest && guest.firstName}
                      />
                      <LastName
                        className="form-control"
                        name="lastName"
                        placeholder="Last name"
                        data-testid="guest-last-name"
                        onChange={e =>
                          props.guestChange(
                            e,
                            (guest && guest.guestNumber) || 0,
                          )
                        }
                        value={guest && guest.lastName}
                      />

                      {props.event.guestEmailDataCollection !== 'notShown' ? (
                        <EMail>
                          <FormGroup row>
                            <Label for="email" sm={4}>
                              Email{' '}
                              {props.event.guestEmailDataCollection ===
                              'required' ? (
                                <RequiredFlag>*</RequiredFlag>
                              ) : (
                                <Fragment />
                              )}
                            </Label>
                            <Col sm={8}>
                              <Input
                                className="form-control"
                                name="email"
                                placeholder="Email Address"
                                data-testid="guest-email"
                                onChange={e =>
                                  props.guestChange(
                                    e,
                                    (guest && guest.guestNumber) || 0,
                                  )
                                }
                                value={guest && guest.email}
                                // invalid={
                                //   props.event.guestEmailDataCollection ===
                                //     'required' &&
                                //   (!guest || guest.email === '')
                                // }
                                invalid={
                                  (props.event.guestEmailDataCollection ===
                                    'required' &&
                                    (!guest || guest.email === '')) ||
                                  (props.errors &&
                                    props.errors[
                                      `emailGuest${
                                        guest ? guest.guestNumber : ''
                                      }`
                                    ])
                                }
                              />
                              <FormFeedback>
                                Please enter a valid e-mail address
                              </FormFeedback>
                            </Col>
                          </FormGroup>
                        </EMail>
                      ) : (
                        <Fragment />
                      )}

                      {props.event.guestPhoneDataCollection !== 'notShown' ? (
                        <Phone>
                          <FormGroup row>
                            <Label for="phone" sm={4}>
                              Phone #
                              {props.event.guestPhoneDataCollection ===
                              'required' ? (
                                <RequiredFlag>*</RequiredFlag>
                              ) : (
                                <Fragment />
                              )}
                            </Label>
                            <Col sm={8}>
                              <Input
                                className="form-control"
                                name="phone"
                                placeholder="Phone #"
                                data-testid="guest-phone"
                                onChange={e =>
                                  props.guestChange(
                                    e,
                                    (guest && guest.guestNumber) || 0,
                                  )
                                }
                                value={guest && guest.phone}
                                invalid={
                                  props.event.guestPhoneDataCollection ===
                                    'required' &&
                                  (!guest || guest.email === '')
                                }
                              />
                            </Col>
                          </FormGroup>
                        </Phone>
                      ) : (
                        <Fragment />
                      )}
                      {props.event.guestAddressDataCollection !== 'notShown' ? (
                        <Address>
                          <FormGroup row>
                            <Label for="address" sm={4}>
                              Address
                              {props.event.guestAddressDataCollection ===
                              'required' ? (
                                <RequiredFlag>*</RequiredFlag>
                              ) : (
                                <Fragment />
                              )}
                            </Label>
                            <Col sm={8}>
                              <Input
                                className="form-control"
                                name="address"
                                placeholder="Address"
                                data-testid="guest-address"
                                onChange={e =>
                                  props.guestChange(
                                    e,
                                    (guest && guest.guestNumber) || 0,
                                  )
                                }
                                value={guest && guest.address}
                                invalid={
                                  props.event.guestAddressDataCollection ===
                                    'required' &&
                                  (!guest || guest.email === '')
                                }
                              />
                            </Col>
                          </FormGroup>
                        </Address>
                      ) : (
                        <Fragment />
                      )}

                      {props.event.guestOrganizationDataCollection !==
                      'notShown' ? (
                        <Organization>
                          <FormGroup row>
                            <Label for="organization" sm={4}>
                              Organization
                              {props.event.guestOrganizationDataCollection ===
                              'required' ? (
                                <RequiredFlag>*</RequiredFlag>
                              ) : (
                                <Fragment />
                              )}
                            </Label>
                            <Col sm={8}>
                              <Input
                                className="form-control"
                                name="organization"
                                placeholder="Organization"
                                data-testid="guest-organization"
                                onChange={e =>
                                  props.guestChange(
                                    e,
                                    (guest && guest.guestNumber) || 0,
                                  )
                                }
                                value={guest && guest.organization}
                                invalid={
                                  props.event
                                    .guestOrganizationDataCollection ===
                                    'required' &&
                                  (!guest || guest.email === '')
                                }
                              />
                            </Col>
                          </FormGroup>
                        </Organization>
                      ) : (
                        <Fragment />
                      )}
                      <Questions>
                        {guest ? (
                          <CustomQuestions
                            errorIDBase={`guest${guest.guestNumber}`}
                            questions={props.questions}
                            responses={guest.questionResponses || []}
                            handleChange={e =>
                              props.guestChange(
                                e,
                                (guest && guest.guestNumber) || 0,
                              )
                            }
                            errors={props.errors}
                          />
                        ) : (
                          <Fragment />
                        )}
                      </Questions>
                      <CloseButton
                        type="button"
                        className="btn btn-sm btn-secondary"
                        onClick={props.removeGuestClick}
                        data-testid="remove-guest-button"
                        data-guestid={guest && guest.guestNumber}
                      >
                        <FontAwesomeIcon icon="times-circle" />
                      </CloseButton>
                    </GuestListPanel>
                  </div>
                ))}
              </div>
            </Fragment>
          ) : (
            ''
          )}
        </Fragment>
      )
    })}
  </Fragment>
)
// }

export default GuestItemList
