import ExcelJS from 'exceljs'
import {saveAs} from 'file-saver'
import {parse, addMinutes} from 'date-fns'

export const saveAsExcel = async (columns, data, fileName) => {
  const workbook = new ExcelJS.Workbook()
  workbook.created = new Date()
  workbook.modified = new Date()
  const worksheet = workbook.addWorksheet()

  const exportColumns = []
  columns.forEach(c => {
    if (c.export !== false) {
      exportColumns.push(c)
    }
  })

  const headers = exportColumns.map(c => ({
    name: c.exportValue,
    filterButton: true,
  }))

  const exportData = []
  data.forEach(d => {
    const exportRow = []
    d.forEach((cell, index) => {
      if (columns[index].export !== false) {
        if (columns[index].formatFunction) {
          exportRow.push(columns[index].formatFunction(cell))
        } else if (columns[index].dateFormat) {
          const parsedDate = parse(cell, columns[index].dateFormat, new Date())
          if (parsedDate) {
            exportRow.push(
              addMinutes(parsedDate, -parsedDate.getTimezoneOffset()),
            )
          } else {
            exportRow.push(cell)
          }
        } else {
          exportRow.push(cell)
        }
      }
    })
    exportData.push(exportRow)
  })

  worksheet.addTable({
    name: 'ExportTable',
    ref: 'A1',
    headerRow: true,
    totalsRow: false,
    style: {
      theme: 'TableStyleMedium2',
      showRowStripes: true,
    },
    columns: headers,
    rows: exportData,
  })

  worksheet.columns.forEach((column, index) => {
    const originalColumn = columns[index]
    if (originalColumn?.excelWidth) {
      //excelwidth was provided use that
      column.width = originalColumn.excelWidth
    } else {
      //autofit width based on character count
      var dataMax = 0
      column.eachCell({includeEmpty: true}, function(cell) {
        var columnLength = cell.value?.length
        if (columnLength > dataMax) {
          dataMax = columnLength
        }
      })
      column.width = dataMax < 15 ? 15 : dataMax * 0.9
    }

    if (originalColumn?.wrapText) {
      column.alignment = {wrapText: true}
    }

    if (originalColumn?.formatting === 'number') {
      const format = '#,##0.' + '0'.repeat(originalColumn?.decimals || 0)
      column.numFmt = format
    }

    if (originalColumn?.formatting === 'currency') {
      column.numFmt = '$#,##0.00'
    }
  })

  const buf = await workbook.xlsx.writeBuffer()

  saveAs(new Blob([buf]), `${fileName || 'Export'}.xlsx`)
}
