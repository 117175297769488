//      
import React, {Component, Fragment} from 'react'
import {FormGroup, Table, Card, CardBody} from 'reactstrap'
import styled from 'styled-components/macro'
import {cloneDeep} from 'lodash'

import DonationItem from './DonationItem'
import DonationModal from './DonationModal'
                                               
import {returnOnlyNumbers} from '../../assets/helpers'

import CardHeaderWithNewButton from '../../assets/CardHeaderWithNewButton'
import {removeDonation} from '../EventPageContainerHelpers'

              
                           
                                           
 

              
                             
                            
                                
 

const DonationItemsContainer = styled.div`
  margin-bottom: 20px;
`

const NoDataRow = styled.td`
  text-align: center;
`

class DonationItems extends Component               {
  state = {
    showDonationModal: false,
    editingDonation: {
      id: '',
      description: 'Monetary Donation',
      value: '0.00',
      monetary: true,
    },
    currentDonationNumber: 0,
  }

  toggleDonationModal = () => {
    this.setState({
      showDonationModal: !this.state.showDonationModal,
    })
  }

  updateDonations = () => {
    const donations = cloneDeep(this.props.donations)
    let newDonations = []
    //take donations sent in to props, edit and send back to container
    const editingDonation = cloneDeep(this.state.editingDonation)
    if (editingDonation.id !== '') {
      //editing an item, find it and update
      donations.forEach(donation => {
        if (donation && donation.id === editingDonation.id) {
          newDonations.push(editingDonation)
        } else {
          newDonations.push(donation)
        }
      })
    } else {
      //new item
      editingDonation.id = String(this.state.currentDonationNumber + 1)
      this.setState({
        currentDonationNumber: this.state.currentDonationNumber + 1,
      })
      newDonations = [editingDonation, ...donations]
    }
    this.props.updateDonations(newDonations)
  }

  removeDonation = (e                                   ) => {
    const newItems = removeDonation(e, this.props.donations)
    this.props.updateDonations(newItems)
    return
  }

  editDonation = (e                                   ) => {
    const clickedId = e.currentTarget.dataset.itemid
    const items = this.props.donations
    const itemToEdit = items.find(item => item && item.id === clickedId)
    if (itemToEdit) {
      this.setState({
        editingDonation: itemToEdit,
        showDonationModal: true,
      })
    }
  }

  updateModalDonation = (e                                  ) => {
    const field = e.currentTarget.name
    const value = e.currentTarget.value
    const editedDonation = cloneDeep(this.state.editingDonation)
    if (field === 'monetary') {
      editedDonation[field] = e.currentTarget.checked
      if (e.currentTarget.checked) {
        editedDonation.description = 'Monetary Donation'
      } else {
        editedDonation.description = ''
      }
    } else if (field === 'value') {
      let goodValue = returnOnlyNumbers(String(editedDonation.value), value)
      editedDonation.value = goodValue
    } else {
      editedDonation[field] = value
    }
    this.setState({editingDonation: editedDonation})
  }

  cancelModal = () => {
    const emptyItem = {
      id: '',
      description: 'Monetary Donation',
      value: 0,
      monetary: true,
    }
    this.setState({
      editingDonation: emptyItem,
      showDonationModal: false,
    })
  }

  modalSubmit = () => {
    this.updateDonations()
    this.cancelModal()
  }

  render() {
    return (
      <DonationItemsContainer>
        <Card>
          <CardHeaderWithNewButton
            newFunction={this.toggleDonationModal}
            kind="custom"
            headerText="Donations"
          />
          <CardBody>
            <FormGroup row>
              <Table striped>
                <thead>
                  <tr>
                    <th>Delete</th>
                    <th>Edit</th>
                    <th>Description</th>
                    <th>Value</th>
                  </tr>
                </thead>
                <tbody>
                  {this.props.donations.length === 0 ? (
                    <tr>
                      <NoDataRow colSpan="4">
                        {`Click the "+" above to add a donation`}
                      </NoDataRow>
                    </tr>
                  ) : (
                    <Fragment />
                  )}
                  {this.props.donations.map(
                    (donation, index) =>
                      donation && (
                        <DonationItem
                          key={index}
                          donation={donation}
                          editDonation={this.editDonation}
                          removeDonation={this.removeDonation}
                        />
                      ),
                  )}
                </tbody>
              </Table>
            </FormGroup>
          </CardBody>
        </Card>

        <DonationModal
          showDonationModal={this.state.showDonationModal}
          toggleDonationModal={this.toggleDonationModal}
          updateDonation={this.updateModalDonation}
          editingDonation={this.state.editingDonation}
          items={this.props.donations}
          cancel={this.cancelModal}
          formSubmit={this.modalSubmit}
        />
      </DonationItemsContainer>
    )
  }
}

export default DonationItems
