//
import React from 'react'
import history from '../history'
import {useUserState} from '../Session/context'
import FourOhFour from '../marketing/FourOhFour'
import LandingPage from './LandingPage'
// import type { EventType } from "../TypeAliases";
import Spinner from '../assets/Spinner'
import {contrastTextColor} from '../assets/contrastTextColor'
import useEventLandingPageLookup from './hooks/useEventLandingPageLookup'

const LandingPageContainerBase = props => {
  const [showSponsorshipButton, setShowSponsorshipButton] = React.useState(
    false,
  )
  const [sponsorImagesDisplayed, setSponsorImagesDisplayed] = React.useState([])
  const [textColor, setTextColor] = React.useState('#ffffff')
  const lowerCaseSlug = props?.match?.params?.event
    ? props.match.params.event.toLowerCase()
    : ''
  const {data: event, status, error} = useEventLandingPageLookup(lowerCaseSlug)

  React.useEffect(() => {
    if (event) {
      let images = []
      event.sponsorImages.forEach(image => {
        if (image && image.display) images.push(image)
      })
      setSponsorImagesDisplayed(images)
    }
  }, [event])

  React.useEffect(() => {
    if (event) {
      setTextColor(
        event.buttonColor ? contrastTextColor(event.buttonColor) : '#fff',
      )
    }
  }, [event])

  React.useEffect(() => {
    if (event && event.sponsorshipItems && event.sponsorshipItems.length) {
      event.sponsorshipItems.forEach(item => {
        if (item && item.kind === 'sponsorship') {
          setShowSponsorshipButton(true)
        }
      })
    }
  }, [event])

  if (status === 'loading') return <Spinner />
  if (error)
    return (
      <p>
        There was an error, please try again and contact us at
        support@golfinvite.com if you continue to have problems.
      </p>
    )
  if (event?.status === 'expired' || event === null) {
    return <FourOhFour />
  }

  return (
    <LandingPage
      history={history}
      event={event}
      status={status}
      adminMode={event?.adminMode}
      textColor={textColor}
      showSponsorshipButton={showSponsorshipButton}
      sponsorImagesDisplayed={sponsorImagesDisplayed}
    />
  )
}

const LandingPageContainer = props => {
  const {user} = useUserState()
  const token = user ? user.token : ''
  return <LandingPageContainerBase token={token} match={props.match} />
}

export default LandingPageContainer
