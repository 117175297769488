import ExcelJS from 'exceljs'
import {saveAs} from 'file-saver'
import {parse, format} from 'date-fns'

export const saveAsCSV = async (columns, data, fileName) => {
  const workbook = new ExcelJS.Workbook()
  workbook.created = new Date()
  workbook.modified = new Date()
  const worksheet = workbook.addWorksheet()

  const exportColumns = []
  columns.forEach(c => {
    if (c.export !== false) {
      exportColumns.push(c)
    }
  })

  const headers = exportColumns.map(c => c.exportValue)

  const exportData = []

  data.forEach(d => {
    const exportRow = []
    d.forEach((cell, index) => {
      if (columns[index].export !== false) {
        if (columns[index].formatFunction) {
          exportRow.push(columns[index].formatFunction(cell))
        } else if (columns[index].dateFormat) {
          const parsedDate = parse(cell, columns[index].dateFormat, new Date())
          if (parsedDate) {
            exportRow.push(format(parsedDate, 'MM/dd/yyyy'))
          } else {
            exportRow.push(cell)
          }
        } else {
          exportRow.push(cell)
        }
      }
    })
    exportData.push(exportRow)
  })

  worksheet.addRow(headers)
  worksheet.addRows(exportData)

  const buf = await workbook.csv.writeBuffer()

  saveAs(new Blob([buf]), `${fileName || 'Export'}.csv`)
}
