import axios from 'axios'
import {print} from 'graphql'
import {useMutation, useQueryClient} from 'react-query'
import {NEW_EVENT_REGISTRATION} from '../../assets/queries'

function useNewEventRegistration() {
  const cache = useQueryClient()
  return useMutation(
    async registration => {
      try {
        const variables = {input: registration}
        const response = await axios.post('', {
          query: print(NEW_EVENT_REGISTRATION),
          variables,
        })
        return response?.data?.data?.newEventRegistration
      } catch (error) {
        console.error(JSON.stringify(error, undefined, 2))
      }
    },
    {
      onError: (err, variables) => {
        console.error('New Event Registration', err, variables)
      },
      // After success or failure, refetch the registrations query
      onSettled: () => {
        cache.invalidateQueries('eventRegistrations')
      },
    },
  )
}

export default useNewEventRegistration
