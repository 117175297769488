//
import React from 'react'
import styled from 'styled-components/macro'

import 'react-responsive-carousel/lib/styles/carousel.min.css'
import {Carousel} from 'react-responsive-carousel'

const SponsorsContainer = styled.div`
  border: 1px solid rgba(0, 0, 0, 0.125);
  border-radius: 0.25rem;
  padding: 0;
  max-width: 300px;
  background-color: white;
  .slide {
    background-color: white !important;
  }
`

const SponsorsHeader = styled.div`
  background-color: ${p => (p.bColor ? p.bColor : '#2b4e72')};
  color: ${p => (p.color ? p.color : '#ffffff')};
  text-align: center;
  padding: 10px;
`

const SponsorsImages = styled.div`
  width: 100%;
`

const StyledCarousel = styled(Carousel)`
  .carousel .slider-wrapper.axis-horizontal .slider {
    align-items: center;
  }
  img {
    width: auto !important;
  }
`

const Sponsors = props => (
  <SponsorsContainer>
    <SponsorsHeader bColor={props.buttonColor} color={props.textColor}>
      Sponsored By
    </SponsorsHeader>
    <SponsorsImages>
      <StyledCarousel
        showStatus={false}
        showIndicators={false}
        showThumbs={false}
        showArrows={false}
        interval={3000}
        infiniteLoop
        autoPlay
      >
        {props.sponsorImagesDisplayed.map((sponsor, index) => {
          if (!sponsor?.website) {
            return (
              <div key={sponsor ? sponsor.order : index}>
                <img
                  alt="sponsor"
                  src={`https://res.cloudinary.com/${String(
                    process.env.REACT_APP_CLOUDINARY_CLOUD,
                  )}/image/upload/${sponsor ? sponsor.image : '' || ''}`}
                />
              </div>
            )
          } else {
            let address = sponsor.website
            const split = sponsor.website.split('//')
            if (split.length === 1) {
              address = `https://${sponsor.website}`
            }
            return (
              <a
                key={sponsor ? sponsor.order : index}
                href={address}
                target="_blank"
                rel="noopener noreferrer"
                data-cy="sponsorLink"
              >
                <div>
                  <img
                    alt="sponsor"
                    src={`https://res.cloudinary.com/${String(
                      process.env.REACT_APP_CLOUDINARY_CLOUD,
                    )}/image/upload/${sponsor ? sponsor.image : '' || ''}`}
                  />
                </div>
              </a>
            )
          }
        })}
      </StyledCarousel>
    </SponsorsImages>
  </SponsorsContainer>
)

export default Sponsors
