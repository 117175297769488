import React, {useState, useEffect} from 'react'
import {loadStripe} from '@stripe/stripe-js'
import axios from 'axios'
import {
  EmbeddedCheckoutProvider,
  EmbeddedCheckout,
} from '@stripe/react-stripe-js'

const Stripe = ({
  cartTotal,
  stripeLinkedID,
  slug,
  submitObject,
  description,
}) => {
  // Make sure to call `loadStripe` outside of a component’s render to avoid
  // recreating the `Stripe` object on every render.
  // This is your test public API key.
  const stripePromise = React.useMemo(
    () =>
      loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY, {
        stripeAccount: stripeLinkedID,
      }),
    [stripeLinkedID],
  )
  const [clientSecret, setClientSecret] = useState('')

  const options = {clientSecret}

  useEffect(() => {
    // Create a Checkout Session as soon as the page loads
    const createOrder = async () => {
      // Set up a url on your server to create the payment
      var CREATE_URL = `${process.env.REACT_APP_API_URL ||
        ''}/stripe/create-checkout-session`

      // Make a call to your server to set up the payment

      const res = await axios.post(CREATE_URL, {
        amount: cartTotal,
        stripeLinkedID,
        slug: slug,
        submitObject,
        description,
      })
      setClientSecret(res.data.clientSecret)
    }
    if (cartTotal && stripeLinkedID) {
      createOrder()
    }
  }, [cartTotal, description, slug, stripeLinkedID, submitObject])

  return (
    <div id="checkout">
      {clientSecret && (
        <EmbeddedCheckoutProvider stripe={stripePromise} options={options}>
          <EmbeddedCheckout />
        </EmbeddedCheckoutProvider>
      )}
    </div>
  )
}

export default Stripe
