import styled from 'styled-components/macro'
export const Styles = styled.div`
  border-collapse: collapse;
  overflow-x: scroll;
  table {
    border: 1px solid rgba(0, 0, 0, 0.1);
    width: 100%;
    border-spacing: 0;
    font-size: 1rem;
    /* for sticky headers */
    /* border-collapse: separate; 
    tr: first-child {
      background-color: #eee;
    }
    tr:first-child > th {
      position: sticky;
      top: 0;
      background-color: #eee;
    } */
    tbody tr:nth-of-type(odd) {
      background-color: rgba(0, 0, 0, 0.06);
    }
    tbody tr {
      :hover {
        background-color: rgba(0, 0, 0, 0.08);
      }
    }
    th {
      transition: box-shadow 0.3s;
      background-color: #fff;
      border-right: 1px solid rgba(0, 0, 0, 0.05);
    }
    td,
    th {
      padding: 3px 6px;
      vertical-align: middle;
      white-space: nowrap;
      /* border-top: 1px solid #dee2e6; */
      input[type='text'] {
        height: 30px;
        min-width: 100px;
        border: 0;
      }
    }
  }
  .pagination {
    padding: 0.5rem;
  }
`
export const TableHeaders = styled.th`
  text-align: center;
  background-color: rgba(0, 0, 0, 0.05) !important;
  box-shadow: ${p =>
    p.sorted === 'asc'
      ? 'inset 0 -4px 0 0 #444'
      : p.sorted === 'desc'
      ? 'inset 0 4px 0 0 #444'
      : 'none'};
`

export const TableSubHeaders = styled.th`
  /* background-color: rgba(0, 0, 0, 0.07) !important; */
  input {
    width: 100%;
    box-sizing: border-box;
    border: 1px solid rgba(0, 0, 0, 0.1);
    background: #fff;
    padding: 2px;
    font-size: inherit;
    border-radius: 3px;
    font-weight: 400;
    outline-width: 0;
  }
`

export const PaginationFooter = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  background-color: #eee;
  padding: 5px;
`

export const PaginationCenter = styled.div`
  display: flex;
  justify-content: space-around;
  flex: 1;
`

export const PaginationButtons = styled.button`
  /* width: 80%; */
  @media (min-width: 991px) {
    min-width: 150px;
  }
  text-align: center;
  background-color: rgba(0, 0, 0, 0.15);
  padding: 3px 10px;
  border: 1px solid #bbb;
  border-radius: 3px;
  :hover,
  :focus {
    background-color: rgba(0, 0, 0, 0.35);
    outline: none;
  }
`

export const PaginationPageCounts = styled.div`
  display: flex;
  align-items: center;
  input {
    border: 1px solid rgba(0, 0, 0, 0.1);
    background: #fff;
    padding: 2px 7px;
    margin: 0 5px;
    font-size: inherit;
    border-radius: 3px;
    font-weight: 400;
    outline-width: 0;
    width: 50px;
  }
`
export const PaginationPageDropDown = styled.div`
  display: flex;
  align-items: center;
  select {
    border: 1px solid rgba(0, 0, 0, 0.1);
    background: #fff;
    padding: 2px 7px;
    font-size: inherit;
    border-radius: 3px;
    font-weight: 400;
    outline-width: 0;
  }
`

export const NoRecordsRow = styled.div`
  text-align: center;
  padding: 6px;
`

export const TableTitle = styled.h1`
  width: 100%;
  font-size: 1.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
`
