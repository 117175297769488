import React from 'react'
import ReactDOM from 'react-dom'

import {UserProvider} from './Session/context'
import 'bootstrap/dist/css/bootstrap.min.css'
import 'react-datepicker/dist/react-datepicker.css'
import 'rc-time-picker/assets/index.css'
import 'toastr/build/toastr.min.css'
import 'tippy.js/dist/tippy.css'
import './App.css'

import App from './App'
import * as serviceWorker from './registerServiceWorker'

const root = document.getElementById('root')
if (root === null) {
  console.error('Root not found')
} else {
  ReactDOM.render(
    <UserProvider>
      <App />
    </UserProvider>,
    root,
  )
}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister()
