//

import React, {Fragment} from 'react'
import styled from 'styled-components/macro'

const CourseDescription = styled.div`
  white-space: pre-line;
`

const HeaderBanner = styled.div`
  text-align: center;
  .underlined {
    text-decoration: underline;
  }
`

const CourseInformation = props => {
  if (props.name) {
    return (
      <div className="card">
        <div className="card-body">
          <HeaderBanner>
            <h4>{props.name}</h4>
            <a
              href={
                props.website.startsWith('http')
                  ? props.website
                  : `https:\\${props.website}`
              }
              target="_blank"
              rel="noopener noreferrer"
            >
              <h6 className="text-muted underlined">Course Website</h6>
            </a>
          </HeaderBanner>
          <CourseDescription>{props.description}</CourseDescription>
        </div>
      </div>
    )
  } else {
    return <Fragment />
  }
}
export default CourseInformation
