//
import React, {Fragment} from 'react'
import styled from 'styled-components/macro'

const Panel = styled.div`
  width: 100%;
  position: relative;
  background: #fff;
  box-shadow: 0 1px 2px 0 rgba(34, 36, 38, 0.15);
  margin: 0;
  /*padding: 1em 1em;*/
  border-radius: 0.28571429rem;
  border: 1px solid rgba(34, 36, 38, 0.15);
`

const StyledHeader = styled.header`
  img {
    max-width: 100%;
    height: auto;
  }
`

const Header = props => (
  <StyledHeader>
    <div>
      <Panel data-testid="header-name">
        {props.homeHeaderHTML && props.homeHeaderHTML !== '' ? (
          <div dangerouslySetInnerHTML={{__html: props.homeHeaderHTML}} />
        ) : (
          <Fragment />
        )}
      </Panel>
    </div>
  </StyledHeader>
)

export default Header
