//
import React from 'react'
import {Button, CardHeader} from 'reactstrap'
import styled from 'styled-components/macro'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'

const TableHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`

const TableHeaderText = styled.h5`
  margin: 6px;
`

const NewText = styled.span`
  margin: 2px;
`

const TableHeaderAside = styled.span`
  font-size: 0.8rem;
  margin-left: 8px;
  color: red;
`

const CardHeaderWithNewButton = props => (
  <CardHeader>
    <TableHeader>
      <TableHeaderText className="text-center">
        {props.headerText}
        {props.headerAside && (
          <TableHeaderAside>{props.headerAside}</TableHeaderAside>
        )}
      </TableHeaderText>
      <div>
        {props.children}
        <Button
          type="button"
          color="primary"
          onClick={e => props.newFunction(props.kind, e)}
          data-cy="newButton"
        >
          <FontAwesomeIcon icon="plus" />
          {props.newButtonText && <NewText>{props.newButtonText}</NewText>}
        </Button>
      </div>
    </TableHeader>
  </CardHeader>
)

export default CardHeaderWithNewButton
