import * as React from 'react'
import styled from 'styled-components/macro'
import moment from 'moment'
import Spinner from 'assets/Spinner'
import useEventsReport from './hooks/useEventsReport'
import TableWithHooks from 'common/tables/TableWithHooks'
import {currencyFormatter} from 'assets/helpers'
import useUserLookup from '../hooks/useUserLookup'

const Container = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  background-color: #fff;
  padding: 20px;
`

const RightAlign = styled.div`
  width: 100%;
  text-align: right;
`

const CapitalizeAndCenter = styled.div`
  width: 100%;
  text-transform: capitalize;
  text-align: center;
`

const currencyColumn = ({cell: {value}}) => (
  <RightAlign>{currencyFormatter.format(value || 0)}</RightAlign>
)

const numberColumn = ({cell: {value}}) => <RightAlign>{value || 0}</RightAlign>

const dateColumn = ({cell: {value}}) => (
  <CapitalizeAndCenter>{moment(value).format('MM/DD/YY')}</CapitalizeAndCenter>
)

const EventsReport = () => {
  const {data: user} = useUserLookup()
  const {data: events, status} = useEventsReport(user)

  const columns = [
    {
      Header: 'Name',
      accessor: 'name',
    },
    {
      Header: 'Planner',
      accessor: 'planner',
    },
    {
      Header: 'Contact',
      accessor: 'contact',
    },
    {
      Header: 'Site',
      accessor: 'slug',
    },
    {
      Header: 'Event Date',
      accessor: 'eventDateConverted',
      disableFilters: true,
      Cell: dateColumn,
    },
    {
      Header: 'Course Name',
      accessor: 'courseName',
    },
    {
      Header: 'Players',
      id: 'playerCount',
      accessor: d => Number(d.playerCount),
      Cell: numberColumn,
      disableFilters: true,
      width: 130,
    },
    {
      Header: 'Capacity',
      id: 'playerCapacity',
      accessor: d => Number(d.playerCapacity),
      Cell: numberColumn,
      disableFilters: true,
      width: 130,
    },
    {
      Header: 'Payments',
      id: 'payments',
      accessor: d => Number(d.payments),
      Cell: currencyColumn,
      disableFilters: true,
      width: 130,
    },
    {
      Header: 'Paypal',
      id: 'paypalPayments',
      accessor: d => Number(d.paypalPayments),
      Cell: currencyColumn,
      disableFilters: true,
      width: 130,
    },
  ]

  if (status === 'loading' || !events) return <Spinner name="double-bounce" />
  if (status === 'error') return <div>error</div>

  return (
    <Container>
      <TableWithHooks
        data={events}
        columns={columns}
        initPageSize={50}
        filterTable={true}
        initSort={[{id: 'eventDateConverted', desc: -1}]}
        tableTitle="Events"
        exportTable={true}
      />
    </Container>
  )
}
export default EventsReport
