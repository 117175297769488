//
import React, {Fragment} from 'react'

import styled from 'styled-components/macro'
import {
  Card,
  CardHeader,
  CardBody,
  Input,
  FormFeedback,
  FormGroup,
  Label,
} from 'reactstrap'

const RequiredFlag = styled.sup`
  font-size: 0.7rem;
  color: #dc3545;
  margin-left: 5px;
`

const RegistrationProfile = props => (
  <Card>
    <CardHeader>
      Registration Profile<RequiredFlag>* Required</RequiredFlag>
    </CardHeader>
    <CardBody>
      <div className="row" id="registrationProfile">
        <div className="col-sm">
          <FormGroup>
            <Label for="registrantFirstName">
              First name<RequiredFlag>*</RequiredFlag>
            </Label>
            <Input
              invalid={props.errors?.firstName}
              className="form-control"
              data-testid="firstName"
              id="registrantFirstName"
              data-type="firstName"
              placeholder="First name"
              onChange={props.handleChange}
              name="firstName"
              value={props.registrant.firstName}
            />
            <FormFeedback>Please enter your first name</FormFeedback>
          </FormGroup>
        </div>
        <div className="col-sm">
          <FormGroup>
            <Label for="registrantLastName">
              Last name<RequiredFlag>*</RequiredFlag>
            </Label>
            <Input
              invalid={props.errors?.lastName}
              className="form-control"
              data-testid="lastName"
              id="registrantLastName"
              data-type="lastName"
              placeholder="Last name"
              name="lastName"
              onChange={props.handleChange}
              value={props.registrant.lastName}
            />
            <FormFeedback>Please enter your last name</FormFeedback>
          </FormGroup>
        </div>
      </div>

      <div className="row">
        <div className="col-sm">
          <FormGroup>
            <Label for="registrantEmail">
              E-mail<RequiredFlag>*</RequiredFlag>
            </Label>
            <Input
              invalid={props.errors?.email}
              className="form-control"
              data-testid="email"
              id="registrantEmail"
              data-type="email"
              type="email"
              placeholder="E-mail"
              name="email"
              onChange={props.handleChange}
              value={props.registrant.email}
            />
            <FormFeedback>Please enter a valid e-mail address</FormFeedback>
          </FormGroup>
        </div>
        {props.event.registrantPhoneDataCollection !== 'notShown' ? (
          <div className="col-sm">
            <FormGroup>
              <Label for="registrantPhone">
                Phone{' '}
                {props.event.registrantPhoneDataCollection === 'required' ? (
                  <RequiredFlag>*</RequiredFlag>
                ) : (
                  <Fragment />
                )}
              </Label>
              <Input
                invalid={props.errors?.phone}
                className="form-control"
                data-testid="phone"
                id="registrantPhone"
                data-type="phone"
                type="tel"
                placeholder="Phone"
                name="phone"
                onChange={props.handleChange}
                value={props.registrant.phone}
              />
              <FormFeedback>Please enter your phone number</FormFeedback>
            </FormGroup>
          </div>
        ) : (
          <Fragment />
        )}
        {props.event.registrantOrganizationDataCollection !== 'notShown' ? (
          <div className="col-sm">
            <FormGroup>
              <Label for="registrantOrganization">
                Organization{' '}
                {props.event.registrantOrganizationDataCollection ===
                'required' ? (
                  <RequiredFlag>*</RequiredFlag>
                ) : (
                  <Fragment />
                )}
              </Label>
              <Input
                invalid={props.errors?.organization}
                className="form-control"
                data-testid="organization"
                id="registrantOrganization"
                data-type="organization"
                type="text"
                placeholder="Organization"
                name="organization"
                onChange={props.handleChange}
                value={props.registrant.organization || ''}
              />
              <FormFeedback>Please enter your organization</FormFeedback>
            </FormGroup>
          </div>
        ) : (
          <Fragment />
        )}
      </div>
      {props.event.registrantAddressDataCollection !== 'notShown' ? (
        <Fragment>
          <div className="row">
            <div className="col-sm">
              <FormGroup>
                <Label for="registrantAddress">
                  Street Address{' '}
                  {props.event.registrantAddressDataCollection ===
                  'required' ? (
                    <RequiredFlag>*</RequiredFlag>
                  ) : (
                    <Fragment />
                  )}
                </Label>
                <Input
                  invalid={props.errors?.address}
                  className="form-control"
                  data-testid="address"
                  id="registrantAddress"
                  data-type="address"
                  name="address"
                  placeholder="Street Address"
                  onChange={props.handleChange}
                  value={props.registrant.address}
                />
                <FormFeedback>Please enter a street address</FormFeedback>
              </FormGroup>
            </div>
          </div>
          <div className="row">
            <div className="col-sm">
              <FormGroup>
                <Label for="registrantCity">
                  City{' '}
                  {props.event.registrantAddressDataCollection ===
                  'required' ? (
                    <RequiredFlag>*</RequiredFlag>
                  ) : (
                    <Fragment />
                  )}
                </Label>
                <Input
                  invalid={props.errors?.city}
                  className="form-control"
                  data-testid="city"
                  id="registrantCity"
                  data-type="city"
                  placeholder="City"
                  name="city"
                  onChange={props.handleChange}
                  value={props.registrant.city}
                />
                <FormFeedback>Please enter your city</FormFeedback>
              </FormGroup>
            </div>
            <div className="col-sm">
              <FormGroup>
                <Label for="registrantStateAddress">
                  State{' '}
                  {props.event.registrantAddressDataCollection ===
                  'required' ? (
                    <RequiredFlag>*</RequiredFlag>
                  ) : (
                    <Fragment />
                  )}
                </Label>
                <Input
                  invalid={props.errors?.stateAddress}
                  className="form-control"
                  data-testid="stateAddress"
                  id="registrantStateAddress"
                  data-type="stateAddress"
                  placeholder="State"
                  name="stateAddress"
                  onChange={props.handleChange}
                  value={props.registrant.stateAddress}
                />
                <FormFeedback>Please enter your state</FormFeedback>
              </FormGroup>
            </div>
            <div className="col-sm">
              <FormGroup>
                <Label for="registrantZipCode">
                  Zip Code{' '}
                  {props.event.registrantAddressDataCollection ===
                  'required' ? (
                    <RequiredFlag>*</RequiredFlag>
                  ) : (
                    <Fragment />
                  )}
                </Label>
                <Input
                  invalid={props.errors?.zipCode}
                  className="form-control"
                  data-testid="zipCode"
                  id="registrantZipCode"
                  data-type="zipCode"
                  placeholder="Zip Code"
                  name="zipCode"
                  onChange={props.handleChange}
                  value={props.registrant.zipCode}
                />
                <FormFeedback>Please enter your zip code</FormFeedback>
              </FormGroup>
            </div>
          </div>
        </Fragment>
      ) : (
        <Fragment />
      )}

      <label htmlFor="registrantComments">Comments</label>
      <textarea
        placeholder={props.commentsPlaceholder || 'Comments...'}
        className="form-control"
        data-testid="comments"
        id="registrantComments"
        data-type="comments"
        onChange={props.handleChange}
        value={props.registrant.comments}
      />
    </CardBody>
  </Card>
)

export default RegistrationProfile
