import React from 'react'
import {NavLink} from 'react-router-dom'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {signOut, getAuth} from 'firebase/auth'
import history from './history'
import showToastr from './assets/showToastr'
//import {useUserDispatch} from './Session/context'
import {useQueryClient} from 'react-query'

const SignOutButton = () => {
  const queryCache = useQueryClient()

  const doSignOut = () => {
    const auth = getAuth()
    signOut(auth)
      .then(() => {
        queryCache.clear()
        // dispatch({type: 'removeUser'})
        showToastr({success: true, response: 'You have been logged out'})
        localStorage.clear()
        history.push('/login')
      })
      .catch(function(error) {
        console.error('error signing out', error)
        // An error happened.
      })
  }
  return (
    <NavLink
      data-cy="signoutButton"
      className="nav-link"
      onClick={doSignOut}
      to="/login"
    >
      <FontAwesomeIcon size="lg" icon="sign-out-alt" />
      <span>Sign Out</span>
    </NavLink>
  )
}

export default SignOutButton
