//     
import React from 'react'
import styled from 'styled-components/macro'
import {Button} from 'reactstrap'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'

                 
              
                      
                         
 

              
                     
                                                                 
                                                               
 

const ButtonColumn = styled.td`
  width: 69px;
`

const DonationItem = (props       ) => {
  return (
    <tr>
      <ButtonColumn>
        <Button
          color="danger"
          onClick={props.removeDonation}
          data-itemid={props.donation && props.donation.id}
          data-cy="deleteDonationButton"
        >
          <FontAwesomeIcon icon="times-circle" />
        </Button>
      </ButtonColumn>
      <ButtonColumn>
        <Button
          color="info"
          onClick={props.editDonation}
          data-itemid={props.donation && props.donation.id}
          data-cy="editDonationButton"
        >
          <FontAwesomeIcon icon="edit" />
        </Button>
      </ButtonColumn>
      <td>{props.donation.description}</td>
      <td>{props.donation.value}</td>
    </tr>
  )
}

export default DonationItem
