//     
import toastr from 'toastr'

              
                   
                   
 

const showToast = (props       ) => {
  toastr.options = {
    closeButton: true,
    positionClass: 'toast-bottom-right',
    showDuration: '300',
    hideDuration: '1000',
    timeOut: '5000',
    extendedTimeOut: '1000',
    showEasing: 'swing',
    hideEasing: 'linear',
    showMethod: 'fadeIn',
    hideMethod: 'fadeOut',
  }

  if (props.success) {
    setTimeout(() => toastr.success(props.response), 300)
  } else {
    setTimeout(() => toastr.error(props.response), 300)
  }
}

export default showToast
