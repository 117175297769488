//
import React, {Fragment} from 'react'
import {FormGroup, Input, Label} from 'reactstrap'
import styled from 'styled-components/macro'

const RequiredFlag = styled.sup`
  font-size: 0.7rem;
  color: #dc3545;
  margin-left: 5px;
`

const QuestionContainer = styled.div`
  margin: 0 0.25rem 0 0.25rem;
  .form-group {
    margin-bottom: 0 !important;
  }
`

const CustomQuestionItem = props => (
  <QuestionContainer data-cy="customQuestionContainer">
    {props.question && props.question.questionType === 'Text Input' && (
      <div key={props.question.id + 'TI'} className="form-group">
        <div className="row">
          <div className="col-sm">
            <FormGroup>
              <Label>
                {props.question && props.question.question}{' '}
                {props.question.required && <RequiredFlag>*</RequiredFlag>}
              </Label>
              <Input
                invalid={
                  props.errors[`${props.question.id}${props.errorIDBase}`]
                }
                data-cy="customQuestionText"
                className="form-control"
                name={`${props.question.id}${props.errorIDBase}`}
                // placeholder={props.question.question}
                onChange={props.handleChange}
                value={props.response.response}
              />
            </FormGroup>
          </div>
        </div>
      </div>
    )}

    {/* Radio Buttons */}
    {props.question && props.question.questionType === 'Radio (Choose One)' && (
      <div key={props.question.id + 'DD'} className="form-group">
        <div className="row">
          <div className="col-sm">
            <label>
              {props.question && props.question.question}
              {props.question.required && <RequiredFlag>*</RequiredFlag>}
              {props.errors[`${props.question.id}${props.errorIDBase}`] ? (
                <RequiredFlag>Please select an answer!</RequiredFlag>
              ) : (
                <Fragment />
              )}
            </label>
            <FormGroup tag="fieldset">
              {props.question.options.map((option, index) => (
                <FormGroup key={index} check inline>
                  <Label check>
                    <Input
                      invalid={
                        props.errors[`${props.question.id}${props.errorIDBase}`]
                      }
                      data-cy="customQuestionRadio"
                      type="radio"
                      value={option && option.text}
                      checked={
                        props.response.response ===
                        (option ? option.text : 'notdefined')
                      }
                      name={`${props.question.id}${props.errorIDBase}`}
                      onChange={props.handleChange}
                    />
                    {option && option.text}
                  </Label>
                </FormGroup>
              ))}
            </FormGroup>
          </div>
        </div>
      </div>
    )}

    {/* Checkboxes */}
    {props.question &&
      props.question.questionType === 'Checkboxes (Choose Multiple)' && (
        <div key={props.question.id + 'CB'} className="form-group">
          <div className="row">
            <div className="col-sm">
              <label>
                {props.question && props.question.question}
                {props.question.required && <RequiredFlag>*</RequiredFlag>}
                {props.errors[`${props.question.id}${props.errorIDBase}`] ? (
                  <RequiredFlag>Please select an answer!</RequiredFlag>
                ) : (
                  <Fragment />
                )}
              </label>
              <FormGroup tag="fieldset">
                {props.question.options.map((option, index) => {
                  //props.question.response is a csv of options selected
                  const responsesArray = props.response.response
                    ? props.response.response.split(',')
                    : []
                  const responseText = option ? option.text : 'notdefined'
                  const checked =
                    responsesArray.findIndex(
                      response => response === responseText,
                    ) > -1
                  return (
                    <FormGroup key={index} check inline>
                      <Label check>
                        <Input
                          type="checkbox"
                          data-cy="customQuestionCheckbox"
                          invalid={
                            props.errors[
                              `${props.question.id}${props.errorIDBase}`
                            ]
                          }
                          value={option && option.text}
                          checked={checked}
                          name={`${props.question.id}${props.errorIDBase}`}
                          onChange={props.handleChange}
                        />
                        {option && option.text}
                      </Label>
                    </FormGroup>
                  )
                })}
              </FormGroup>
            </div>
          </div>
        </div>
      )}
    <hr />
  </QuestionContainer>
)

export default CustomQuestionItem
