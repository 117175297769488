import {useQuery} from 'react-query'
import axios from 'axios'
import {print} from 'graphql'
import gql from 'graphql-tag'

const GET_REGISTRATION_BY_ID = gql`
  query getEventRegistrationByID($input: String!) {
    getEventRegistrationByID(id: $input) {
      id
      eventID
      guests {
        firstName
        lastName
        guestNumber
        questionResponses {
          questionID
          question
          response
        }
        email
        address
        phone
        organization
        guestFee
        guestFeeOptionIndex
        guestOptionName
      }
      players {
        id
        firstName
        lastName
        questionResponses {
          questionID
          question
          response
        }
        email
        address
        phone
        organization
        playerFee
        playerFeeOptionIndex
        playerFeeOptionID
        sponsorshipItemIndex
        sponsorshipItemID
        playerOptionName
        twosomeDiscount
        foursomeDiscount
        roundID
        groupID
      }
      registrant {
        firstName
        lastName
        organization
        email
        address
        city
        stateAddress
        zipCode
        phone
        comments
      }
      sponsorshipItems {
        id
        price
        quantityInCart
        itemName
        playersIncluded
        kind
      }
      donations {
        id
        description
        value
        monetary
      }
      questionResponses {
        questionID
        question
        response
      }
      cartTotal
    }
  }
`

const useRegistrationLookup = (id, user) => {
  const variables = {input: id}
  return useQuery(
    ['eventRegistrationLookup', id],
    async () => {
      const response = await axios.post('', {
        query: print(GET_REGISTRATION_BY_ID),
        variables,
      })
      return response?.data?.data?.getEventRegistrationByID
    },
    {
      enabled: Boolean(user && id),
      refetchOnReconnect: false,
      refetchOnWindowFocus: false,
      onError: err => {
        console.error(err)
      },
    },
  )
}

export default useRegistrationLookup
