import axios from 'axios'
import {print} from 'graphql'
import gql from 'graphql-tag'
import {useQuery} from 'react-query'
import * as Sentry from '@sentry/browser'
import useUserLookup from 'hooks/useUserLookup'

const GET_EVENT_BY_SLUG = gql`
  query getEventBySlug($slug: String!) {
    getEventBySlug(slug: $slug) {
      id
      adminMode
      name
      slug
      status
      eventDate
      golfStartTime
      openDate
      closeDate
      organizationName
      organizationWebsite
      homepageHTML
      homeHeaderHTML
      showHeaderOnLandingPage
      courseName
      courseAddress
      courseWebsite
      courseDescription
      format
      startType
      sponsorImages {
        name
        website
        image
        order
        display
      }
      sponsorshipItems {
        id
        kind
      }
      allowDonors
      allowVolunteers
      showPairings
      showContactPlanner
      buttonColor
      multipleRounds
      registrationButtonText
    }
  }
`

function useEventLandingPageLookup(slug) {
  const variables = {slug: slug.toLowerCase()}
  const loggedIn = localStorage.getItem('loggedIn')
  const {data: user} = useUserLookup()
  const enabled = loggedIn ? user && slug : slug
  return useQuery(
    ['eventLandingPage', slug],
    async () => {
      const response = await axios.post('', {
        query: print(GET_EVENT_BY_SLUG),
        variables,
      })
      return response?.data?.data?.getEventBySlug
    },
    {
      enabled: Boolean(enabled),
      refetchOnReconnect: false,
      refetchOnWindowFocus: false,
      onError: err => {
        Sentry.captureMessage(`Error loading landing page - ${slug}`)
        Sentry.captureException(err)
      },
    },
  )
}

export default useEventLandingPageLookup
