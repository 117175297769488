import axios from 'axios'
import {print} from 'graphql'
import {useMutation, useQueryClient} from 'react-query'
import {EDIT_EVENT_REGISTRATION} from '../../assets/queries'

function useEditRegistration(slug) {
  const cache = useQueryClient()
  return useMutation(
    async registration => {
      try {
        const variables = {input: registration}
        const response = await axios.post('', {
          query: print(EDIT_EVENT_REGISTRATION),
          variables,
        })
        return response?.data?.data?.updateEventRegistration
      } catch (error) {
        console.error(JSON.stringify(error, undefined, 2))
      }
    },
    {
      enabled: Boolean(slug),
      // On failure, roll back to the previous value
      onError: (err, variables, previousValue) => {
        console.error('Edit Event Registration', err, variables)
        cache.setQueryData(
          ['eventRegistrations', variables.eventID],
          previousValue,
        )
      },
      // After success or failure, refetch the registrations query
      onSettled: (_, __, mutationVariables) => {
        cache.invalidateQueries(
          ['eventRegistrations', mutationVariables.eventID],
          {
            refetchInactive: true,
          },
        )
        cache.invalidateQueries('pairings')
      },
    },
  )
}

export default useEditRegistration
