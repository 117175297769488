import React from 'react'

import PlayerItem from './PlayerItem'
import PlayerItemList from './PlayerItemList'

const PlayerButton = props => (
  <div data-cy="playerSection">
    {props.event.playerFeeOptions.map((option, index) => {
      if (option) {
        const playersForThisOption = props.players.filter(
          // player => player && player.playerFeeOptionIndex === index,
          player => player && player.playerFeeOptionID === option.id,
        )
        return (
          <div key={index} data-cy="playerPanel">
            <PlayerItem
              addPlayerClick={props.addPlayerClick}
              removeGroupClick={props.removeGroupClick}
              playerCount={playersForThisOption.length}
              totalPlayerCount={props.players.length}
              optionIndex={index}
              option={option}
              event={props.event}
              // playersForThisOption={playersForThisOption}
            />
            {playersForThisOption && playersForThisOption.length ? (
              <PlayerItemList
                event={props.event}
                //players={props.players}
                addPlayerClick={props.addPlayerClick}
                removePlayerClick={props.removePlayerClick}
                playerChange={props.playerChange}
                // availablePlayers={props.availablePlayers}
                questions={props.questions}
                errors={props.errors}
                playersForThisOption={playersForThisOption}
                option={option}
              />
            ) : (
              ''
            )}
          </div>
        )
      } else {
        return <div />
      }
    })}
  </div>
)
// }

export default PlayerButton
