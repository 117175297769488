import * as React from 'react'

export function DefaultColumnFilter({column: {filterValue, setFilter}}) {
  return (
    <input
      value={filterValue || ''}
      onChange={e => {
        setFilter(e.target.value || undefined) // Set undefined to remove the filter entirely
      }}
      placeholder={`Search`}
    />
  )
}
