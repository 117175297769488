import {generateID} from '../assets/helpers'
export const loadEventFiller = (event, volunteersOnly) => {
  // sort custom questions and add responses and error entries
  const playerQuestions = []
  const guestQuestions = []
  const sponsorshipQuestions = []
  const donorQuestions = []
  const volunteerQuestions = []
  const registrantQuestions = []
  const errors = {
    firstName: undefined,
    lastName: undefined,
    email: undefined,
    address: undefined,
    city: undefined,
    stateAddress: undefined,
    zipCode: undefined,
    phone: undefined,
    organization: undefined,
  }
  let formError = false

  if (event?.customQuestions && event.customQuestions.length > 0) {
    // const errors = this.state.errors;
    event.customQuestions.map(question => {
      switch (question.appliesTo) {
        case 'Players':
        case 'Specific Round':
          playerQuestions.push(question)
          break
        case 'Players & Reception Guests':
          guestQuestions.push(question)
          playerQuestions.push(question)
          break
        case 'Reception Guests':
          guestQuestions.push(question)
          break
        case 'Sponsors':
          sponsorshipQuestions.push(question)
          break
        case 'Donors':
          donorQuestions.push(question)
          break
        case 'Volunteers':
          volunteerQuestions.push(question)
          break
        case 'All':
          guestQuestions.push(question)
          playerQuestions.push(question)
          registrantQuestions.push(question)
          break
        default:
          break
      }
      question.response = ''
      return question
    })
  }
  //add errors for volunteer
  if (volunteersOnly) {
    volunteerQuestions.forEach(q => {
      if (q.required) {
        errors[`${q.id}Volunteer`] = true
      }
    })
    registrantQuestions.forEach(q => {
      if (q.required) {
        errors[`${q.id}Registrant`] = true
      }
    })
    formError = true
  }

  //remove errors for data collection if not required
  if (event.registrantPhoneDataCollection !== 'required') {
    errors.phone = false
  }
  if (event.registrantAddressDataCollection !== 'required') {
    errors.address = false
    errors.city = false
    errors.stateAddress = false
    errors.zipCode = false
  }
  if (event.registrantOrganizationDataCollection !== 'required') {
    errors.organization = false
  }

  return {
    event,
    guestQuestions,
    playerQuestions,
    sponsorshipQuestions,
    donorQuestions,
    volunteerQuestions,
    registrantQuestions,
    errors,
    formError,
  }
}

export const editEventForRegistration = (registration, event, errors) => {
  let nextGuestNumber,
    player1Changed,
    showSponsorshipQuestions,
    showDonorQuestions

  if (registration) {
    event.registrationID = registration.id

    //run through sponsorship items and update quantities on event
    event.sponsorshipItems.map(item => {
      if (registration && registration.sponsorshipItems) {
        const foundSponsorshipItem = registration.sponsorshipItems.find(
          foundItem => foundItem.id === item.id,
        )
        item.quantityInCart = foundSponsorshipItem
          ? foundSponsorshipItem.quantityInCart
          : 0
        if (item.quantityInCart > 0) {
          showSponsorshipQuestions = true
        }
        return item
      } else {
        return item
      }
    })

    // const newOptions = event.playerFeeOptions
    let option
    if (registration && registration.players) {
      registration.players.forEach(player => {
        if (
          player.sponsorshipItemIndex === null ||
          player.sponsorshipItemIndex === undefined
        ) {
          if (player) {
            option = event.playerFeeOptions.find(
              o => o.id === player.playerFeeOptionID,
            )
            if (!option) {
              //option id not found - search by name and amount
              option = event.playerFeeOptions.find(
                o =>
                  o.playerOptionName === player.playerOptionName &&
                  Number(o.playerFee) === Number(player.playerFee),
              )
              if (option) {
                player.playerFeeOptionID = option.id
              }
            }
            if (!option) {
              //can't find by id or name - use index
              option = event.playerFeeOptions[player.playerFeeOptionIndex]
            }
          }
          if (option) {
            let addToCount = 0
            //add group ids and add to quantity if new group
            if (player.groupID) {
              if (!option.groupIDs) {
                option.groupIDs = [player.groupID]
                addToCount = 1
              } else {
                let foundGroup = option.groupIDs.find(g => g === player.groupID)
                if (!foundGroup) {
                  option.groupIDs = [...option.groupIDs, player.groupID]
                  addToCount = 1
                }
              }
            } else {
              //older registrations don't have a group id on player
              addToCount = 1
            }
            if (option.quantityInCart) {
              option.quantityInCart = option.quantityInCart + addToCount
            } else {
              option.quantityInCart = 1
            }

            if (player.groupID && option.groupIDs) {
              //look to see if groupID is already on this option and if not add it
              const foundGroupIndex = option.groupIDs.findIndex(
                o => o === player.groupID,
              )
              if (foundGroupIndex === -1) {
                option.groupIDs.push(player.groupID)
              }
            } else if (player.groupID && !option.groupIDs) {
              option.groupIDs = [player.groupID]
            }
          }
        }
      })
    }

    // if (newOptions && newOptions.length > 0) {
    //   event.playerFeeOptions = newOptions
    // }
    if (registration && registration.guests) {
      //setup next guest number
      nextGuestNumber = registration.guests.length + 1
      registration.guests.forEach(guest => {
        delete guest.__typename
      })
    }

    //remove errors since we are editing
    for (var property in errors) {
      errors[property] = false
    }

    //if registrant is different from player one then change state
    if (
      registration.players &&
      registration.players.length &&
      registration.players[0].email !== registration.registrant.email
    ) {
      player1Changed = true
    }

    if (registration.donations && registration.donations.length > 0) {
      showDonorQuestions = true
    }
  }
  const returnObject = {
    registration,
    event,
    errors,
    player1Changed,
    nextGuestNumber,
    showSponsorshipQuestions,
    showDonorQuestions,
  }
  return returnObject
}

export const addPlayer = ({
  event,
  registrant,
  players,
  playerQuestions,
  count = 1,
  optionIndex = 0,
  type = 'player',
  errors,
  nextNumber,
  editingRegistration,
}) => {
  //look for custom questions that apply to players and add to this new player
  const newPlayerEntries = []
  let firstPlayer = false
  if (players.length === 0) firstPlayer = true
  if (event) {
    const customQuestionCheck = (question, id, option) => {
      if (question) {
        const playerQuestion = {
          questionID: question.id,
          question: question.question,
          response: '',
        }
        if (
          question.appliesTo === 'Specific Round' &&
          question.roundID === option.roundID &&
          question.required
        ) {
          errors[`${question.id}Player${String(id)}`] = true
        } else if (
          question.required &&
          question.appliesTo !== 'Specific Round'
        ) {
          errors[`${question.id}Player${String(id)}`] = true
        }
        return playerQuestion
      }
    }

    const returnPlayerQuestions = (questions, id, option) => {
      const playerQuestions = []
      questions.forEach(q => {
        const playerQuestion = customQuestionCheck(q, id, option)
        playerQuestions.push(playerQuestion)
      })
      return playerQuestions
    }

    //find fee option
    let option, optionName
    if (event && event.playerFeeOptions && type === 'player') {
      if (event.playerFeeOptions[optionIndex]) {
        option = event.playerFeeOptions[optionIndex]
      }
      if (option) optionName = option.playerOptionName || ''
    } else {
      //must be a sponsorship
      option = event.sponsorshipItems[optionIndex]
      if (option) optionName = option.itemName
    }

    //limit to players available
    // let roundQuantityInCart = 0
    if (!editingRegistration) {
      if (event.multipleRounds) {
        event.rounds.map(r => {
          if (r && option && r.roundID === option.roundID) {
            const roundAvailable = Number(r.available) - r.quantityInCart
            count = count > roundAvailable ? roundAvailable : count
            r.quantityInCart = r.quantityInCart + count
          }
          return r
        })
      } else {
        let available = (event.playersAvailable || 0) - players.length
        count = count > available ? available : count
        count = count < 0 ? 0 : count
      }
    }

    let groupID = null
    if (option) {
      // console.log(option)
      //add 1 to option quantity (even if two or four players are added as a group)
      option.quantityInCart = (option.quantityInCart || 0) + 1
      // if (count > 1 && type === 'player' && option) {
      groupID = generateID()
      if (option.groupIDs) {
        option.groupIDs.push(groupID)
      } else {
        option.groupIDs = [groupID]
      }
    }

    for (var i = 0; i < count; i++) {
      //create errors for required data collection fields
      // playerEmailDataCollection: string,
      // playerAddressDataCollection: string,
      // playerPhoneDataCollection: string,
      // playerOrganizationDataCollection: string,
      const nextid = String(nextNumber + i)

      //we may have added errors if questions are required

      //create empty entries
      if (option) {
        const empty = {
          firstName: '',
          lastName: '',
          id: nextid,
          questionResponses: returnPlayerQuestions(
            playerQuestions,
            nextid,
            option,
          ),
          email: '',
          phone: '',
          address: '',
          organization: '',
          playerFee: option.playerFee,
          playerFeeOptionIndex: undefined,
          playerFeeOptionID: undefined,
          sponsorshipItemIndex: undefined,
          sponsorshipItemID: undefined,
          playerOptionName: optionName,
          twosomeDiscount: option.twosomeDiscount,
          foursomeDiscount: option.foursomeDiscount,
          roundID: option.roundID,
          groupID: groupID,
        }
        if (firstPlayer && i === 0) {
          empty.firstName = registrant.firstName
          empty.lastName = registrant.lastName
          empty.email = registrant.email
          empty.address = registrant.address
          empty.phone = registrant.phone
          empty.organization = registrant.organization
        }
        if (
          event.playerEmailDataCollection === 'required' &&
          empty.email === ''
        ) {
          errors[`emailPlayer${nextid}`] = true
        }
        if (
          event.playerAddressDataCollection === 'required' &&
          empty.address === ''
        ) {
          errors[`addressPlayer${nextid}`] = true
        }
        if (
          event.playerPhoneDataCollection === 'required' &&
          empty.phone === ''
        ) {
          errors[`phonePlayer${nextid}`] = true
        }
        if (
          event.playerOrganizationDataCollection === 'required' &&
          empty.organization === ''
        ) {
          errors[`organizationPlayer${nextid}`] = true
        }
        if (type === 'player') {
          empty.playerFeeOptionIndex = optionIndex
          empty.playerFeeOptionID = option.id || ''
        } else {
          empty.sponsorshipItemIndex = optionIndex
          empty.sponsorshipItemID = option.id || ''
        }
        newPlayerEntries.push(empty)
      }
    }

    return {
      event,
      nextPlayerNumber: nextNumber + count,
      players: [...players, ...newPlayerEntries],
      errors,
    }
  }
}

export const removePlayer = (event, errors, players, ids, player1Changed) => {
  //check errors to see if there are any for this player and remove them if found
  if (ids && ids.length > 0) {
    ids.forEach(id => {
      for (var property1 in errors) {
        if (property1.endsWith(`Player${id}`)) {
          delete errors[property1]
        }
      }

      let removedPlayer = players.find(player => player && player.id === id)

      let roundID = null
      if (removedPlayer && event) {
        const option =
          event.playerFeeOptions[removedPlayer.playerFeeOptionIndex]
        if (option) roundID = option.roundID
      }

      if (event) {
        event.playerFeeOptions.map(o => {
          if (o && o.roundID === roundID) {
            if (o.available !== null && o.available !== undefined) {
              o.available++
            }
          }
          return o
        })
      }
    })
    const remainingPlayers = []
    players.forEach(player => {
      let foundPlayerInRemovedList = ids.findIndex(
        id => player && player.id === id,
      )
      if (foundPlayerInRemovedList === -1) remainingPlayers.push(player)
    })

    return {
      event,
      players: remainingPlayers,
      errors,
      player1Changed: remainingPlayers.length > 0 ? player1Changed : false,
    }
  }
}
