/* eslint-disable react/jsx-key */
import React, {Fragment} from 'react'
import {useTable, useSortBy, useFilters, usePagination} from 'react-table'
import {useExportData} from 'react-table-plugins'
import {getExportFileBlob, compareIgnoreCase} from './tableWithHooksHelpers'
import TableButtons from './TableButtons'
import {
  Styles,
  TableHeaders,
  TableSubHeaders,
  NoRecordsRow,
  TableTitle,
} from './tableStyles'
import TablePaginationControls from './TablePaginationControls'
import {DefaultColumnFilter} from './Filters'
import styled from 'styled-components/macro'

const Header = styled.header`
  display: flex;
  position: relative;
  padding: 5px;
`

const ScrollableDiv = styled.div`
  width: 100%;
  /* for sticky headers */
  /* overflow: auto;
   height: 75vh; */
`

function TableWithHooks(props) {
  const {
    columns,
    data,
    initPageSize = 50,
    tableTitle,
    filterTable = true,
    exportTable = false,
    initSort = [],
    hiddenColumns = [],
    rowClick,
  } = props
  const defaultColumn = React.useMemo(
    () => ({
      Filter: DefaultColumnFilter,
    }),
    [],
  )

  const exportFile = React.useCallback(
    props => {
      const newProps = {
        ...props,
        fileName:
          props.fileName === 'all-data'
            ? tableTitle || 'Report'
            : `${tableTitle || 'Report'}-filtered`,
      }
      return getExportFileBlob(newProps)
    },
    [tableTitle],
  )

  const sortTypes = React.useMemo(
    () => ({
      alphanumeric: (row1, row2, columnName) => {
        return compareIgnoreCase(
          row1.values[columnName],
          row2.values[columnName],
        )
      },
    }),
    [],
  )

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    prepareRow,
    canPreviousPage,
    canNextPage,
    pageOptions,
    rows,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    state: {pageIndex, pageSize},
    exportData,
  } = useTable(
    {
      columns,
      data,
      defaultColumn,
      getExportFileBlob: exportFile,
      pageSize: initPageSize,
      sortTypes: sortTypes,
      initialState: {
        sortBy: initSort,
        pageSize: initPageSize,
        hiddenColumns: hiddenColumns,
      },
    },
    useFilters,
    useSortBy,
    usePagination,
    useExportData,
  )

  const scrollRef = React.useRef()

  return (
    <>
      <Styles>
        <Header>
          {tableTitle && <TableTitle>{tableTitle}</TableTitle>}
          {exportTable && <TableButtons exportData={exportData} />}
        </Header>
        <ScrollableDiv>
          <table {...getTableProps()}>
            <thead>
              {headerGroups.map((headerGroup, index) => (
                <Fragment key={index}>
                  <tr ref={scrollRef} {...headerGroup.getHeaderGroupProps()}>
                    {headerGroup.headers.map(column => (
                      // Add the sorting props to control sorting. For this example
                      // we can add them into the header props
                      <TableHeaders
                        // key={colIndex}
                        width={column.width}
                        {...column.getHeaderProps(
                          column.getSortByToggleProps(),
                        )}
                        sorted={
                          column.isSorted
                            ? column.isSortedDesc
                              ? 'desc'
                              : 'asc'
                            : ''
                        }
                      >
                        {column.render('Header')}
                      </TableHeaders>
                    ))}
                  </tr>
                  {filterTable && (
                    <tr className="subheader">
                      {headerGroup.headers.map((column, colIndex) => (
                        <TableSubHeaders key={colIndex}>
                          {column.canFilter ? column.render('Filter') : null}
                        </TableSubHeaders>
                      ))}
                    </tr>
                  )}
                </Fragment>
              ))}
            </thead>
            <tbody {...getTableBodyProps()}>
              {rows.length > 0 ? (
                <>
                  {page.map(row => {
                    prepareRow(row)
                    return (
                      <tr
                        // key={i}
                        data-cy="tableRow"
                        onClick={rowClick ? () => rowClick(row) : null}
                        {...row.getRowProps()}
                      >
                        {row.cells.map(cell => {
                          return (
                            <td {...cell.getCellProps()}>
                              {cell.render('Cell')}
                            </td>
                          )
                        })}
                      </tr>
                    )
                  })}
                </>
              ) : (
                <tr>
                  <td colSpan={100}>
                    <NoRecordsRow data-cy="noRecords">
                      No records found
                    </NoRecordsRow>
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </ScrollableDiv>

        <div>
          {data.length > initPageSize && (
            <TablePaginationControls
              scrollRef={scrollRef}
              nextPage={nextPage}
              previousPage={previousPage}
              canPreviousPage={canPreviousPage}
              gotoPage={gotoPage}
              canNextPage={canNextPage}
              pageIndex={pageIndex}
              pageOptions={pageOptions}
              pageSize={pageSize}
              setPageSize={setPageSize}
            />
          )}
        </div>
      </Styles>
    </>
  )
}

export default React.memo(TableWithHooks)
