//
import React, {Fragment, useState, useEffect} from 'react'
import styled from 'styled-components/macro'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'

import {Col, FormGroup, Label, Input, FormFeedback} from 'reactstrap'
import {Message} from '../../assets/elements'
import CustomQuestions from '../CustomQuestions'

const PlayerListPanel = styled.div`
  display: grid;
  grid-template-columns: 3fr 3fr 40px;
  grid-template-rows: auto;
  grid-template-areas:
    'first        last         button'
    'email        email        email'
    'phone        phone        phone'
    'address      address      address'
    'organization organization organization'
    'questions    questions    questions';
  grid-column-gap: 5px;
  align-items: center;
  padding: 1rem 0.5rem 0 0.5rem;
  margin: 1rem 0;
  border: 1px solid rgba(34, 36, 38, 0.15);
  border-top: 2px solid #cc8c18 !important;
  box-shadow: 0 1px 2px 0 rgba(34, 36, 38, 0.15);
  border-radius: 0.28571429rem;
`

const CloseButton = styled.button`
  grid-area: button;
  display: flex;
  justify-content: center;
  padding: 0.35rem;
  width: 2em;
  height: 2em;
`

const FirstName = styled.input`
  grid-area: first;
`

const LastName = styled.input`
  grid-area: last;
`

const Questions = styled.div`
  grid-area: questions;
`

const RequiredFlag = styled.sup`
  font-size: 0.7rem;
  color: #dc3545;
  margin-left: 5px;
`

const EMail = styled.div`
  padding: 10px 5px 0 5px;
  margin-bottom: -10px;
  grid-area: email;
`

const Address = styled.div`
  padding: 10px 5px 0 5px;
  margin-bottom: -10px;
  grid-area: address;
`

const Phone = styled.div`
  padding: 10px 5px 0 5px;
  margin-bottom: -10px;
  grid-area: phone;
`

const Organization = styled.div`
  padding: 10px 5px 0 5px;
  margin-bottom: -10px;
  grid-area: organization;
`

const PlayerItemList = props => {
  const [applicableQuestions, setApplicableQuestions] = useState(
    props.questions,
  )
  useEffect(() => {
    if (props.event.multipleRounds) {
      let newQuestions = []
      props.questions.forEach(question => {
        if (
          question &&
          question.roundID &&
          ((props.option && props.option.roundID === question.roundID) ||
            props.roundID === question.roundID)
        ) {
          newQuestions.push(question)
        } else if (question && (!question.roundID || question.roundID === '')) {
          newQuestions.push(question)
        }
      })
      setApplicableQuestions(newQuestions)
    }
  }, [props.event.multipleRounds, props.option, props.questions, props.roundID])
  return (
    <Fragment>
      <Message data-testid="">
        <h4>Player Names</h4>
        <p>
          {`If you don’t know the players names please enter Guest for First Name and Your Last Name`}
        </p>
      </Message>
      <div>
        {props.playersForThisOption.map((player, index) => (
          <div key={player && (player.id || index)}>
            <PlayerListPanel data-cy="playerListPanel">
              <FirstName
                name="firstName"
                className="form-control"
                placeholder="First name"
                data-testid="player-first-name"
                onChange={e =>
                  props.playerChange(e, (player && player.id) || '0')
                }
                value={player && player.firstName}
              />
              <LastName
                className="form-control"
                name="lastName"
                placeholder="Last name"
                data-testid="player-last-name"
                onChange={e =>
                  props.playerChange(e, (player && player.id) || '0')
                }
                value={player && player.lastName}
              />
              {props.event.playerEmailDataCollection !== 'notShown' ? (
                <EMail>
                  <FormGroup row>
                    <Label for="email" sm={4}>
                      Email{' '}
                      {props.event.playerEmailDataCollection === 'required' ? (
                        <RequiredFlag>*</RequiredFlag>
                      ) : (
                        <Fragment />
                      )}
                    </Label>
                    <Col sm={8}>
                      <Input
                        className="form-control"
                        name="email"
                        placeholder="Email Address"
                        data-testid="player-email"
                        onChange={e =>
                          props.playerChange(e, (player && player.id) || '0')
                        }
                        value={player && player.email}
                        invalid={
                          (props.event.playerEmailDataCollection ===
                            'required' &&
                            (!player || player.email === '')) ||
                          (props.errors &&
                            props.errors[
                              `emailPlayer${player ? player.id : ''}`
                            ])
                        }
                      />
                      <FormFeedback data-cy="emailFeedback">
                        Please enter a valid e-mail address
                      </FormFeedback>
                    </Col>
                  </FormGroup>
                </EMail>
              ) : (
                <Fragment />
              )}

              {props.event.playerPhoneDataCollection !== 'notShown' ? (
                <Phone>
                  <FormGroup row>
                    <Label for="phone" sm={4}>
                      Phone #
                      {props.event.playerPhoneDataCollection === 'required' ? (
                        <RequiredFlag>*</RequiredFlag>
                      ) : (
                        <Fragment />
                      )}
                    </Label>
                    <Col sm={8}>
                      <Input
                        className="form-control"
                        name="phone"
                        placeholder="Phone #"
                        data-testid="player-phone"
                        invalid={
                          props.event.playerPhoneDataCollection ===
                            'required' &&
                          (!player || player.phone === '')
                        }
                        onChange={e =>
                          props.playerChange(e, (player && player.id) || '0')
                        }
                        value={player && player.phone}
                        required={
                          props.event.playerPhoneDataCollection === 'required'
                        }
                      />
                    </Col>
                  </FormGroup>
                </Phone>
              ) : (
                <Fragment />
              )}
              {props.event.playerAddressDataCollection !== 'notShown' ? (
                <Address>
                  <FormGroup row>
                    <Label for="address" sm={4}>
                      Address
                      {props.event.playerAddressDataCollection ===
                      'required' ? (
                        <RequiredFlag>*</RequiredFlag>
                      ) : (
                        <Fragment />
                      )}
                    </Label>
                    <Col sm={8}>
                      <Input
                        className="form-control"
                        name="address"
                        placeholder="Address"
                        data-testid="player-address"
                        invalid={
                          props.event.playerAddressDataCollection ===
                            'required' &&
                          (!player || player.email === '')
                        }
                        onChange={e =>
                          props.playerChange(e, (player && player.id) || '0')
                        }
                        value={player && player.address}
                        required={
                          props.event.playerAddressDataCollection === 'required'
                        }
                      />
                    </Col>
                  </FormGroup>
                </Address>
              ) : (
                <Fragment />
              )}

              {props.event.playerOrganizationDataCollection !== 'notShown' ? (
                <Organization>
                  <FormGroup row>
                    <Label for="organization" sm={4}>
                      Organization
                      {props.event.playerOrganizationDataCollection ===
                      'required' ? (
                        <RequiredFlag>*</RequiredFlag>
                      ) : (
                        <Fragment />
                      )}
                    </Label>
                    <Col sm={8}>
                      <Input
                        className="form-control"
                        name="organization"
                        placeholder="Organization"
                        data-testid="player-organization"
                        invalid={
                          props.event.playerOrganizationDataCollection ===
                            'required' &&
                          (!player || player.organization === '')
                        }
                        onChange={e =>
                          props.playerChange(e, (player && player.id) || '0')
                        }
                        value={player && player.organization}
                        required={
                          props.event.playerOrganizationDataCollection ===
                          'required'
                        }
                      />
                    </Col>
                  </FormGroup>
                </Organization>
              ) : (
                <Fragment />
              )}

              <Questions>
                {player ? (
                  <CustomQuestions
                    errorIDBase={`player${player.id}`}
                    questions={applicableQuestions}
                    responses={player.questionResponses || []}
                    handleChange={e =>
                      props.playerChange(e, (player && player.id) || '0')
                    }
                    errors={props.errors}
                  />
                ) : (
                  <Fragment />
                )}
              </Questions>
              {player &&
                player.playerFeeOptionIndex !== undefined &&
                player.playerFeeOptionIndex !== null &&
                (!props.option ||
                  props.option.soldAs === 1 ||
                  !props.option.soldAs) && (
                  <CloseButton
                    type="button"
                    className="btn btn-sm btn-secondary"
                    onClick={props.removePlayerClick}
                    data-testid="remove-player-button"
                    data-playerid={player.id}
                  >
                    <FontAwesomeIcon icon="times-circle" />
                  </CloseButton>
                )}
            </PlayerListPanel>
          </div>
        ))}
      </div>
    </Fragment>
  )
}

export default PlayerItemList
