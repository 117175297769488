//     
import React from 'react'
import styled from 'styled-components/macro'
                                             

              
                   
                    
 

const EventInformationContainer = styled.div`
  border: 1px solid rgba(0, 0, 0, 0.125);
  border-radius: 0.25rem;
  padding: 0;
  width: 100%;
  max-width: 300px;
  margin: 10px;
`

const EventInformationHeader = styled.div`
  background-color: ${p => (p.bColor ? p.bColor : '#2b4e72')};
  color: ${p => (p.color ? p.color : '#ffffff')};
  text-align: center;
  padding: 10px;
`

const EventInformationLine = styled.div`
  font-weight: bold;
  padding: 3px 25px;
`

const EventInformationSection = (props       ) => (
  <EventInformationContainer>
    <EventInformationHeader
      bColor={props.event.buttonColor}
      color={props.textColor}
    >
      Event Information
    </EventInformationHeader>
    {props.event.eventDate ? (
      <EventInformationLine>Date: {props.event.eventDate}</EventInformationLine>
    ) : null}
    {props.event.golfStartTime ? (
      <EventInformationLine>
        Start Time: {props.event.golfStartTime}
      </EventInformationLine>
    ) : null}
    {props.event.startType && props.event.startType !== 'N/A' ? (
      <EventInformationLine>
        Start Type: {props.event.startType}
      </EventInformationLine>
    ) : null}
    {props.event.format ? (
      <EventInformationLine>Format: {props.event.format}</EventInformationLine>
    ) : null}
  </EventInformationContainer>
)

export default EventInformationSection
