import * as React from 'react'
import styled from 'styled-components/macro'
import {
  ButtonDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from 'reactstrap'

const Container = styled.div`
  display: flex;
  position: absolute;
  right: 5px;
  top: 5px;
`

const TableButtons = ({exportData}) => {
  const [dropdownOpen, setDropdownOpen] = React.useState(false)
  return (
    <Container>
      <ButtonDropdown
        isOpen={dropdownOpen}
        toggle={() => setDropdownOpen(!dropdownOpen)}
        size="sm"
      >
        <DropdownToggle caret>Export</DropdownToggle>
        <DropdownMenu>
          <DropdownItem
            onClick={() => {
              exportData('xlsx', true)
            }}
          >
            Export All as xlsx
          </DropdownItem>
          <DropdownItem
            onClick={() => {
              exportData('xlsx', false)
            }}
          >
            Export Current View as xlsx
          </DropdownItem>
          <DropdownItem
            onClick={() => {
              exportData('csv', true)
            }}
          >
            Export All as CSV
          </DropdownItem>
          <DropdownItem
            onClick={() => {
              exportData('csv', false)
            }}
          >
            Export Current View as CSV
          </DropdownItem>
        </DropdownMenu>
      </ButtonDropdown>
    </Container>
  )
}
export default TableButtons
